const getFormattedGMTtoDMYDate = (dateString: any) => {
  if (!dateString) return null

  // Convertir la fecha a un objeto Date
  const date = new Date(dateString)

  // Obtener el día, el mes y el año
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  // Agregar ceros a la izquierda del mes si es necesario
  if (month < 10) {
    return `${day}/0${month}/${year}`
  };

  // Devolver la fecha en el formato deseado
  return `${day}/${month}/${year}`
}

export default getFormattedGMTtoDMYDate
