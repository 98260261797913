import type { PayoutStatusHistoryElementKey } from '../../typings/types'

export const statusHistoryLabels: Record<PayoutStatusHistoryElementKey, string> = {
  processing: 'Procesando',
  checking: 'Revisando',
  dispersion: 'Dispersando',
  success: 'Finalizado',
  voided: 'Anulado',
  waiting: 'Esperando',
  rejected: 'Rechazado',
  paid: 'Pagado'
}
