import { Link, useNavigate } from 'react-router-dom'

import validRoutesData from '../../../../constants/validRoutesData'

interface ISidebarMenuGroupItem {
    id: string
    title: string
    url: string
    sessionUserIndexRoute: string
    disabled: boolean
    handleSidebarMenuGroupItemClick: any
};

const SidebarMenuGroupItem = ({
  id,
  title,
  url,
  sessionUserIndexRoute,
  disabled,
  handleSidebarMenuGroupItemClick
}: ISidebarMenuGroupItem) => {
  const classNameDisabled = disabled ? 'sidebar__body__group__item--disabled' : ''
  const classNameUrl = window?.location?.pathname === url ? 'sidebar__body__group__item--active' : ''
  const className = `sidebar__body__group__item ${classNameUrl} ${classNameDisabled}`

  const route = validRoutesData?.includes(url) ? url : sessionUserIndexRoute

  const navigate = useNavigate()

  /*
     * Efecto que detecta si se hizo un click en algún item del grupo de menú del sidebar
     * para automáticamente navegar a su correspondiente ruta y además elevar esta última
     * hasta el componente padre Home
    */
  const handleClick = () => {
    navigate(route)

    handleSidebarMenuGroupItemClick(route)
  }

  /*
     * Renderizado del grupo de items de cada menu del layout que contiene el Sidebar
    */
  return (
    <li
      key={id}
      className={className}
    >
      <Link
        to={route}
        onClick={handleClick}
      >
        <span className="sidebar__body__group__item__text">
          {title}
        </span>
      </Link>
    </li>
  )
}

export default SidebarMenuGroupItem
