import getRoleById from './getRoleById'

const getFinalPermissions = (currentView: string, services: any, servicesBySubMenu: any, userRole: string) => {
  const allServices = services
  const currentViewWithoutId = currentView.replace(/\/[\w-]+$/, '')
  const newServicesBySubMenu = servicesBySubMenu.filter((item: string) => item.includes(currentViewWithoutId))
  const formatNewServicesBySubMenu = newServicesBySubMenu.map((item: string) => item.split('.').pop())
  const formatNewServicesWithoutEmpty = formatNewServicesBySubMenu.filter((item: string) => item !== '')

  const filtrarEndpoints = (permittedServices: any, allServices: any) => {
    return allServices.filter((endpoint: any) => {
      return permittedServices.some((prefix: any) => endpoint.includes(prefix))
    })
  }

  const finalPermissions = filtrarEndpoints(formatNewServicesWithoutEmpty, allServices)
  const cleanRole = finalPermissions.map((item: string) => item.replace(`.${getRoleById(userRole)}`, ''))
  const permissions = cleanRole.map((item: string) => {
    const [service, endpoint, methods] = item.split('.')
    return {
      service,
      endPoint: endpoint.slice(0, -1),
      methods: methods.split(',')
    }
  })
  return permissions
}

export default getFinalPermissions
