import removeSpaces from '../commons/removeSpaces'

const getTemplateOptionsData = (files: any): any => {
  if (!files || files?.length === 0) return []

  const templateOptions: Array<{ id: number, name: string, title: string }> = []

  files?.forEach((file: any, idx: number) => {
    const { file_cod } = file // eslint-disable-line

    const name = removeSpaces(file_cod)?.toLowerCase()

    templateOptions?.push({ id: idx, name, title: file_cod })
  })

  return templateOptions
}

export default getTemplateOptionsData
