import getTimeZoneHours from './getTimeZoneHours'

const getFormattedGMTDate = (date: Date, timeZoneCode: string) => {
  if (!date) return new Date()

  const timeZoneHours = getTimeZoneHours(timeZoneCode)

  // Obtener la diferencia en minutos entre la hora local y GMT-5 (EST)
  const differenceGMT5 = timeZoneHours * 60 // -5 horas * 60 minutos/hora

  // Calcular la fecha y hora en GMT-5
  const dateGMT5 = new Date(date?.getTime() + differenceGMT5 * 60 * 1000)

  return dateGMT5
}

export default getFormattedGMTDate
