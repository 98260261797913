import './ProgressBar.css'

interface IProgressBarProps {
    title?: string
    type?: string
    currentValue: number
    maxValue: number
};

const ProgressBar = ({ title = '', type = 'progressBar', currentValue, maxValue }: IProgressBarProps) => {
  const percentage = maxValue !== 0 ? (currentValue / maxValue) * 100 : 0

  return (
    <div className="wrapper_progressBar">
      <div className="progressBar__header">
        <span className="progressBar__title">{title}</span>
        <span className="progressBar__value">{currentValue}/{maxValue}</span>
      </div>
      <div className="progressBar__body">
        <div className="progressBar__bar" style={{ width: `${percentage}%` }} role={type} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
      </div>
    </div>
  )
}

export default ProgressBar
