import { type PayoutDataType } from '../../../typings/types'
import { getPayoutCreatedDtm } from '../data/getPayoutCreatedDtm'
import getFormattedDate from './getFormattedDate'

const getFormattedFileUploadDate = (payout: PayoutDataType | undefined, timeZoneCode: string) => {
  if (!payout) return ''

  const originalDate = getPayoutCreatedDtm(payout)

  const formattedOriginalDate = getFormattedDate(originalDate, timeZoneCode)

  const day = formattedOriginalDate?.getDate()?.toString()?.padStart(2, '0')
  const month = (formattedOriginalDate?.getMonth() + 1)?.toString()?.padStart(2, '0') // Sumamos 1 porque los meses se indexan desde 0
  const year = formattedOriginalDate?.getFullYear()
  const hour = formattedOriginalDate?.getHours()?.toString()?.padStart(2, '0')
  const minutes = formattedOriginalDate?.getMinutes()?.toString()?.padStart(2, '0')
  const seconds = formattedOriginalDate?.getSeconds()?.toString()?.padStart(2, '0')

  const formattedDate = `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`

  return formattedDate
}

export default getFormattedFileUploadDate
