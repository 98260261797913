import getRoleById from './getRoleById'

const getPermissionsServices = (servicesData: any, userRole: string) => {
  if (!servicesData) return []
  const result = []
  for (const serviceKey in servicesData) {
    const service = servicesData[serviceKey]

    for (const endpoint of service?.endPoints) {
      const endpointStr = endpoint?.endpoint_str

      for (const roleKey in endpoint?.roles) {
        const role = endpoint?.roles[roleKey]

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const str = `${service?.name_str}.${endpointStr}/.${role?.role_cod}.${role?.permissions?.join(',')}`
        result.push(str)
      }
    }
  }
  const filterByRoleServices = result?.filter((item) => item.includes(getRoleById(userRole)))
  return filterByRoleServices
}

export default getPermissionsServices
