export const getViewsByUserRole = (views: any, userRole: string) => {
  if (!views || !userRole) return []

  const allViews: any = []

  for (const menu of views?.menu) {
    for (const submenu of menu?.sub_menu) {
      allViews.push({
        url: submenu?.view?.url_str,
        permissions: submenu?.view?.roles?.[userRole]?.permissions
      })
    };
  };

  return allViews
}

export const getPermittedViews = (allViews: any, currentUrl: string) => {
  if (!allViews || !currentUrl) return []

  const permittedViews: any = allViews?.find((view: any) => view.url === currentUrl)?.permissions

  return permittedViews
}
