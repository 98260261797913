import { useEffect, useState } from 'react'
import MainView from './components/MainView'

import './Main.css'
import { useLocation, useNavigate } from 'react-router'
import { useAppSelector } from '../../store/hooks'
import { getPermittedViews } from '../../utils/permissions/getViewsByUserRole'

const Main = () => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isNotExecutable, setIsNotExecutable] = useState<boolean>(false)
  const [isNotReadable, setIsNotReadable] = useState<boolean>(false)

  const permissions = useAppSelector(state => state.authReducer.permissions)

  const navigate = useNavigate()
  const location = useLocation()

  const currentViewPath = location?.pathname
  const sessionUserIndexRoute = location?.pathname
  const classNameNotExecutable = isNotExecutable ? 'main--notexecutable' : ''
  const classNameNotReadable = isNotReadable ? 'main--notreadable' : ''
  const className = `main ${classNameNotExecutable} ${classNameNotReadable}`

  const permittedViewsByUserRole = getPermittedViews(permissions, currentViewPath)

  /*
   * Efecto que, dependiendo de los permisos de la actual vista, oculta o desactiva elementos y
   * procesos pertenecientes a la misma, además de redigir a una vista nueva de ser necesario
  */
  useEffect(() => {
    setIsNotExecutable(false)
    setIsNotReadable(false)
    if ((!permittedViewsByUserRole?.filter((el: any) => el?.trim() !== '')?.length) &&
      !location?.pathname?.includes('/payout/customer-detail/') &&
      !location?.pathname?.includes('/payout/invoice-detail/')) {
      if (location?.pathname === sessionUserIndexRoute) {
        setIsActive(false)
      } else {
        navigate(sessionUserIndexRoute)
      };
    } else {
      setIsActive(true)

      if (!permittedViewsByUserRole?.includes('EXECUTION')) {
        setIsNotExecutable(true)
      };

      if (!permittedViewsByUserRole?.includes('READ')) {
        setIsNotReadable(true)
      };
    };
  }, [permittedViewsByUserRole])

  window.onpopstate = () => {
    if (currentViewPath !== window?.location?.pathname) {
      window.location.href = location?.pathname
    };
  }

  /*
   * Renderizado del contenido principal del Home
  */
  if (!isActive) return <></>

  return (
    <MainView
      className={className}
    />
  )
}

export default Main
