import { useNavigate } from 'react-router-dom'

import ErrorView from './components/ErrorView'

import signOutService from '../../services/cognitoServices/signOutService'

import './Error.css'

interface INotFoundProps {
    isLogged: boolean
};

const Error = ({ isLogged }: INotFoundProps) => {
  const navigate = useNavigate()

  const errorText = isLogged ? 'Sign Out' : 'Return Home'

  /*
     * Función que maneja las acciones del botón de continuar de la página de error Not Found
    */
  const handleErrorButton = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()

    if (isLogged) {
      await signOutService()
    } else {
      navigate(process.env.REACT_APP_COGNITO_NOTFOUND_RETURN_URL ?? '')
    };
  }

  /*
     * Renderizado de la página de Error
    */
  return <ErrorView errorText={errorText} handleErrorButton={handleErrorButton} /> // eslint-disable-line
}

export default Error
