import BatchDispersionStateDetail from './BatchDispersionStateDetail/BatchDispersionStateDetail'

import banksData from '../../../../../constants/banksData'

import Card from '../../../../../components/Card/Card'
import BatchDispersionStateLoader from '../../../../../components/Loader/BatchDispersionStateLoader/BatchDispersionStateLoader'
import NavItem from '../../../../../components/NavItem/NavItem'
import Image from '../../../../../components/Image/Image'
import Button from '../../../../../components/Button/Button'
import Icon from '../../../../../components/Icon/Icon'
import Code from '../../../../../components/Code/Code'
import type { PayoutDataType } from '../../../../../../typings/types'
import BatchDispersionStatusHistorySubtitle from './BatchDispersionStatusHistorySubtitle/BatchDispersionStatusHistorySubtitle'

interface IBatchDispersionStateViewProps {
    timeZoneCode: string
    fileNameSelected: string
    uploadFileIdSelected: string
    payoutDataSelected: PayoutDataType
    bankSelected: any
    processStatus: string
    transactionReportsOptions: any
    transactionReportsRef: any
    isLoading: boolean
    isProcess: boolean
    isOpenTransactionReports: boolean
    statusHistoryTimelineOpened: boolean
    handleClickSelectExport: React.MouseEventHandler<HTMLLIElement> | undefined
    handleClickSelectBank: React.MouseEventHandler<HTMLDivElement> | undefined
    handleClickTransactionReport: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleClickTransactionReportsBanks: (option: string) => void
    handleClickStatusHistoryTrigger: () => void
  };

const BatchDispersionStateView = ({
  timeZoneCode,
  fileNameSelected,
  payoutDataSelected,
  bankSelected,
  uploadFileIdSelected,
  processStatus,
  transactionReportsOptions,
  transactionReportsRef,
  isLoading,
  isProcess,
  isOpenTransactionReports,
  statusHistoryTimelineOpened,
  handleClickSelectExport,
  handleClickSelectBank,
  handleClickTransactionReport,
  handleClickTransactionReportsBanks,
  handleClickStatusHistoryTrigger
}: IBatchDispersionStateViewProps) => {
  return (
    <Card
      key={fileNameSelected}
      title="Estado de dispersión"
      subtitle={
        payoutDataSelected.status_history
          ? <BatchDispersionStatusHistorySubtitle
            fileName={fileNameSelected}
            opened={statusHistoryTimelineOpened}
            statusHistory={payoutDataSelected.status_history}
            handleClickTrigger={handleClickStatusHistoryTrigger}
          />
          : fileNameSelected
      }
      payoutDataSelected={payoutDataSelected}
      timeZoneCode={timeZoneCode}
      isToolbarDisabled={isLoading || !payoutDataSelected}
      isDispersing={processStatus === 'DISPERSION'}
      hasToolbar
      handleSelectOptionToolbarClick={handleClickSelectExport}
      // eslint-disable-next-line
      children={
        <>
          <div className="batchdispersionstate">
            {
              banksData?.map((bankData: any) => (
                <NavItem
                  key={bankData?.name}
                  name={bankData?.name}
                  icon={
                    <Image
                      type={bankData?.typeLogo}
                      width={bankData?.widthLogo}
                      height={bankData?.heightLogo}
                      alt={bankData?.alt}
                    />
                  }
                  selected={bankSelected === bankData?.name}
                  disabled={bankData?.name !== 'Todos'}
                  onClick={handleClickSelectBank}
                />
              ))
            }
          </div>
          {
            uploadFileIdSelected === ''
              ? (
                <></>
              )
              : isLoading
                ? (
                  <BatchDispersionStateLoader />
                )
                : (
                  <>
                    <BatchDispersionStateDetail
                      timeZoneCode={timeZoneCode}
                      payoutDataSelected={payoutDataSelected}
                    />
                    <div
                      className={`batchdispersionstate__transactionReports ${transactionReportsOptions?.length ? 'batchdispersionstate__transactionReports--hasOptions' : ''} ${isOpenTransactionReports ? 'batchdispersionstate__transactionReports--open' : ''}`}
                      ref={transactionReportsRef}
                    >
                      {
                        !isProcess && (
                          <Button
                            title="PDF"
                            text="Constancia de depósitos"
                            icon={<Icon type="pdf"/>}
                            isFitContent={true}
                            onClick={handleClickTransactionReport}
                          />
                        )
                      }
                      {
                        isOpenTransactionReports && (
                          <ul className="batchdispersionstate__transactionReports__options">
                            {
                              transactionReportsOptions?.map((option: any) => (
                                <li
                                  key={option?.id}
                                  className="batchdispersionstate__transactionReports__option"
                                  onClick={() => { handleClickTransactionReportsBanks(option?.value) }}
                                >
                                  {option?.name}
                                </li>
                              ))
                            }
                          </ul>
                        )
                      }
                    </div>
                    <Code label="ID de proceso" text={payoutDataSelected?.payout_id} />
                  </>
                )
          }
        </>
      }
    />
  )
}

export default BatchDispersionStateView
