/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState } from 'react'
import Badge from '../Badge/Badge'
import './Toggle.css'
import Icon from '../Icon/Icon'
import bankData from '../../constants/bankData'

interface IToggleProps {
    badgeText?: string
    badgeColor?: 'yellow' | 'blue' | 'red' | 'green' | 'gray'
    contentData?: any
}

const Toggle = ({ badgeText = '', badgeColor = 'green', contentData }: IToggleProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const className = `wrapper_toggle ${isCollapsed ? 'wrapper_toggle--isCollapsed' : ''}`

  const getAccountType: any = {
    SAVING: 'Ahorro',
    CHECKING: 'Corriente'
  }

  const handleClickToggle = (event: any) => {
    event?.preventDefault()

    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={className}>
      <div className="toggle" onClick={handleClickToggle}>
        <div className="toggle__arrow">
          <span className="toggle__arrow__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor"></path>
            </svg>
          </span>
        </div>
        <div className="toggle__icon">
          <Icon type={bankData[contentData?.bank_id]?.icon || 'openbankingLogo'} />
        </div>
        <div className="toggle__summary">
          <div className="toggle__summary__top">
            <div className="toggle__summary__top__first">{bankData[contentData?.bank_id]?.name || contentData?.bank_name_str}</div>
            <Badge text={badgeText} color={badgeColor} />
          </div>
          <div className="toggle__summary__bottom">{bankData[contentData?.bank_id] ? contentData?.bank_account_number_str : contentData?.cci_str}</div>
        </div>
      </div>
      <div className="toggle__content">
        <div className="toggle__content_container">
          <div className="toggle__content__left">
            <div className="toggle__content__item">
              <h4 className="toggle__content__item--label">Banco</h4>
              <p className="toggle__content__item--text">{bankData[contentData?.bank_id]?.name || contentData?.bank_name_str}</p>
            </div>
            <div className="toggle__content__item">
              <h4 className="toggle__content__item--label">Tipo cuenta</h4>
              <p className="toggle__content__item--text">{getAccountType?.[contentData?.type] ?? ''}</p>
            </div>
            <div className="toggle__content__item">
              <h4 className="toggle__content__item--label"># Cuenta</h4>
              <p className="toggle__content__item--text">{contentData?.bank_account_number_str}</p>
            </div>
            <div className="toggle__content__item">
              <h4 className="toggle__content__item--label">CCI</h4>
              <p className="toggle__content__item--text">{contentData?.cci_str}</p>
            </div>
          </div>
          <div className="toggle__content__right">
            <div className="toggle__content__item">
              <h4 className="toggle__content__item--label">Pagos exitosos</h4>
              <Badge text={`+${contentData?.stats?.total_success_int?.toString()}`} color="green" />
            </div>
            <div className="toggle__content__item">
              <h4 className="toggle__content__item--label">Pagos errados</h4>
              <Badge text={contentData?.stats?.total_error_int?.toString()} color="red" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Toggle
