import { useEffect, useState } from 'react'

import PermittedComponentView from './PermittedComponentView'

import './PermittedComponent.css'
import { useAppSelector } from '../../store/hooks'
import { useLocation } from 'react-router'
import { getPermittedComponents } from '../../utils/permissions/getPermittedComponents'

interface IPermittedComponentProps {
    componentId: string
    children: any
};

const PermittedComponent = ({ componentId, children }: IPermittedComponentProps) => {
  const { pathname } = useLocation()
  const componentsRedux = useAppSelector((state) => state.authReducer.components)
  const userRedux = useAppSelector((state) => state.authReducer.user)
  const [show, setShow] = useState<boolean>(false)
  const [className, setClassName] = useState<string>('permittedcomponent')

  const permittedComponentsByView = getPermittedComponents(componentsRedux?.allComponents, componentsRedux?.payoutCustomersComponents, pathname, userRedux?.role_id ?? '')

  /*
     * Efecto que asigna los permisos de componentes por vista
    */
  useEffect(() => {
    if (!permittedComponentsByView?.length) return

    const component = permittedComponentsByView?.find((permittedComponent: any) => permittedComponent?.component_str === componentId)
    const permissions = component?.permissions

    if (!permissions?.filter((permission: any) => permission?.trim() !== '')?.length) {
      setShow(false)
    } else {
      setShow(true)

      if (!permissions?.includes('READ')) {
        setClassName(className + ' permittedcomponent--notreadable')
      };

      if (!permissions?.includes('EXECUTION')) {
        setClassName(className + ' permittedcomponent--notexecutable')
      };
    };
  }, [permittedComponentsByView])

  /*
     * Renderizado del componente permitido
    */
  if (!componentId?.length || !children) return <></>

  return (
    <PermittedComponentView
      componentId={componentId}
      className={className}
      show={show}
      children={children} // eslint-disable-line
    />
  )
}

export default PermittedComponent
