import { type PayoutDataType } from '../../../typings/types'

export const getPayoutCreatedDtm = (payout?: PayoutDataType) => {
  if (!payout) return ''

  let originalDate: string | undefined

  if (payout.origin_cod === 'API') {
    originalDate = payout.created_dtm ?? payout.request_dtm
  } else {
    originalDate = payout.batch.file.request_dtm
  }

  if (!originalDate) originalDate = ''

  return originalDate
}
