import React from 'react'

const ModalContext = React.createContext<{
    modalData: { isOpen: boolean, type: string, service: string, message?: string }
    setModalData: (data: { isOpen: boolean, type: string, service: string, message?: string }) => void
      }>({
        modalData: { isOpen: false, type: 'conexionError', service: '', message: '' },
        setModalData: () => {}
      })

export default ModalContext
