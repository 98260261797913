import getCapitalizedString from '../commons/getCapitalizedString'

import { customersTableHeadingsData } from '../../constants/tableData'

const getCustomersTableData = (data: any) => {
  if (!data) return

  const customersTableBodyData = data?.map((item: any) => {
    return {
      customerId: item?.customer_id,
      externalCustomerId: item?.external_customer_id,
      name: getCapitalizedString(item?.name_str),
      documentType: item?.document_type,
      documentId: item?.document_id
    }
  })

  const customersTableData = {
    headings: customersTableHeadingsData,
    body: customersTableBodyData
  }

  return customersTableData
}

export default getCustomersTableData
