import { Outlet } from 'react-router-dom'

interface Props {
  className: string
}

const MainView = ({ className }: Props) => {
  return (
    <main className={className} >
      <div className="main_container">
        <Outlet />
      </div>
    </main>
  )
}

export default MainView
