const getStringFromArray = (arr: string[]) => {
  if (!arr?.length) return ''

  if (arr?.length > 1) {
    const finalStr = `${arr?.slice(0, arr?.length - 1)?.join(', ')} o ${arr[arr?.length - 1]}`

    return finalStr
  };

  return arr[0]
}

export default getStringFromArray
