import Image from '../../../../components/Image/Image'
import Icon from '../../../../components/Icon/Icon'
import Link from '../../../../components/Link/Link'

import './SidebarHeader.css'

interface ISidebarHeaderProps {
    isSmall: boolean
    isToggle: boolean
    handleSidebarToggle: React.MouseEventHandler<HTMLDivElement> | undefined
};

const SidebarHeader = ({ isSmall, isToggle, handleSidebarToggle }: ISidebarHeaderProps) => {
  /*
     * Renderizado del header del layout que contiene al Sidebar
    */
  return (
    <div className="sidebar__header">
      <div className="sidebar__logo">
        <Link url="/">
          {
            (isSmall && !isToggle)
              ? (
                <Image
                  type="oblogomin"
                  width={28}
                  height={20}
                  alt="Open Banking Mini Logo"
                />
              )
              : (
                <Image
                  type="oblogo"
                  width={120}
                  height={25}
                  alt="Open Banking Logo"
                />
              )
          }
        </Link>
      </div>
      <div className="sidebar__toggle" onClick={handleSidebarToggle}>
        <Icon type="sidebartoggle"/>
      </div>
    </div>
  )
}

export default SidebarHeader
