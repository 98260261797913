import './Badge.css'

interface IBadgeProps {
    text?: string
    size?: 'small' | 'regular' | 'large'
    color?: 'yellow' | 'blue' | 'red' | 'green' | 'gray'
};

const Badge = ({
  text,
  size = 'regular',
  color = 'gray'
}: IBadgeProps) => {
  if (!text || text.length === 0) return <></>

  const className = `badge badge--${size} badge--${color}`

  return (
    <span className={className}>
      {text}
    </span>
  )
}

export default Badge
