import './Code.css'

interface ICodeProps {
    label?: string
    text?: string
};

const Code = ({ label = '', text = '' }: ICodeProps) => {
  if (!text?.length) return <></>

  return (
    <div className="code">
      <label className="code__label">{label}</label>
      <code className="code__text">{text}</code>
    </div>
  )
}

export default Code
