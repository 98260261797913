import './Button.css'

interface IButtonProps {
    id?: string
    type?: 'primary' | 'secondary' | 'tertiary' | 'red'
    title?: string
    text: string
    icon?: any
    size?: 'small' | 'regular' | 'large'
    isFitContent?: boolean
    isDisabled?: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const Button = ({
  id,
  type = 'primary',
  title = '',
  text,
  icon = <></>,
  size = 'regular',
  isFitContent = false,
  isDisabled = false,
  onClick
}: IButtonProps) => {
  if (!text?.length) return <></>

  const classNameFitContent = isFitContent ? 'button--fit' : ''
  const classNameDisabled = isDisabled ? 'button--disabled' : ''
  const className = `button button--${type} button--${size} ${classNameFitContent} ${classNameDisabled} actioncomponent`

  return (
    <button
      id={id}
      className={className}
      disabled={isDisabled}
      onClick={onClick}
    >
      {icon ?? <></>}
      {
        title !== ''
          ? (
            <div className="button__title">
              <p>{title}</p>
              <span>{text}</span>
            </div>
          )
          : (
            <span className="button__text">{text}</span>
          )
      }
    </button>
  )
}

export default Button
