
import BatchView from './components/BatchView'

import './Batch.css'
import useBatch from './hooks/useBatch'

const Batch = () => {
  const {
    timeZoneCode,
    uploadFileData,
    fileBatchList,
    uploadFileStatus,
    newPayoutId,
    currentPayout,
    isActiveUploadButton,
    templateExtensions,
    templateList,
    uploadFileIdSelected,
    fileBatchListIsLoading,
    filterData,
    loadingToastList,
    batchToastList,
    toastClassName,
    canViewToast,
    setCurrentPayout,
    setIsDispersionStateLoading,
    handleChangeUploadFile,
    handleChangeSelectTemplate,
    handleClickCloseUploadFileStatus,
    handleClickUploadFile,
    handleScrollUploadFiles,
    handleDispersionLoadingDone,
    reloadBatchDispersionState,
    handleFilterSubmit
  } = useBatch()

  /*
     * Renderizado de la vista Batch
    */
  return (
    <BatchView
      canViewToast={canViewToast}
      toastClassName={toastClassName}
      loadingToastList={loadingToastList}
      batchToastList={batchToastList}
      timeZoneCode={timeZoneCode}
      uploadFileData={uploadFileData}
      templateExtensions={templateExtensions}
      templateList={templateList}
      fileBatchList={fileBatchList}
      uploadFileStatus={uploadFileStatus}
      uploadFileIdSelected={uploadFileIdSelected}
      reloadBatchDispersionState={reloadBatchDispersionState} // eslint-disable-line
      newPayoutId={newPayoutId}
      fileBatchListIsLoading={fileBatchListIsLoading}
      isActiveUploadButton={isActiveUploadButton}
      currentPayout={currentPayout}
      filterData={filterData}
      setCurrentPayout={setCurrentPayout}
      setIsDispersionStateLoading={setIsDispersionStateLoading}
      handleChangeUploadFile={handleChangeUploadFile} // eslint-disable-line
      handleChangeSelectTemplate={handleChangeSelectTemplate}
      handleClickCloseUploadFileStatus={handleClickCloseUploadFileStatus}
      handleClickUploadFile={handleClickUploadFile} // eslint-disable-line
      handleScrollUploadFiles={handleScrollUploadFiles}
      handleDispersionLoadingDone={handleDispersionLoadingDone}
      handleFilterSubmit={handleFilterSubmit}
    />
  )
}

export default Batch
