function isGMTOffset (offset: string) {
  if (offset?.length !== 5 && offset?.length !== 6) {
    return false
  };

  if (offset?.charAt(0) !== 'G' || offset?.charAt(1) !== 'M' || offset?.charAt(2) !== 'T') {
    return false
  };

  if (offset?.charAt(3) === '-' && offset?.charAt(4) === '0') {
    return false
  };

  const number = offset?.substring(3)
  const numberAsInteger = parseInt(number)

  return numberAsInteger >= -12 && numberAsInteger <= 12
}

export default isGMTOffset
