import Icon from '../../Icon/Icon'
import './MenuButton.css'

type IMenuButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

const MenuButton = ({
  disabled = false,
  ...restProps
}: IMenuButtonProps) => {
  const classNameDisabled = disabled ? 'menu-button--disabled' : ''
  const className = `menu-button ${classNameDisabled} actioncomponent`

  return (
    <button
      className={className}
      disabled={disabled}
      {...restProps}
    >
      <Icon type='menu' />
    </button>
  )
}

export default MenuButton
