import { useEffect, useState } from 'react'

import { useOutsideClick } from '../../../../hooks/useOutsideClick'

interface Props {
  openHamburgerMenu: boolean
}

const useSidebar = ({ openHamburgerMenu }: Props) => {
  const [isSmall, setIsSmall] = useState<boolean>(true)
  const [isToggle, setIsToggle] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  /*
     * Función que maneja el estado booleano que determina si el sidebar está minimizado
    */
  const handleSidebarToggle = () => {
    setIsSmall(!isSmall)
  }

  /*
     * Función que maneja el estado toggle del sidebar cuando este está minimizado y el cursor está dentro
    */
  const handleOnMouseEnterSidebar = (event: any) => {
    event?.stopPropagation()

    if (isSmall) {
      setIsToggle(false)
    };
  }

  /*
     * Función que maneja el estado toggle del sidebar cuando este está minimizado y el cursor está fuera
    */
  const handleOnMouseLeaveSidebar = (event: any) => {
    event?.stopPropagation()

    if (isSmall) {
      setIsToggle(false)
    };
  }

  /*
     * Función que maneja la apertura del sidebar al hacer click fuera de este y para tamaños de pantalla mobile
    */
  const sidebarRef = useOutsideClick(() => {
    if (window?.innerWidth <= 1024) {
      setIsOpen(false)
    };
  })

  /*
     * Efecto que abre el sidebar al cambiar el prop que controla la apertura del mismo desde el Home
    */
  useEffect(() => {
    setIsOpen(true)
  }, [openHamburgerMenu])

  /*
     * Efecto que se lanza en el primer renderizado y que inicializa el sidebar cerrado
    */
  useEffect(() => {
    setIsOpen(false)
  }, [])

  return {
    // States
    isSmall,
    isToggle,
    isOpen,
    sidebarRef,

    // Functions States

    // Functions
    handleSidebarToggle,
    handleOnMouseEnterSidebar,
    handleOnMouseLeaveSidebar
  }
}

export default useSidebar
