import { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { useLocation } from 'react-router-dom'

import customersDataService from '../../../../services/payoutServices/customersDataService'
import getCustomersTableData from '../../../../utils/data/getCustomersTableData'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import ModalContext from '../../../../contexts/ModalContext'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'

const useCustomers = () => {
  const companyData = useAppSelector((state) => state.authReducer.company)
  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const { pathname } = useLocation()

  const permissions = getFinalPermissions(pathname, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const [nativeData, setNativeData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [finderValue, setFinderValue] = useState<string>('')
  const [isLoadingCustomersTable, setIsLoadingCustomersTable] = useState<boolean>(true)

  const { setModalData } = useContext(ModalContext)

  const companyId = companyData?.company_id ?? ''
  const timeZoneCode = companyData?.time_zone_cod ?? ''

  /*
     * Función que maneja la petición de servicio que obtiene la data total de los Clientes del usuario
    */
  const getCustomersData = async (isRestarted: boolean, offset: number, searchValue: string) => {
    if (validPermittedServices('customersData', permissions)) {
      try {
        setIsLoadingCustomersTable(true)

        const response = await customersDataService(companyId, searchValue, 50, offset, 'invoice.customer.document_id', 'desc', reduxUser)
        const { data: responseData } = response
        const { data: currentNativeData } = responseData
        let newNativeData = currentNativeData

        if (!isRestarted) {
          newNativeData = nativeData?.concat(currentNativeData)
        };

        const customersTableData = getCustomersTableData(newNativeData)

        setNativeData(newNativeData)
        setData(customersTableData)
        setIsLoadingCustomersTable(false)
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'noAccess', service: 'customersData' })
    }
  }

  const handleScrollCustomersTableData = (event: any) => {
    const isBottom = (event?.target?.scrollHeight - event?.target?.scrollTop - 1) <= event?.target?.clientHeight

    if (isBottom && data?.body?.length >= offset + 50) {
      const newOffset = offset + 50

      setOffset(newOffset)
      setIsLoadingCustomersTable(true)

      getCustomersData(false, newOffset, finderValue) // eslint-disable-line
    };
  }

  const handleCustomersFinderValue = (event: any) => {
    const customersFinderValue = event?.target?.value

    setFinderValue(customersFinderValue)
  }

  const handleCustomersTableDataClick = (customerData: any) => {
    const externalCustomerId = customerData?.externalCustomerId

    window.location.href = `/payout/customer-detail/${externalCustomerId}` // eslint-disable-line
  }

  useEffect(() => {
    setOffset(0)

    const table = document?.getElementsByClassName('wrapper_table')?.[0]
    table.scrollTop = 0

    getCustomersData(true, 0, finderValue) // eslint-disable-line
    }, [finderValue]); // eslint-disable-line

  return {
    // States
    isLoadingCustomersTable,
    timeZoneCode,
    data,

    // Functions States

    // Functions
    handleScrollCustomersTableData,
    handleCustomersFinderValue,
    handleCustomersTableDataClick
  }
}

export default useCustomers
