import { useState } from 'react'

import { logout } from '../../../store/slices/auth'
import { useAppDispatch } from '../../../store/hooks'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import signOutService from '../../../services/cognitoServices/signOutService'

const useHeader = () => {
  const [openHeaderProfile, setOpenHeaderProfile] = useState<boolean>(false)

  const distpatch = useAppDispatch()

  /*
     * Función que abre el menú del header al pasar el cursor por encima de la imagen del profile
     * que se encuentra en el header
    */
  const handleOnMouseEnterHeaderProfile = () => {
    setOpenHeaderProfile(true)
  }

  /*
     * Función que cierra el menú del header al dar un click fuera de el mismo
    */
  const headerProfileRef = useOutsideClick(() => {
    setOpenHeaderProfile(false)
  })

  /*
     * Función que cierra la sesión desde el menú del header
    */
  const handleClickSignOut = async (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    await signOutService()
    distpatch(logout())
  }

  return {
    // States
    openHeaderProfile,
    headerProfileRef,

    // Functions States

    // Functions
    handleOnMouseEnterHeaderProfile,
    handleClickSignOut
  }
}

export default useHeader
