import Icon from '../../Icon/Icon'

import type { ITimelineItemData } from '../../../../typings/types'

import './TimelineItem.css'

interface ITimeLineItemProps {
  data: ITimelineItemData
};

const TimelineItem = ({ data }: ITimeLineItemProps) => {
  const iconClassName = `timelineitem__icon timelineitem__icon--${data.leftSection.color}`

  return (
    <div className="timelineitem">
      <div className="timelineitem__line"></div>
      <div className={iconClassName}>
        <Icon type="warning" />
      </div>
      <div className="timelineitem__content">
        <div className="timelineitem__label">
          <p>{data.leftSection.title}</p>
          <span>{data.leftSection.subtitle}</span>
        </div>
        {
          data.rightSection && (
            <div className="timelineitem__result">
              {data.rightSection.icon}
              <div className="timelineitem__result__total">
                <span>{data.rightSection.title}</span>
                <p>{data.rightSection.subtitle}</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default TimelineItem
