import BatchUploadFile from './BatchUploadFile/BatchUploadFile'
import Card from '../../../../../components/Card/Card'
import Dropdown from '../../../../../components/Dropdown/Dropdown'
import Icon from '../../../../../components/Icon/Icon'
import Loader from '../../../../../components/Loader/Loader'
import PermittedComponent from '../../../../../components/PermittedComponent/PermittedComponent'
import UploadButton from '../../../../../components/Button/UploadButton/UploadButton'

import useBatchUpload from '../../hooks/useBatchUpload'
import type { PayoutDataType, DropdownOptionsType } from '../../../../../../typings/types'

import './BatchUpload.css'

interface IBatchUploadProps {
    timeZoneCode: string
    uploadFileData: any
    templateExtensions: string
    templateList: DropdownOptionsType
    isActiveUploadButton: boolean
    fileBatchList: any
    fileBatchListIsLoading: boolean
    uploadFileStatus: any
    uploadFileIdSelected: string
    currentPayout: any
    filterData: any
    handleChangeUploadFile: React.ChangeEventHandler<HTMLInputElement> | undefined
    handleChangeSelectTemplate: (option: string) => void
    handleClickCloseUploadFileStatus: React.MouseEventHandler<HTMLSpanElement> | undefined
    handleClickUploadFile: (payout: PayoutDataType) => void
    handleScrollUploadFiles: React.UIEventHandler<HTMLDivElement> | undefined
    handleFilterSubmit: any
};

const BatchUpload = ({
  timeZoneCode,
  uploadFileData,
  templateExtensions,
  templateList,
  isActiveUploadButton,
  fileBatchList,
  fileBatchListIsLoading,
  uploadFileStatus,
  uploadFileIdSelected,
  currentPayout,
  filterData,
  handleChangeUploadFile,
  handleChangeSelectTemplate,
  handleClickCloseUploadFileStatus,
  handleClickUploadFile,
  handleScrollUploadFiles,
  handleFilterSubmit
}: IBatchUploadProps) => {
  const classNameUploadFileStatusMessage = `batchupload__status__text batchupload__status__text--${uploadFileStatus}` // eslint-disable-line

  const uploadFileName = uploadFileData?.name
  const uploadFileSize = (uploadFileData?.size / 1024).toFixed(1)
  const dropdownDefaultOption = { id: templateList?.length, name: 'template', title: 'Plantilla' }

  const uploadFileStatusMessage: any = {
    success: `${uploadFileName} (${uploadFileSize} KB)`, // eslint-disable-line
    already: 'El archivo ya fue cargado anteriormente',
    userPermitError: 'El usuario no tiene permisos para realizar esta acción',
    extensionError: 'El formato del archivo no está permitido',
    error: 'No se pudo cargar el archivo, intentar nuevamente en unos minutos'
  }

  const { payoutListData } = useBatchUpload({ fileBatchList, currentPayout })

  /*
     * Renderizado del componente de la lista de archivos subidos batch
    */
  return (
    <Card
      title="Archivos Procesados"
      subtitle={templateExtensions}
      timeZoneCode={timeZoneCode}
      filterData={filterData}
      hasFilter
      handleFilterSubmit={handleFilterSubmit}
      // eslint-disable-next-line
      children={
        <>
          <div className="batchupload__actions">
            <PermittedComponent componentId='bt-upload-files'>
              <UploadButton
                text="Cargar Archivo"
                isActive={isActiveUploadButton}
                onChange={handleChangeUploadFile}
              />
            </PermittedComponent>
            <PermittedComponent componentId='select-template-list'>
              <Dropdown
                options={templateList}
                defaultOption={dropdownDefaultOption}
                size="small"
                isCentered
                isFitContent
                isDisabled={fileBatchListIsLoading}
                isDefaultOptionHide={true}
                onChange={handleChangeSelectTemplate}
              />
            </PermittedComponent>
          </div>
          {
            uploadFileStatus !== '' && (
              <div className="batchupload__status">
                <span
                  className={classNameUploadFileStatusMessage}
                >
                  {uploadFileStatusMessage[uploadFileStatus]}
                </span>
                <span
                  className="batchupload__status__closeicon"
                  onClick={handleClickCloseUploadFileStatus}
                >
                  <Icon type="close" />
                </span>
              </div>
            )
          }
          <Loader
            isLoading={fileBatchListIsLoading}
            hasLoaderIcon={true}
          >
            <div className="batchupload__uploadfiles" onScroll={handleScrollUploadFiles}>
              {
                payoutListData?.map((fileBatch: any) => (
                  <BatchUploadFile
                    key={fileBatch?.payout_id}
                    timeZoneCode={timeZoneCode}
                    fileBatch={fileBatch}
                    isSelected={uploadFileIdSelected === fileBatch?.payout_id}
                    onClick={handleClickUploadFile}
                  />
                ))
              }
            </div>
          </Loader>
        </>
      }
    />
  )
}

export default BatchUpload
