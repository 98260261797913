import { CognitoUserPool } from 'amazon-cognito-identity-js'

const signOutService = async () => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? ''
  }

  const userPool = new CognitoUserPool(poolData)

  const cognitoUser = userPool?.getCurrentUser()

  if (cognitoUser) {
    cognitoUser.signOut(() => {
      window.location.href = process.env.REACT_APP_COGNITO_LOGOUT_URL ?? ''
    })
  };
}

export default signOutService
