import getFormattedGMTDate from './getFormattedGMTDate'

const getFormattedDate = (currentDate: any, timeZoneCode: string) => {
  if (currentDate?.split(/[- ' ' / :]/)?.length === 5) {
    const parts = currentDate?.split(' ') // Dividir en fecha y hora
    const date = parts[0]?.split(/[- /]/) // Dividir la fecha en día, mes y año

    // Nos aseguramos que el año tenga cuatro dígitos
    if (date[2]?.length === 2) {
      date[2] = '20' + date[2] // eslint-disable-line
    };

    // Obtenemos la hora en formato de 24 horas si está en formato AM/PM
    if (parts[1].match(/(\d{2}:\d{2})([APM]{2})/)) {
      const hour = parts[1]?.match(/(\d{2}:\d{2})([APM]{2})/)[1]
      const meridian = parts[1]?.match(/(\d{2}:\d{2})([APM]{2})/)[2]
      let [hh, mm] = hour?.split(':')

      if (meridian === 'PM') {
        hh = String(parseInt(hh) + 12)
      };

      return getFormattedGMTDate(new Date(`${date[2]}-${date[1]}-${date[0]}T${hh}:${mm}`), timeZoneCode) // eslint-disable-line
    };

    return getFormattedGMTDate(new Date(`${date[2]}-${date[1]}-${date[0]}T${parts[1]}`), timeZoneCode) // eslint-disable-line
  } else {
    const parts = currentDate?.split(/[- / : ' ']/)

    // Las partes son: [día, mes, año, hora, minutos, segundos]
    const day = parseInt(parts?.[0])
    const month = parseInt(parts?.[1]) - 1 // Los meses en JavaScript son 0-indexados
    const year = parseInt(parts?.[2])
    const hour = parseInt(parts?.[3])
    const minutes = parseInt(parts?.[4])
    const seconds = parseInt(parts?.[5])

    const date = new Date(year, month, day, hour, minutes, seconds)

    return getFormattedGMTDate(date, timeZoneCode)
  };
}

export default getFormattedDate
