import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import forgotPasswordService from '../../../services/cognitoServices/forgotPasswordService'
import { isEmailValid } from '../../../utils/validations/inputFieldValidations'

const useResetPassword = () => {
  const [username, setUsername] = useState<string>('')
  const [inputTouched, setInputTouched] = useState<boolean>(false)
  const [hasResetPasswordEmailError, setHasResetPasswordEmailError] = useState<boolean>(false)
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState<boolean>(false)
  const [submitResetPasswordErrorMessage, setSubmitResetPasswordErrorMessage] = useState<string>('')

  const navigate = useNavigate()

  /*
     * Función que maneja la data ingresada en los input fields de la página de 'Reset Password'
     * y la guarda en un estado al momento
    */
  const handleResetPasswordInputValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: username } = event?.target

    setUsername(username)
    setSubmitResetPasswordErrorMessage('')
  }

  /*
     * Función que marca el campo como "tocado" cuando el usuario interactúa con el input
    */
  const handleLoginInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event?.preventDefault()

    const { value } = event?.target

    if (value?.length !== 0) {
      setInputTouched(true)
    };
  }

  /*
     * Función que maneja las acciones que se disparan al hacer click
     * al botón submit de Reset Password
    */
  const handleClickSubmitButton = async (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    try {
      await forgotPasswordService(username)

      navigate('/two-steps', { state: { username } })
    } catch (error: any) {
      setSubmitResetPasswordErrorMessage(error?.message || JSON.stringify(error))
    };
  }

  /*
     * Función que maneja las acciones que se disparan al hacer click al botón cancelar de Reset Password
    */
  const handleClickCancelButton = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    navigate(process.env.REACT_APP_COGNITO_RESETPASSWORD_CANCEL_URL ?? '')
  }

  /*
     * Efecto que setea el valor del error cuando la data del usuario en el 'Reset Password' cambia
    */
  useEffect(() => {
    const validation = isEmailValid(username)

    setHasResetPasswordEmailError(!validation && inputTouched)
    setIsActiveSubmitButton(validation)
  }, [username, inputTouched])

  return {
    // States
    hasResetPasswordEmailError,
    isActiveSubmitButton,
    submitResetPasswordErrorMessage,

    // Functions States

    // Functions
    handleResetPasswordInputValues,
    handleLoginInputBlur,
    handleClickSubmitButton,
    handleClickCancelButton
  }
}

export default useResetPassword
