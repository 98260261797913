import processStatusData from '../../constants/processStatusData'

const getBatchDispersionStateDetailRadarChartData = (events: any, total: number) => {
  if (!events) return []

  const eventsArr = Object?.entries(events)

  const newEvents = eventsArr?.map((event: any) => {
    return { ...event?.[1], code: event?.[0] }
  })

  const nonNullsEvents = newEvents?.filter((event: any) => event?.status_name_str)

  const radarChartData = nonNullsEvents?.map((newEvent: any) => {
    const currentColor = processStatusData?.find((item: any) => item?.code === newEvent?.code)?.colorCode

    return {
      category: newEvent?.status_name_str,
      value: Math.round(100 * (newEvent.total_rows_int / total)) ?? 0,
      full: 100,
      columnSettings: {
        fillOpacity: 1,
        fill: currentColor
      }
    }
  })

  return radarChartData
}

export default getBatchDispersionStateDetailRadarChartData
