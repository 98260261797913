import { useContext, useEffect } from 'react'

import ModalView from './ModalView'

import ModalContext from '../../contexts/ModalContext'

import './Modal.css'

interface IModalProps {
    data: any
};

const Modal = ({ data }: IModalProps) => {
  const isOpen = data?.isOpen
  const type = data?.type
  const service = data?.service
  const message = data?.message

  const { modalData, setModalData } = useContext(ModalContext)

  const contentByType: any = {
    conexionError: {
      title: 'Fallo de conexión',
      description: 'Reintentar en un momento'
    },
    serviceError: {
      title: 'Error en el servicio',
      description: message?.length ? message : 'No puedes realizar esta acción. Contáctate con soporte'
    }
  }

  const title = contentByType?.[type]?.title
  const description = contentByType?.[type]?.description

  /*
     * Función que maneja la acción del botón continuar del Modal
    */
  const handleContinue = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()

    setModalData({ isOpen: false, type, service })
  }

  /*
     * Efecto que oculta o muestra el modal dependiendo del estado isOpen
    */
  useEffect(() => {
    if (modalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    };
    }, [isOpen]); // eslint-disable-line

  /*
     * Renderizado del componente Modal
    */
  if (!data || !isOpen) return <></>

  return (
    <ModalView
      title={title}
      description={description}
      handleContinue={handleContinue}
    />
  )
}

export default Modal
