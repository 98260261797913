interface IPermittedComponentViewProps {
  componentId: string
  className: string
  show: boolean
  children: any
};

const PermittedComponentView = ({ componentId, className, show, children }: IPermittedComponentViewProps) => {
  return (
    <div id={componentId} className={className}>
      {
        show && (
          <>
            {children}
          </>
        )
      }
    </div>
  )
}

export default PermittedComponentView
