const getFormattedMoney = (money: any) => {
  if (!money) return '0.00'

  let formattedMoney

  if (typeof money === 'string') {
    if (money?.split('.')?.[1]?.length === 2) {
      formattedMoney = money
    } else {
      formattedMoney = Number(money)?.toFixed(2)
    };
  };

  if (typeof money === 'number') {
    formattedMoney = money?.toFixed(2)
  };

  // Verificar si el número es entero
  const isInteger = money % 1 === 0

  // Convertir el número a string y dividir la parte entera y decimal
  const parts = formattedMoney?.split('.')

  // Formatear la parte entera con espacios cada 3 dígitos
  const formattedIntegerPart = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  // Formatear el resultado final
  const result = isInteger
    ? formattedIntegerPart + '.00' // eslint-disable-line
    : formattedIntegerPart + '.' + parts?.[1] // eslint-disable-line

  return result
}

export default getFormattedMoney
