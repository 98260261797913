import NewPasswordView from './components/NewPasswordView'
import NotFound from '../Error/Error'

import useNewPassword from './hooks/useNewPassword'

const NewPassword = () => {
  const {
    username,
    otpValue,
    hasConfirmNewPasswordError,
    isActiveSubmitButton,
    submitNewPasswordErrorMessage,
    setHasValidPassword,
    handleNewPasswordReturnClick,
    handleNewPasswordInputValues,
    handleLoginInputBlur,
    handleSubmitNewPassword
  } = useNewPassword()

  /*
   * Renderizado del componente visual de la página de Nueva Contraseña
   */
  if (!username || !otpValue) return <NotFound isLogged={false} />

  return (
    <NewPasswordView
      hasConfirmNewPasswordError={hasConfirmNewPasswordError}
      isActiveSubmitButton={isActiveSubmitButton}
      submitNewPasswordErrorMessage={submitNewPasswordErrorMessage}
      setHasValidPassword={setHasValidPassword}
      handleNewPasswordReturnClick={handleNewPasswordReturnClick}
      handleNewPasswordInputValues={handleNewPasswordInputValues}
      handleLoginInputBlur={handleLoginInputBlur}
      handleSubmitNewPassword={handleSubmitNewPassword} // eslint-disable-line
    />
  )
}

export default NewPassword
