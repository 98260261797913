const getPermissionsBySubMenu = (menuData: any) => {
  if (!menuData) return []
  const servicesBySubMenu: any = []

  menuData?.forEach((menu: any) => {
    menu?.sub_menu?.forEach((subMenu: any) => {
      subMenu?.view?.services?.forEach((service: any) => {
        const menuTitle: string = menu?.title_str.toLowerCase().replaceAll(' ', '-')
        const subMenuUrl: string = subMenu?.view?.url_str
        const serviceTitle: string = service
        servicesBySubMenu.push(`${menuTitle}.${subMenuUrl}.${serviceTitle}`)
        if (subMenuUrl === '/payout/customers') {
          servicesBySubMenu.push(`${menuTitle}./payout/customer-detail.${serviceTitle}`)
        } else if (subMenuUrl === '/payout/invoices') {
          servicesBySubMenu.push(`${menuTitle}./payout/invoice-detail.${serviceTitle}`)
        }
      })
    })
  })

  return servicesBySubMenu
}

export default getPermissionsBySubMenu
