import type { PayoutDataType } from '../../../../../../../typings/types'
import Icon from '../../../../../../components/Icon/Icon'
import VisTimeline from '../../../../../../components/VisTimeline/VisTimeline'
import getVisTimelineFormattedData from '../../../../../../utils/data/getVisTimelineFormattedData'

interface IBatchDispersionStatusHistorySubtitleProps {
  fileName?: string
  opened: boolean
  handleClickTrigger: () => void
  statusHistory: PayoutDataType['status_history']
}

const BatchDispersionStatusHistorySubtitle = ({
  fileName,
  opened,
  statusHistory,
  handleClickTrigger
}: IBatchDispersionStatusHistorySubtitleProps) => {
  if (!fileName) return null

  const statusHistoryTriggerClassName = `statusHistoryTrigger ${opened ? 'active' : ''}`

  return (
    <div>
      <button className={statusHistoryTriggerClassName} onClick={handleClickTrigger}>
        {fileName}
        <Icon type='alarm' />
      </button>
      {
        opened &&
          <div className='statusHistoryTimeline__wrapper'>
            <VisTimeline data={getVisTimelineFormattedData(statusHistory)} />
          </div>
      }
    </div>
  )
}

export default BatchDispersionStatusHistorySubtitle
