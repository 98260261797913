import { Helmet } from 'react-helmet'

import Button from '../../../components/Button/Button'
import InputField from '../../../components/InputField/InputField'
import Link from '../../../components/Link/Link'

interface ILoginProps {
  isLoadingLogin: boolean
    hasLoginEmailError: boolean
    hasLoginPasswordError: boolean
    submitLoginErrorMessage: string
    isActiveSubmitButton: boolean
    handleLoginInputValues: React.ChangeEventHandler<HTMLInputElement> | ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
    handleLoginInputBlurs: React.FocusEventHandler<HTMLInputElement> | undefined
    handleSubmitSignIn: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const LoginView = ({
  isLoadingLogin,
  hasLoginEmailError,
  hasLoginPasswordError,
  submitLoginErrorMessage,
  isActiveSubmitButton,
  handleLoginInputValues,
  handleLoginInputBlurs,
  handleSubmitSignIn
}: ILoginProps) => {
  /*
     * Renderizado de la página de Login
    */
  return (
    <div className="page">
      <Helmet>
        <title>OpenBanking - Login</title>
      </Helmet>
      <div className="page_container">
        <main className="page__main">
          <div className="page__main_container">
            <div className="page__main__header"></div>
            <form className="page__main__form">
              <div className="page__main__form__header">
                <h2 className="page__main__form__title">Sign In</h2>
                <p className="page__main__form__description">Get unlimited access & earn money</p>
              </div>
              <div className="page__main__form__inputfields">
                <InputField
                  name="username"
                  type="email"
                  placeholder="Email"
                  hasError={hasLoginEmailError}
                  onBlur={handleLoginInputBlurs}
                  onChange={handleLoginInputValues}
                />
                <InputField
                  name="password"
                  type="password"
                  placeholder="Password"
                  hasError={hasLoginPasswordError}
                  onBlur={handleLoginInputBlurs}
                  onChange={handleLoginInputValues}
                />
              </div>
              <div className="page__main__form__link">
                <span className="page__main__form__error">{submitLoginErrorMessage ?? ''}</span>
                <Link text="Forgot Password ?" url="/reset-password" />
              </div>
              <div className="page__main__form__buttons">
                <Button
                  text="Sign in"
                  isFitContent={true}
                  isDisabled={!isActiveSubmitButton || isLoadingLogin}
                  onClick={handleSubmitSignIn}
                />
              </div>
            </form>
            <div className="page__main__footer"></div>
          </div>
        </main>
        <aside className="page__aside">
        </aside>
      </div>
    </div>
  )
}

export default LoginView
