import CustomerDetailView from './CustomerDetailView'

import useCustomerDetail from '../../hooks/useCustomerDetail'

import './CustomerDetail.css'

const CustomerDetail = () => {
  const {
    timeZoneCode,
    customerData,
    customerInvoicesTableData,
    isLoading,
    handleCustomerInvoicesTableDataClick,
    handleScrollCustomerInvoicesTableData
  } = useCustomerDetail()

  return (
    <CustomerDetailView
      timeZoneCode={timeZoneCode}
      customerData={customerData}
      customerInvoicesTableData={customerInvoicesTableData}
      isLoading={isLoading}
      handleScrollCustomerInvoicesTableData={handleScrollCustomerInvoicesTableData}
      handleCustomerInvoicesTableDataClick={handleCustomerInvoicesTableDataClick}
    />
  )
}

export default CustomerDetail
