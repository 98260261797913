import Badge from '../../../../../../components/Badge/Badge'
import Icon from '../../../../../../components/Icon/Icon'
import Timeline from '../../../../../../components/Timeline/Timeline'
import Timer from '../../../../../../components/Timer/Timer'
import RadarChart from '../../../../../../components/Charts/RadarChart'

import processStatusData from '../../../../../../constants/processStatusData'

import { type PayoutDataType } from '../../../../../../../typings/types'

import getCapitalizedString from '../../../../../../utils/commons/getCapitalizedString'
import getBatchDispersionStateDetailTimeLineData from '../../../../../../utils/data/getBatchDispersionStateDetailTimeLineData'
import getBatchDispersionStateDetailRadarChartData from '../../../../../../utils/data/getBatchDispersionStateDetailRadarChartData'

import './BatchDispersionStateDetail.css'
import { getPayoutCreatedDtm } from '../../../../../../utils/data/getPayoutCreatedDtm'

interface IBatchDispersionStateDetailProps {
    timeZoneCode: string
    payoutDataSelected: PayoutDataType
};

const BatchDispersionStateDetail = ({ timeZoneCode, payoutDataSelected }: IBatchDispersionStateDetailProps) => {
  if (!payoutDataSelected) return <></>

  let successRequestDtm

  const events = payoutDataSelected?.events
  const status_cod = payoutDataSelected?.status_cod // eslint-disable-line
  const total_rows_int = payoutDataSelected?.total_rows_int // eslint-disable-line

  const paid = events?.paid
  const voided = events?.voided

  const initialRequestDtm = getPayoutCreatedDtm(payoutDataSelected)

  if (status_cod === 'VOIDED') {
    successRequestDtm = voided?.request_dtm
  } else {
    successRequestDtm = paid?.request_dtm
  };

  const currentProcessStatus = processStatusData.find(process => process.name.toUpperCase() === status_cod)
  const currentProcessStatusName = getCapitalizedString(currentProcessStatus?.title ?? status_cod)
  const currentProcessStatusColor = currentProcessStatus?.color

  const timelineData = getBatchDispersionStateDetailTimeLineData(events)
  const radarChartData = getBatchDispersionStateDetailRadarChartData(events, total_rows_int)

  /*
     * Renderizado del componente del detalle del Estado de Dispersión
    */
  return (
    <div className="batchdispersionstatedetail">
      <div className="batchdispersionstatedetail__payorders">
        <div className="batchdispersionstatedetail__payorders__icon">
          <Icon type="moneybag"/>
        </div>
        <div className="batchdispersionstatedetail__payorders__text">
          <span className="batchdispersionstatedetail__payorders__time">
            <Timer
              timeZoneCode={timeZoneCode}
              initialDate={initialRequestDtm}
              finalDate={successRequestDtm}
              isDone={status_cod === 'SUCCESS' || status_cod === 'VOIDED'}
            />
          </span>
          <h4 className="batchdispersionstatedetail__payorders__title">Ordenes de pago</h4>
        </div>
      </div>
      <div className="batchdispersionstatedetail__process">
        <div className="batchdispersionstatedetail__process__timeline">
          <Badge text={currentProcessStatusName} color={currentProcessStatusColor} />
          <Timeline data={timelineData} />
        </div>
        <div className="batchdispersionstatedetail__process__chart">
          <RadarChart data={radarChartData} />
        </div>
      </div>
    </div>
  )
}

export default BatchDispersionStateDetail
