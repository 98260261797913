import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'

import SidebarMenuGroupTitle from './SidebarMenuGroupTitle'
import SidebarMenuGroupItem from './SidebarMenuGroupItem'

import PermittedComponent from '../../../../components/PermittedComponent/PermittedComponent'
interface ISidebarMenuProps {
    sessionUserIndexRoute: string
    handleSidebarMenuGroupItemClick: any
};

const SidebarMenu = ({ sessionUserIndexRoute, handleSidebarMenuGroupItemClick }: ISidebarMenuProps) => {
  const [hiddenMenuGroupItems, setHiddenMenuGroupItems] = useState<string[]>([])

  const sidebarMenuData = useAppSelector(state => state.authReducer.sidebarMenuData)

  /*
     * Función que maneja la apertura y el cierre de cada item del menú en general
    */
  const handleOpenMenuGroup = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const { id: menuId } = event?.currentTarget

    let newHiddenMenuGroupItems = []

    if (hiddenMenuGroupItems.includes(menuId)) {
      newHiddenMenuGroupItems = hiddenMenuGroupItems?.filter(id => id !== menuId)
    } else {
      newHiddenMenuGroupItems = hiddenMenuGroupItems.concat(menuId)
    };

    setHiddenMenuGroupItems(newHiddenMenuGroupItems)
  }

  /*
     * Efecto que se lanza en el primer renderizado y que inicializa el estado que maneja el array
     * de identificadores de los items del menú que estan cerrados
    */
  useEffect(() => {
    const initialHiddenMenuGroupItems = sidebarMenuData?.map((menu: any) => menu?.title_str) ?? []

    setHiddenMenuGroupItems(initialHiddenMenuGroupItems)
    }, []); // eslint-disable-line

  /*
     * Renderizado del menu del layout que contiene el Sidebar
    */
  return (
    <div className="sidebar__body">
      <div className="sidebar__body_container">
        {
          sidebarMenuData?.map((menuData: any) => (
            <div
              key={menuData?.title_str}
              className="sidebar__body__group"
            >
              <SidebarMenuGroupTitle
                id={menuData?.title_str}
                title={menuData?.title_str}
                disabled={!menuData?.sub_menu?.length}
                open={hiddenMenuGroupItems.includes(menuData?.title_str)}
                onClick={handleOpenMenuGroup}
              />
              {
                hiddenMenuGroupItems.includes(menuData?.title_str) && (
                  <ul className="sidebar__body__group__items">
                    {
                      menuData?.sub_menu?.map((subMenu: any) => {
                        if (subMenu?.view?.url_str === '/payout/statement') {
                          return (
                            <PermittedComponent key={subMenu?.title_str} componentId={'statement-menu-item'}>
                              <SidebarMenuGroupItem
                                key={subMenu?.title_str}
                                id={subMenu?.title_str}
                                title={subMenu?.title_str}
                                url={subMenu?.view?.url_str}
                                sessionUserIndexRoute={sessionUserIndexRoute}
                                disabled={!subMenu?.view?.url_str}
                                handleSidebarMenuGroupItemClick={handleSidebarMenuGroupItemClick}
                              />
                            </PermittedComponent>
                          )
                        } else {
                          return (
                            <SidebarMenuGroupItem
                              key={subMenu?.title_str}
                              id={subMenu?.title_str}
                              title={subMenu?.title_str}
                              url={subMenu?.view?.url_str}
                              sessionUserIndexRoute={sessionUserIndexRoute}
                              disabled={!subMenu?.view?.url_str}
                              handleSidebarMenuGroupItemClick={handleSidebarMenuGroupItemClick}
                            />
                          )
                        }
                      })
                    }
                  </ul>
                )
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default SidebarMenu
