import './Loader.css'

interface ILoaderProps {
    isLoading?: boolean
    hasLoaderIcon?: boolean
    children?: any
};

const Loader = ({
  isLoading = true,
  hasLoaderIcon = false,
  children = <></>
}: ILoaderProps) => {
  const className = isLoading ? 'wrapper_loader--loading' : 'wrapper_loader'

  const hasChildren = children?.props?.children?.length !== 0

  return (
    <div className={className}>
      {children}
      {
        (hasLoaderIcon && hasChildren) && (
          <span className="loader"></span>
        )
      }
    </div>
  )
}

export default Loader
