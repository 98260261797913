/**
 * Get role name by id
 * @param roleId
 * @returns
 */
const getRoleById = (roleId: string) => {
  switch (roleId) {
    case '1':
      return 'admin'
    case '2':
      return 'support'
    case '3':
      return 'approver'
    case '4':
      return 'reader'
    default:
      return 'reader'
  }
}

export default getRoleById
