import validServicesData from '../../constants/validServicesData'
import removeCurlyBraces from '../commons/removeCurlyBraces'
import removeParams from '../commons/removeParams'

const validPermittedServices = (service: string, permittedServices: any) => {
  if (!service || !permittedServices) return

  if (!validServicesData?.[service]) return false

  const instance = validServicesData?.[service]

  const instanceService = instance?.service
  const instanceEndPoint = instance?.endPoint
  const instanceMethod = instance?.method

  for (const permittedService of permittedServices) {
    if (
      permittedService?.service === instanceService &&
            removeCurlyBraces(removeParams(permittedService?.endPoint)) === removeCurlyBraces(instanceEndPoint) &&
            permittedService?.methods?.includes(instanceMethod)
    ) {
      return true
    };
  };

  return false
}

export default validPermittedServices
