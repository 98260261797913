import { Fragment } from 'react'

const isElement = (value: unknown): value is React.ReactElement => {
  if (Array.isArray(value) || value === null) {
    return false
  }

  if (typeof value === 'object') {
    if ((value as { type: unknown }).type === Fragment) {
      return false
    }

    return true
  }

  return false
}

export default isElement
