import { Fragment, cloneElement, useState } from 'react'
import isElement from '../../utils/react/isElement'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import Icon from '../Icon/Icon'
import MenuButton from './MenuButton/MenuButton'
import PermittedComponent from '../PermittedComponent/PermittedComponent'
import '../Menu/Menu.css'

interface IMenuItemWithoutItems {
  id: string
  label: string
  disabled?: boolean
  items?: undefined
  render?: undefined
  permissionId?: string
  onClick?: (event: React.MouseEvent<HTMLLIElement>) => void
}

interface IMenuItemWithRender {
  id: string
  label?: undefined
  disabled?: boolean
  items?: undefined
  render: React.ReactNode
  permissionId?: string
  onClick?: undefined
}

interface TMenuItemWithItems {
  id: string
  label: string
  disabled?: boolean
  items: ISubMenuItem[]
  render?: undefined
  permissionId?: string
  onClick?: undefined
}

export type ISubMenuItem = IMenuItemWithoutItems | IMenuItemWithRender
export type IMenuItem = TMenuItemWithItems | IMenuItemWithoutItems | IMenuItemWithRender

interface IMenuProps {
  opened: boolean
  disabled?: boolean
  title?: string
  target?: React.ReactNode
  items: IMenuItem[]
  onChange: (opened: boolean) => void
}

const Menu = (props: IMenuProps) => {
  const {
    disabled = false,
    opened,
    title = 'Acciones',
    target = <MenuButton />,
    items: menuItems,
    onChange
  } = props
  const [activeMenuItemId, setActiveMenuItemId] = useState<string>()
  const menuRef = useOutsideClick(() => { onChange(false) })
  const classNameOpen = opened ? 'menu--open' : ''
  const classNameDisabled = disabled ? 'menu--disabled' : ''
  const className = `menu ${classNameOpen} ${classNameDisabled}`

  if (!isElement(target)) {
    throw new Error(
      'Menu target prop should be an element. Fragments, strings, numbers and other primitive values are not supported'
    )
  }

  /*
    * Función que maneja la apertura del menu al pasar el mouse por encima
    * de una opción
  */
  const handleOnMouseEnterMenuItem = (event: React.MouseEvent<HTMLLIElement>) => {
    setActiveMenuItemId(event.currentTarget.id)
  }

  /*
    * Función que maneja el cierre del menu al pasar el mouse fuera
    * de una opción
  */
  const handleOnMouseLeaveMenuItem = (event: React.MouseEvent<HTMLLIElement>) => {
    setActiveMenuItemId(undefined)
  }

  const handleSelectMenuItem =
    (callback: ((event: React.MouseEvent<HTMLLIElement>) => void) | undefined) =>
      (event: React.MouseEvent<HTMLLIElement>) => {
        if (!callback) return null
        setActiveMenuItemId(undefined)
        onChange(false)
        callback(event)
      }

  const renderSubmenuItems = (subMenuItems: ISubMenuItem[]) =>
    subMenuItems.map(subMenuItem => {
      if (!subMenuItem.label && !subMenuItem.render) {
        throw new Error('Submenu item component should have label or render as props')
      }

      const subMenuItemProps = !subMenuItem.render && {
        className: `menu__item ${subMenuItem.disabled ? 'menu__item--disabled' : ''}`,
        onClick: handleSelectMenuItem(subMenuItem.onClick)
      }

      const content = (
        <li
          id={subMenuItem.id}
          {...subMenuItemProps}
        >
          {
            subMenuItem.render && isElement(subMenuItem.render)
              ? cloneElement(subMenuItem.render, {
                id: subMenuItem.id,
                onClick: handleSelectMenuItem(subMenuItem.render.props.onClick)
              })
              : subMenuItem.label
          }
        </li>
      )

      return (
        subMenuItem.permissionId
          ? (
            <PermittedComponent
              key={subMenuItem.id}
              componentId={subMenuItem.permissionId}
            >
              {content}
            </PermittedComponent>
          )
          : (
            <Fragment key={subMenuItem.id}>
              {content}
            </Fragment>
          )
      )
    })

  const renderMenuItems = (menuItems: IMenuItem[]) =>
    menuItems.map(menuItem => {
      if (!menuItem.label && !menuItem.render) {
        throw new Error('Menu item component should have label or render as props')
      }

      const menuItemProps = !menuItem.render && {
        id: menuItem.id,
        className: `menu__item ${menuItem.disabled ? 'menu__item--disabled' : ''}`,
        onClick: handleSelectMenuItem(menuItem.onClick)
      }
      const menuItemWithChildrenProps = menuItem.items && {
        id: menuItem.id,
        onMouseEnter: handleOnMouseEnterMenuItem,
        onMouseLeave: handleOnMouseLeaveMenuItem
      }

      const content = (
        <li
          {...menuItemProps}
          {...menuItemWithChildrenProps}
        >
          {
            menuItem.render && isElement(menuItem.render)
              ? cloneElement(menuItem.render, {
                id: menuItem.id,
                onClick: handleSelectMenuItem(menuItem.render.props.onClick)
              })
              : (
                <>
                  {menuItem.label}
                  {
                    menuItem.items && (
                      <>
                        <div className="menu__item__icon">
                          <Icon type="arrow" />
                        </div>
                        {
                          activeMenuItemId === menuItem.id && (
                            <ul className="wrapper__menu wrapper__menu--submenu">
                              {renderSubmenuItems(menuItem.items)}
                            </ul>
                          )
                        }
                      </>
                    )
                  }
                </>
              )
          }
        </li>
      )

      return (
        menuItem.permissionId
          ? (
            <PermittedComponent
              key={menuItem.id}
              componentId={menuItem.permissionId}
            >
              {content}
            </PermittedComponent>
          )
          : (
            <Fragment key={menuItem.id}>
              {content}
            </Fragment>
          )
      )
    })

  return (
    <div className={className} ref={menuRef}>
      {cloneElement(target, {
        onClick: () => { onChange(!opened) }
      })}
      {
        opened && (
          <div className="wrapper__menu">
            <div className="wrapper__menu__heading">
              {title}
            </div>
            <ul className="wrapper__menu__list">
              {renderMenuItems(menuItems)}
            </ul>
          </div>
        )
      }
    </div>
  )
}

export default Menu
