import './NavItem.css'

interface INavItemProps {
    name: string
    icon?: any
    selected?: boolean
    disabled?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
};

const NavItem = ({
  name,
  icon = <></>,
  selected = false,
  disabled = false,
  onClick = () => {}
}: INavItemProps) => {
  if (!name?.length) return <></>

  const classNameSelected = selected ? 'navitem--selected' : ''
  const classNameDisabled = disabled ? 'navitem--disabled' : ''
  const className = `navitem ${classNameSelected} ${classNameDisabled} actioncomponent`

  return (
    <div id={`navitem--${name}`} className={className} onClick={onClick}>
      <div className="navitem_container">
        <div className="navitem__icon">
          {icon}
        </div>
        <p className="navitem__name">{name}</p>
        <span className="navitem__bullet"></span>
      </div>
    </div>
  )
}

export default NavItem
