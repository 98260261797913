
import BatchDispersionStateView from './BatchDispersionStateView'

import useBatchDispersionState from '../../hooks/useBatchDispersionState'

import './BatchDispersionState.css'
import type { PayoutDataType } from '../../../../../../typings/types'

interface IBatchDispersionStateProps {
    timeZoneCode: string
    uploadFileIdSelected: string
    reloadBatchDispersionState: () => void
    newPayoutId: string
    currentPayout: PayoutDataType
    setCurrentPayout: any
    setIsDispersionStateLoading: any
    handleDispersionLoadingDone: (option: boolean) => void
};

const BatchDispersionState = ({
  timeZoneCode,
  uploadFileIdSelected = '',
  reloadBatchDispersionState,
  newPayoutId,
  currentPayout,
  setCurrentPayout,
  setIsDispersionStateLoading,
  handleDispersionLoadingDone
}: IBatchDispersionStateProps) => {
  const {
    fileNameSelected,
    payoutDataSelected,
    bankSelected,
    processStatus,
    transactionReportsOptions,
    transactionReportsRef,
    isLoading,
    isProcess,
    isOpenTransactionReports,
    statusHistoryTimelineOpened,
    handleClickSelectBank,
    handleClickSelectExport,
    handleClickTransactionReport,
    handleClickTransactionReportsBanks,
    handleClickStatusHistoryTrigger
  } = useBatchDispersionState({
    timeZoneCode,
    uploadFileIdSelected,
    currentPayout,
    setCurrentPayout,
    setIsDispersionStateLoading,
    reloadBatchDispersionState,
    handleDispersionLoadingDone
  })

  /*
     * Renderizado del componente del Estado de Dispersión del archivo subido Batch
    */
  return (
    <BatchDispersionStateView
      timeZoneCode={timeZoneCode}
      fileNameSelected={fileNameSelected}
      payoutDataSelected={payoutDataSelected}
      bankSelected={bankSelected}
      uploadFileIdSelected={uploadFileIdSelected}
      processStatus={processStatus}
      transactionReportsOptions={transactionReportsOptions}
      transactionReportsRef={transactionReportsRef}
      isLoading={isLoading}
      isProcess={isProcess}
      isOpenTransactionReports={isOpenTransactionReports}
      statusHistoryTimelineOpened={statusHistoryTimelineOpened}
      handleClickSelectExport={handleClickSelectExport} // eslint-disable-line
      handleClickSelectBank={handleClickSelectBank}
      handleClickTransactionReport={handleClickTransactionReport}
      handleClickTransactionReportsBanks={handleClickTransactionReportsBanks}
      handleClickStatusHistoryTrigger={handleClickStatusHistoryTrigger}
    />
  )
}

export default BatchDispersionState
