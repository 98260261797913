export const isEmailValid = (email: string): boolean => {
  return /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email)
}

export const hasEightOrMoreChar = (str: string): boolean => {
  return str?.length >= 8
}

export const hasNumbers = (str: string): boolean => {
  const regex = /\d/

  return regex?.test(str)
}

export const hasUppercase = (str: string): boolean => {
  const regex = /[A-Z]/

  return regex?.test(str)
}

export const hasSymbols = (str: string): boolean => {
  const regex = /[^a-zA-Z0-9]/

  return regex?.test(str)
}

export const isPasswordValid = (password: string): boolean => {
  return hasEightOrMoreChar(password) && hasNumbers(password) && hasUppercase(password) && hasSymbols(password)
}
