import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import OtpInput from 'react-otp-input'

import Link from '../../../components/Link/Link'
import Icon from '../../../components/Icon/Icon'
import Button from '../../../components/Button/Button'

interface INewPasswordViewProps {
    username: string
    isActiveSubmitButton: boolean
    handleOtpInputValue: any
    handleClickSubmitButton: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const TwoStepsView = ({ username = '', isActiveSubmitButton, handleOtpInputValue, handleClickSubmitButton }: INewPasswordViewProps) => {
  const [otpInputValue, setOtpInputValue] = useState<string>('')

  useEffect(() => {
    handleOtpInputValue(otpInputValue)
  }, [otpInputValue])

  return (
    <div className="page">
      <Helmet>
        <title>OpenBanking - Two Steps</title>
      </Helmet>
      <div className="page_container">
        <main className="page__main">
          <div className="page__main_container">
            <div className="page__main__header">
              <div className="page__main__header_container">
                <Link url="/reset-password">
                  <div className="page__main__header_return">
                    <Icon type="back" />
                  </div>
                </Link>
                <div className="page__main__header__text">
                  <span>Already a member?</span>
                  <Link text="Sign In" />
                </div>
              </div>
            </div>
            <form className="page__main__form">
              <div className="page__main__form__header">
                <h2 className="page__main__form__title">Two Step Verification</h2>
                <p className="page__main__form__description">Enter the verification code we sent to {username}</p>
              </div>
              <div className="page__main__form__inputfields">
                <OtpInput
                  value={otpInputValue}
                  containerStyle="page__main__form__wrapper_otpinput"
                  inputStyle="page__main__form__otpinput"
                  onChange={setOtpInputValue}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  inputType="number"
                  shouldAutoFocus={true}
                />
              </div>
              <div className="page__main__form__buttons">
                <Button
                  text="Submit"
                  isFitContent={true}
                  isDisabled={!isActiveSubmitButton}
                  onClick={handleClickSubmitButton}
                />
              </div>
            </form>
            <div className="page__main__footer"></div>
          </div>
        </main>
        <aside className="page__aside">
        </aside>
      </div>
    </div>
  )
}

export default TwoStepsView
