import { useState, useEffect } from 'react'

import Icon from '../../Icon/Icon'

import getCorrectNewPassword from '../../../utils/validations/getCorrectNewPassword'

import './InputFieldPassword.css'

interface IInputFieldPasswordProps {
    name: string
    placeholder?: string
    hasBars?: boolean
    setHasValidPassword?: any
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
};

const InputFieldPassword = ({
  name,
  placeholder = '',
  hasBars = false,
  setHasValidPassword = (hasValidPassword: boolean) => {},
  onBlur = () => {},
  onChange = () => {}
}: IInputFieldPasswordProps) => {
  const [value, setValue] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [conditions, setConditions] = useState<boolean[]>([false, false, false, false])

  /*
     * Función que maneja el estado de valor de la contraseña, además de si cumple
     * con las 4 condiciones
    */
  const handleInputFieldPasswordValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target

    const conditions = getCorrectNewPassword(value)

    setValue(value)
    setConditions(conditions)

    if (typeof onChange === 'function') {
      onChange(event)
    };
  }

  /*
     * Función que maneja el estado que muestra u oculta la contraseña del inputfield
    */
  const handleShowPassword = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (!conditions) return

    if (typeof setHasValidPassword === 'function') {
      setHasValidPassword(conditions?.filter(condition => condition)?.length === 4)
    };
  }, [conditions, setHasValidPassword])

  if (!name?.length) return <></>

  return (
    <div className="inputfieldpassword">
      <input
        className="inputfieldpassword__input"
        type={showPassword ? 'text' : 'password'}
        name={name}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={handleInputFieldPasswordValue}
        autoComplete="off"
      />
      <span
        className="inputfieldpassword__input__icon"
        onClick={handleShowPassword}
      >
        {
          showPassword
            ? (
              <Icon type="eye" />
            )
            : (
              <Icon type="eyeslash" />
            )
        }
      </span>
      {
        hasBars && (
          <>
            <div className="inputfieldpassword__bars">
              {
                conditions?.map((condition, idx) => {
                  if (condition) {
                    return (
                      <div key={idx} className="inputfieldpassword__bar inputfieldpassword__bar--active"></div>
                    )
                  } else {
                    return <div key={idx} className="inputfieldpassword__bar"></div>
                  };
                })
              }
            </div>
            <p className="inputfieldpassword__text">Use 8 or more characters with a mix of letters, numbers & symbols.</p>
          </>
        )
      }
      <span className="inputfield__error">The value is not a valid password</span>
    </div>
  )
}

export default InputFieldPassword
