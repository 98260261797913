import SidebarView from './components/SidebarView'

import useSidebar from './components/hooks/useSidebar'

import './Sidebar.css'

interface ISidebarProps {
  sessionUserIndexRoute: string
  openHamburgerMenu: boolean
  canOnMouseEnterSidebar?: boolean
  handleSidebarMenuGroupItemClick: any
}

const Sidebar = ({
  sessionUserIndexRoute,
  openHamburgerMenu,
  canOnMouseEnterSidebar = true,
  handleSidebarMenuGroupItemClick
}: ISidebarProps) => {
  const {
    isSmall,
    isToggle,
    isOpen,
    sidebarRef,
    handleOnMouseEnterSidebar,
    handleOnMouseLeaveSidebar,
    handleSidebarToggle
  } = useSidebar({ openHamburgerMenu })

  /*
   * Renderizado del layout que contiene el Sidebar del Home
   */
  return (
    <SidebarView
      sessionUserIndexRoute={sessionUserIndexRoute}
      sidebarRef={sidebarRef}
      isSmall={isSmall}
      isOpen={isOpen}
      isToggle={isToggle}
      handleOnMouseEnterSidebar={handleOnMouseEnterSidebar}
      handleOnMouseLeaveSidebar={handleOnMouseLeaveSidebar}
      handleSidebarToggle={handleSidebarToggle}
      handleSidebarMenuGroupItemClick={handleSidebarMenuGroupItemClick}
    />
  )
}

export default Sidebar
