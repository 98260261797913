import { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import BatchUpload from './BatchUpload/BatchUpload'
import BatchDispersionState from './BatchDispersionState/BatchDispersionState'
import BatchProcessToast from './BatchProcessToast'

import getStringFromArray from '../../../../utils/commons/getStringFromArray'
import getFormattedFileUploadDate from '../../../../utils/date/getFormattedFileUploadDate'

import 'react-toastify/dist/ReactToastify.css'
import { type PayoutDataType } from '../../../../../typings/types'

interface IBatchViewProps {
    canViewToast: boolean
    toastClassName: string
    loadingToastList: boolean
    batchToastList: PayoutDataType[] | null
    timeZoneCode: string
    uploadFileData: any
    templateExtensions: any
    templateList: any
    fileBatchList: any
    uploadFileStatus: any
    uploadFileIdSelected: string
    reloadBatchDispersionState: () => void
    newPayoutId: string
    fileBatchListIsLoading: boolean
    isActiveUploadButton: boolean
    currentPayout: PayoutDataType
    filterData: any
    setCurrentPayout: any
    setIsDispersionStateLoading: any
    handleChangeUploadFile: React.ChangeEventHandler<HTMLInputElement> | undefined
    handleChangeSelectTemplate: (option: string) => void
    handleClickCloseUploadFileStatus: React.MouseEventHandler<HTMLSpanElement> | undefined
    handleClickUploadFile: (payout: PayoutDataType) => void
    handleScrollUploadFiles: React.UIEventHandler<HTMLDivElement> | undefined
    handleDispersionLoadingDone: (option: boolean) => void
    handleFilterSubmit: any
};

const BatchView = ({
  toastClassName,
  canViewToast,
  loadingToastList,
  batchToastList,
  timeZoneCode,
  uploadFileData,
  templateExtensions,
  templateList,
  fileBatchList,
  uploadFileStatus,
  uploadFileIdSelected,
  reloadBatchDispersionState,
  newPayoutId,
  fileBatchListIsLoading,
  isActiveUploadButton,
  currentPayout,
  filterData,
  setCurrentPayout,
  setIsDispersionStateLoading,
  handleChangeUploadFile,
  handleChangeSelectTemplate,
  handleClickCloseUploadFileStatus,
  handleClickUploadFile,
  handleScrollUploadFiles,
  handleDispersionLoadingDone,
  handleFilterSubmit
}: IBatchViewProps) => {
  const prevBatchToastListRef = useRef<any>()
  const [toastIds, setToastIds] = useState<any>([])

  const notify = (currentBatch: any) => {
    const toastId = toast(
      <BatchProcessToast currentBatch={currentBatch} timeZoneCode={timeZoneCode} />,
      {
        position: 'top-center',
        hideProgressBar: true,
        className:
          currentBatch?.status_cod === 'WAITING'
            ? 'batchupload__toast batchupload__toast--waiting'
            : 'batchupload__toast batchupload__toast--processing',
        autoClose: 1200000,
        closeButton: toastClassName !== 'cant-execute-toast'
      })
    setToastIds((prevToastIds: any) => [...prevToastIds, toastId])
  }

  useEffect(() => {
    if (!loadingToastList && batchToastList && canViewToast && toastClassName !== 'cant-view-toast') {
      if (!toastIds.some((toastId: any) => toast.isActive(toastId))) {
        batchToastList.forEach(currentBatch => {
          const browserTitle = `${getFormattedFileUploadDate(currentBatch, timeZoneCode)} Dispersión`
          const browserDescription = ['PROCESSING', 'CHECKING'].includes(currentBatch?.status_cod)
            ? 'Proceso de payout cargado'
            : 'Proceso payout en espera de aprobación'
          notify(currentBatch)
          showNotification(browserTitle, browserDescription)
        })
      } else if (JSON.stringify(prevBatchToastListRef.current) !== JSON.stringify(batchToastList)) {
        batchToastList.forEach((currentBatch: any) => {
          const browserTitle = `${getFormattedFileUploadDate(currentBatch, timeZoneCode)} Dispersión`
          const browserDescription = ['PROCESSING', 'CHECKING'].includes(currentBatch?.status_cod)
            ? 'Proceso de payout cargado'
            : 'Proceso payout en espera de aprobación'
          notify(currentBatch)
          showNotification(browserTitle, browserDescription)
        })
      }
    }
    prevBatchToastListRef.current = batchToastList
  }, [loadingToastList, batchToastList])

  const showNotification = (title: string, description: string) => {
    if (Notification.permission === 'granted') {
      // eslint-disable-next-line no-new
      new Notification(title, {
        body: description
      })
    } else {
      console.log('Los permisos de notificación no han sido otorgados.')
    }
  }

  return (
    <div className="view batch">
      <ToastContainer />
      <div className="view__header">
        <h1>Batch Payout</h1>
        <h2>Home - Payouts</h2>
      </div>
      <div className="view__body">
        <BatchUpload
          timeZoneCode={timeZoneCode}
          uploadFileData={uploadFileData}
          templateExtensions={getStringFromArray(templateExtensions)}
          templateList={templateList}
          fileBatchList={fileBatchList}
          fileBatchListIsLoading={fileBatchListIsLoading}
          isActiveUploadButton={isActiveUploadButton}
          uploadFileStatus={uploadFileStatus}
          uploadFileIdSelected={uploadFileIdSelected}
          currentPayout={currentPayout}
          filterData={filterData}
          handleChangeUploadFile={handleChangeUploadFile}
          handleChangeSelectTemplate={handleChangeSelectTemplate}
          handleClickCloseUploadFileStatus={handleClickCloseUploadFileStatus}
          handleClickUploadFile={handleClickUploadFile}
          handleScrollUploadFiles={handleScrollUploadFiles}
          handleFilterSubmit={handleFilterSubmit}
        />
        <BatchDispersionState
          timeZoneCode={timeZoneCode}
          uploadFileIdSelected={uploadFileIdSelected}
          reloadBatchDispersionState={reloadBatchDispersionState}
          newPayoutId={newPayoutId}
          currentPayout={currentPayout}
          setCurrentPayout={setCurrentPayout}
          setIsDispersionStateLoading={setIsDispersionStateLoading}
          handleDispersionLoadingDone={handleDispersionLoadingDone}
        />
      </div>
    </div>
  )
}

export default BatchView
