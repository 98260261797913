const getUtcOffHours = (timeZoneHours: number) => {
  const timeZoneHoursStr = timeZoneHours?.toString()
  const timeZoneHoursStrFormatted = Math.abs(timeZoneHours) < 10 ? `0${Math.abs(timeZoneHours)}` : timeZoneHoursStr

  if (timeZoneHours >= 0) {
    return `+${timeZoneHoursStrFormatted}:00`
  } else {
    return `-${timeZoneHoursStrFormatted}:00`
  };
}

export default getUtcOffHours
