const getCurrencySymbolFromCode = (code: string) => {
  if (code === 'PEN') {
    return 'S/'
  };

  if (code === 'USD') {
    return '$'
  };

  return code
}

export default getCurrencySymbolFromCode
