import { hasEightOrMoreChar, hasNumbers, hasSymbols, hasUppercase } from './inputFieldValidations'

const getCorrectNewPassword = (str: string): boolean[] => {
  const sizeCondition = hasEightOrMoreChar(str)
  const hasNumCondition = hasNumbers(str)
  const uppercaseCondition = hasUppercase(str)
  const symbolsCondition = hasSymbols(str)

  const arrConditions = [sizeCondition, hasNumCondition, uppercaseCondition, symbolsCondition]
  const sortedArrConditions = arrConditions?.sort((a, b) => Number(b) - Number(a))

  return sortedArrConditions
}

export default getCorrectNewPassword
