import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '../../../store/hooks'
import { login } from '../../../store/slices/auth'

import getPermissionsServices from '../../../utils/permissions/getPermissionsServices'
import getPermissionsBySubMenu from '../../../utils/permissions/getPermissionsBySubMenu'
import signInService from '../../../services/cognitoServices/signInService'
import { isEmailValid, isPasswordValid } from '../../../utils/validations/inputFieldValidations'

import { InitialInputsTouched, InitialUserLoginData } from '../../../constants/initialData'
import { type InputsTouchedType, type UserLoginDataType } from '../../../../typings/types'
import { getViewsByUserRole } from '../../../utils/permissions/getViewsByUserRole'
import { getComponentsByUserRole } from '../../../utils/permissions/getPermittedComponents'
import getSidebarMenuData from '../../../utils/permissions/getSidebarMenuData'

const useLogin = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const [userLoginData, setUserLoginData] = useState<UserLoginDataType>(InitialUserLoginData)
  const [inputsTouched, setInputsTouched] = useState<InputsTouchedType>(InitialInputsTouched)
  const [hasLoginEmailError, setHasLoginEmailError] = useState<boolean>(false)
  const [hasLoginPasswordError, setHasLoginPasswordError] = useState<boolean>(false)
  const [submitLoginErrorMessage, setSubmitLoginErrorMessage] = useState<string>('')
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState<boolean>(false)

  const navigate = useNavigate()
  const distpatch = useAppDispatch()

  /*
     * Función que maneja la data ingresada en los input fields de la página de 'Login'
     * y la guarda en un objeto de estado al momento
    */
  const handleLoginInputValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target

    setUserLoginData({ ...userLoginData, [name]: value })
    setSubmitLoginErrorMessage('')
  }

  /*
     * Función que marca el campo como "tocado" cuando el usuario interactúa con los inputs
    */
  const handleLoginInputBlurs = (event: React.FocusEvent<HTMLInputElement>) => {
    event?.preventDefault()

    const { name, value } = event?.target

    if (value?.length !== 0) {
      setInputsTouched({ ...inputsTouched, [name]: true })
    };
  }

  /*
     * Función que maneja las acciones que se disparan al hacer click al botón submit del Login
    */
  const handleSubmitSignIn = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault()

    const { username, password } = userLoginData

    try {
      setIsLoadingLogin(true)
      const cognitoData = await signInService(username, password)
      const userData = JSON.parse(cognitoData?.idToken?.payload?.company_info.replaceAll("'", '"'))
      const servicesData = userData?.modules?.at(0)?.layers?.services
      const menuData = userData?.modules?.at(0)?.layers?.views?.menu
      const views = userData?.modules?.at(0)?.layers?.views

      distpatch(login({
        user: {
          user_id: userData?.users?.at(0)?.user_id,
          name_str: userData?.users?.at(0)?.name_str,
          email_str: userData?.users?.at(0)?.email_str,
          role_id: userData?.users?.at(0)?.role_id,
          route_index_str: userData?.users?.at(0)?.route_index_str,
          secret_key: userData?.users?.at(0)?.secret_key
        },
        company: {
          company_id: userData?.company_id,
          name_str: userData?.name_str,
          time_zone_cod: userData?.time_zone_cod,
          files: userData?.modules?.at(0)?.templates?.batch?.files
        },
        permissions: getViewsByUserRole(views, userData?.users?.at(0)?.role_id),
        services: getPermissionsServices(servicesData, userData?.users?.at(0)?.role_id),
        servicesBySubMenu: getPermissionsBySubMenu(menuData),
        components: getComponentsByUserRole(views, userData?.users?.at(0)?.role_id),
        sidebarMenuData: getSidebarMenuData(menuData)
      }))

      navigate(process.env.REACT_APP_COGNITO_LOGIN_SUCCESS_URL ?? '')
    } catch (error: any) {
      setSubmitLoginErrorMessage(error?.message || JSON.stringify(error))
      setIsLoadingLogin(false)
    } finally {
      setIsLoadingLogin(false)
    }
  }

  /*
     * Efecto que setea el valor del error cuando la data del usuario en el Login cambia
    */
  useEffect(() => {
    if (!userLoginData) return

    const { username, password } = userLoginData
    const { username: usernameBlur, password: passwordBlur } = inputsTouched

    const emailValidation = isEmailValid(username)
    const passwordValidation = isPasswordValid(password)

    setHasLoginEmailError(!emailValidation && usernameBlur)
    setHasLoginPasswordError(!passwordValidation && passwordBlur)

    setIsActiveSubmitButton(emailValidation && passwordValidation)
  }, [userLoginData, inputsTouched])

  return {
    // States
    hasLoginEmailError,
    hasLoginPasswordError,
    submitLoginErrorMessage,
    isActiveSubmitButton,
    isLoadingLogin,

    // Function States

    // Functions
    handleLoginInputValues,
    handleLoginInputBlurs,
    handleSubmitSignIn
  }
}

export default useLogin
