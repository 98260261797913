export const getComponentsByUserRole = (views: any, userRole: string) => {
  if (!views || !userRole) return

  const allComponents = []
  let payoutCustomersComponents = null

  for (const menu of views?.menu) {
    for (const submenu of menu?.sub_menu) {
      allComponents.push({
        url: submenu?.view?.url_str,
        components: submenu?.view?.components
      })

      if (submenu?.view?.url_str === '/payout/customers') {
        payoutCustomersComponents = submenu?.view?.components
      };
    };
  };

  return { allComponents, payoutCustomersComponents }
}

export const getPermittedComponents = (allComponents: any, payoutCustomersComponents: any, currentUrl: string, userRole: string) => {
  if (!allComponents || !currentUrl || !userRole) return

  let components = allComponents?.find((comp: any) => comp?.url === currentUrl)?.components

  if (!components) components = []

  if (currentUrl?.includes('/payout/customer-detail/') || currentUrl?.includes('/payout/invoice-detail/')) {
    components = payoutCustomersComponents
  };

  const permittedComponents = []

  for (const component of components) {
    permittedComponents?.push({ component_str: Object?.keys(component)?.[0], permissions: component?.[Object?.keys(component)?.[0]]?.roles?.[userRole]?.permissions })
  };

  return permittedComponents
}
