import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'

import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import getFormattedGMTtoDMYDate from '../../../../utils/date/getFormattedGMTtoDMYDate'
import getTemplateOptionsData from '../../../../utils/data/getTemplateOptionsData'
import invoiceBatchService from '../../../../services/payoutServices/invoiceBatchService'
import listFileBatchService from '../../../../services/payoutServices/listFileBatchService'
import ModalContext from '../../../../contexts/ModalContext'
import removeSpaces from '../../../../utils/commons/removeSpaces'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'
import useBatchToast from './useBatchToast'

import { InitialFilterData, InitialPayoutData } from '../../../../constants/initialData'
import type { PayoutDataType, UploadFileStatusType } from '../../../../../typings/types'

const useBatch = () => {
  const companyData = useAppSelector(state => state.authReducer.company)
  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const company_id = companyData?.company_id ?? '' // eslint-disable-line
  const timeZoneCode = companyData?.time_zone_cod ?? ''
  const files = companyData?.files

  const templateList = getTemplateOptionsData(files)
  const { pathname } = useLocation()

  const permissions = getFinalPermissions(pathname, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const [uploadFileData, setUploadFileData] = useState<any>({})
  const [nativeData, setNativeData] = useState<any>([])
  const [fileBatchList, setFileBatchList] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [fileBatchListSize, setFileBatchListSize] = useState<number>(0)
  const [fileBatchListIsLoading, setFileBatchListIsLoading] = useState<boolean>(false)
  const [selectedTemplateOpt, setSelectedTemplateOpt] = useState<string>('')
  const [templateExtensions, setTemplateExtensions] = useState<string[]>([])
  const [uploadFileStatus, setUploadFileStatus] = useState<UploadFileStatusType>('')
  const [uploadFileIdSelected, setUploadFileIdSelected] = useState<string>('')
  const [newPayoutId, setNewPayoutId] = useState<string>('')
  const [currentPayout, setCurrentPayout] = useState<PayoutDataType>(InitialPayoutData)
  const [filterData, setFilterData] = useState<any>(InitialFilterData)
  const [isActiveUploadButton, setIsActiveUploadButton] = useState<boolean>(false)
  const [isLoadingDispersion, setIsLoadingDispersion] = useState<boolean>(false)
  const [isDispersionStateLoading, setIsDispersionStateLoading] = useState<boolean>(true)

  const { modalData, setModalData } = useContext(ModalContext)

  const {
    loadingToastList, batchToastList, canViewToast,
    toastClassName,
    getToastListFileBatch
  } = useBatchToast({ fileBatchList })

  /*
     * Función que reinicia los filtros
    */
  const restartFilterData = () => { setFilterData(InitialFilterData) }

  /*
     * Función que limpia el componente de estado de dispersión
    */
  const cleanBatchDispersionState = () => {
    setUploadFileIdSelected('')
  }

  /*
     * Función que maneja la petición de servicio que obtiene la lista actual
     * de archivos batch cargados usando un servicio
    */
  const getListFileBatch = async (isRestarted: boolean, offset: number, filterData: any, fileSelected: ('empty' | 'first' | 'current') = 'empty') => {
    setFileBatchListIsLoading(true)
    if (validPermittedServices('listFileBatch', permissions)) {
      try {
        const validFilterData = {
          processStatus: filterData?.processStatus?.toUpperCase() ?? 'ALL',
          calendar: {
            iniDte: getFormattedGMTtoDMYDate(filterData?.calendar?.[0]) ?? '',
            finDte: getFormattedGMTtoDMYDate(filterData?.calendar?.[1]) ?? ''
          },
          nameId: filterData?.nameId ?? ''
        }

        const response = await listFileBatchService(company_id, 20, offset, 'batch.file.request_dtm', 'desc', validFilterData, reduxUser)
        const listData = response?.data?.['list-file-batch']

        let newNativeData = listData

        if (!isRestarted) {
          newNativeData = nativeData?.concat(listData)
        };

        setNativeData(newNativeData)
        setFileBatchListSize(newNativeData?.length)
        setFileBatchList(newNativeData)

        if (fileSelected === 'empty') {
          setUploadFileIdSelected('')
        };

        if (fileSelected === 'first') {
          const payout = listData?.[0]
          const firstPayoutId = payout?.payout_id

          setCurrentPayout(payout)
          setNewPayoutId(firstPayoutId)
          setUploadFileIdSelected(firstPayoutId)
        };

        if (fileSelected === 'current') {
          setUploadFileIdSelected(uploadFileIdSelected)
        };
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'listFileBatch' })
    }
    setTimeout(function () {
      setFileBatchListIsLoading(false)
    }, 1000)
  }

  /*
     * Función que maneja la petición de servicio de subida de archivos por el botón de carga
    */
  const createNewFileBatch = async (batchData: any) => {
    if (!validPermittedServices('invoiceBatch', permissions)) {
      setModalData({ isOpen: true, type: 'serviceError', service: 'invoiceBatch' })
      return
    }

    try {
      setTimeout(() => {
        getListFileBatch(true, 0, InitialFilterData, 'first').catch((error) => {
          console.error(error)
        })
      }, 2500)
      const response = await invoiceBatchService(batchData, reduxUser)

      const { status } = response

      if (status === 202) {
        restartFilterData()
        setOffset(0)

        await getListFileBatch(true, 0, InitialFilterData, 'first')

        setUploadFileStatus('success')
      };

      if (status === 204) {
        setUploadFileStatus('already')
      };
      if (canViewToast && toastClassName !== 'cant-view-toast') {
        getToastListFileBatch() // eslint-disable-line
      }
    } catch (error: any) {
      console.log(error)
      setUploadFileStatus('error')
    }
  }

  /*
     * Función que maneja el botón de subida de archivos
    */
  const handleChangeUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    if (!event.target.files || event.target.files.length === 0) return

    const file = event.target.files[0]

    if (!file) return

    setUploadFileData(file)
    const { name: fileName } = file

    if (!templateExtensions.some(substring => fileName.includes(substring))) {
      setUploadFileStatus('extensionError')
      return
    }

    const batchData = {
      file_bin: file,
      file_cod: selectedTemplateOpt,
      company_id,
      user_id: reduxUser?.user_id
    }

    await createNewFileBatch(batchData)
  }

  /*
     * Función que maneja el cambio de valor del select template cambiando el estado de
     * selectedTemplateOpt, activando el botón de subida de archivo y reiniciando el estado
     * del proceso de subida de archivo
    */
  const handleChangeSelectTemplate = (option: string) => {
    setSelectedTemplateOpt(option)
    setIsActiveUploadButton(true)
    setUploadFileStatus('')
  }

  /*
     * Función que cierra el estado de proceso de subida de archivo al clickear en el ícono
     * del mensaje
    */
  const handleClickCloseUploadFileStatus = () => { setUploadFileStatus('') }

  /*
     * Función que maneja el click de la lista de archivos subidos y setea un nuevo id al estado
     * uploadFileIdSelected
    */
  const handleClickUploadFile = async (payout: PayoutDataType) => {
    event?.preventDefault()

    const { payout_id: uploadFileId } = payout

    setCurrentPayout(payout)
    setIsLoadingDispersion(uploadFileId !== '')
    setUploadFileIdSelected(uploadFileId)
  }

  /*
     * Función que maneja el scroll vertical de la sección de lista de archivos cargados
     * y acciona cuando está en el fondo de esta y además cuando el tamaño de la lista de
     * archivos sigue siendo mayor
    */
  const handleScrollUploadFiles = (event: any) => {
    const target = event.target
    const isBottom = (event.target.scrollHeight - target.scrollTop) <= target.clientHeight

    if (isBottom && fileBatchListSize >= offset + 20) {
      const newOffset = offset + 20

      setOffset(newOffset)
      getListFileBatch(false, newOffset, filterData, 'current') // eslint-disable-line
    };
  }

  /*
     * Función que maneja la habilitación y deshabilitación del botón de subida de archivos y de la lista de archivos,
     * cuando el componente de estado de dispersión está cargando
    */
  const handleDispersionLoadingDone = (isLoadingDispersionDone: boolean) => {
    setIsLoadingDispersion(!isLoadingDispersionDone)
  }

  /*
     * Función que actualiza el estado de dispersión del archivo seleccionado
    */
  const reloadBatchDispersionState = async () => {
    restartFilterData()
    cleanBatchDispersionState()

    await getListFileBatch(true, offset, InitialFilterData, 'current')
  }

  const handleFilterSubmit = async (filterData: any) => {
    cleanBatchDispersionState()

    setOffset(0)
    setFilterData(filterData)

    const table = document?.getElementsByClassName('batchupload__uploadfiles')?.[0]

    table.scrollTop = 0

    await getListFileBatch(true, 0, filterData, 'empty')
  }

  /*
     * Efecto que setea las extensiones de archivo permitidas
    */
  useEffect(() => {
    const strExtensions = files?.find((file: any) => file.file_cod === selectedTemplateOpt)?.file_ext
    const arrExtensions = removeSpaces(strExtensions).split(',')

    setTemplateExtensions(arrExtensions)
    }, [selectedTemplateOpt]); // eslint-disable-line

  /*
     * Efecto que deshabilita el botón de subida de archivos cuando no hay selección alguna
     * o la lista de archivos subidos está actualizandose
    */
  useEffect(() => {
    const isActiveUploadButton = (selectedTemplateOpt !== '' || templateList?.length < 2) && !fileBatchListIsLoading && !isLoadingDispersion

    setIsActiveUploadButton(isActiveUploadButton)
    }, [selectedTemplateOpt, fileBatchListIsLoading, isLoadingDispersion]); // eslint-disable-line

  /*
     * Efecto que detecta si el modal apareció para automáticamente deseleccionar el archivo cargado de la lista
    */
  useEffect(() => {
    if (
      modalData?.isOpen && (
        modalData?.service === 'listFileBatch' ||
                modalData?.service === 'invoiceBatch' ||
                modalData?.service === 'processStatus'
      )
    ) {
      cleanBatchDispersionState()
      setIsLoadingDispersion(false)
    };
    }, [modalData?.isOpen]); // eslint-disable-line

  useEffect(() => {
    setFileBatchListIsLoading(isDispersionStateLoading)
  }, [isDispersionStateLoading])

  /*
     * Efecto que maneja acciones iniciales
    */
  useEffect(() => {
    setOffset(0)
    getListFileBatch(true, 0, filterData, 'empty') // eslint-disable-line

    if (templateList?.length === 1) {
      setSelectedTemplateOpt(files?.[0]?.file_cod)
    };
    }, []); // eslint-disable-line

  return {
    // States
    timeZoneCode,
    uploadFileData,
    fileBatchList,
    uploadFileStatus,
    newPayoutId,
    currentPayout,
    isActiveUploadButton,
    templateExtensions,
    templateList,
    uploadFileIdSelected,
    fileBatchListIsLoading,
    filterData,
    loadingToastList,
    batchToastList,
    toastClassName,
    canViewToast,

    // Functions States
    setCurrentPayout,
    setIsDispersionStateLoading,

    // Functions
    handleChangeUploadFile,
    handleChangeSelectTemplate,
    handleClickCloseUploadFileStatus,
    handleClickUploadFile,
    handleScrollUploadFiles,
    handleDispersionLoadingDone,
    reloadBatchDispersionState,
    handleFilterSubmit
  }
}

export default useBatch
