import Card from '../../../../components/Card/Card'
import Filter from '../../../../components/Filter/Filter'
import Icon from '../../../../components/Icon/Icon'
import InputField from '../../../../components/InputField/InputField'
import Menu from '../../../../components/Menu/Menu'
import Table from '../../../../components/Table/Table'

interface IInvoicesViewProps {
    data: any
    timeZoneCode: string
    filterData: any
    isLoadingInvoicesTable: boolean
    openedMenu: boolean
    downloadInvoicesCSVLoading: boolean
    handleOpenMenu: (opened: boolean) => void
    handleInvoicesExportButton: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleScrollInvoicesTableData: React.UIEventHandler<HTMLTableSectionElement> | undefined
    handleInvoicesFinderValue: React.ChangeEventHandler<HTMLInputElement>
    handleFilterSubmit: any
    handleInvoicesTableDataClick: any
    handleDownloadInvoicesCSV: (filterData: any) => () => void
};

const InvoicesView = ({
  data,
  timeZoneCode,
  filterData,
  isLoadingInvoicesTable,
  openedMenu,
  downloadInvoicesCSVLoading,
  handleOpenMenu,
  handleInvoicesExportButton,
  handleScrollInvoicesTableData,
  handleInvoicesFinderValue,
  handleFilterSubmit,
  handleInvoicesTableDataClick,
  handleDownloadInvoicesCSV
}: IInvoicesViewProps) => {
  return (
    <div className="view invoicesView">
      <div className="view__header">
        <h1>Pagos</h1>
        <h2>Home - Dispersión - Pagos</h2>
      </div>
      <div className="view__body">
        <Card
          headerChildren={
            <>
              <InputField
                name="invoicesFinder"
                placeholder="Id OB o Id movimiento"
                icon={<Icon type="search" />}
                hasErrorMessage={false}
                hasDebounce
                onChange={handleInvoicesFinderValue}
              />
              <div className="invoicesView__body__headerChildren__right">
                <Filter
                  filterData={filterData}
                  dropdownType="invoice"
                  type="secondary"
                  size="large"
                  canOnlySelectSameMonth={true}
                  handleFilterSubmit={handleFilterSubmit}
                />
                <Menu
                  opened={openedMenu}
                  onChange={handleOpenMenu}
                  items={[
                    {
                      id: 'download-csv',
                      label: 'Descargar CSV',
                      disabled: filterData.calendar.length === 0 || downloadInvoicesCSVLoading,
                      onClick: handleDownloadInvoicesCSV(filterData)
                    }
                  ]}
                />
              </div>
            </>
          }
          timeZoneCode={timeZoneCode}
          // eslint-disable-next-line
          children={
            <Table
              data={data}
              isLoadingTableData={isLoadingInvoicesTable}
              handleScrollTableData={handleScrollInvoicesTableData}
              handleTableDataClick={handleInvoicesTableDataClick}
            />
          }
        />
      </div>
    </div>
  )
}

export default InvoicesView
