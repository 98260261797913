import moment from 'moment'

interface IDownloadDataAsFileArgs {
  data?: string
  date?: string
  format: string
  name: string
  utcOffHours: string
}

export const downloadDataAsFile = (args: IDownloadDataAsFileArgs) => {
  const {
    data = '',
    date = (new Date()).toISOString(),
    format,
    name,
    utcOffHours
  } = args
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  const momentDate = moment(date).utcOffset(utcOffHours).format('DDMMYYYYHHmmss')

  const fileName = [name, momentDate].join('-')

  link.href = url
  link.setAttribute('download', `${fileName}.${format}`)
  document.body.appendChild(link)
  link.click()
}
