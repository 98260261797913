import './UploadButton.css'

interface IUploadButtonProps {
    text: string
    isActive?: boolean
    onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
};

const UploadButton = ({ text, isActive = true, onChange }: IUploadButtonProps) => {
  const classNameDisabled = isActive ? '' : 'uploadbutton--disabled'
  const className = `uploadbutton ${classNameDisabled} actioncomponent`

  return (
    <div className={className}>
      <input
        className="uploadbutton__input"
        type="file"
        disabled={!isActive}
        onClick={(event: any) => { event.target.value = null }}
        onChange={onChange}
      />
      <p className="uploadbutton__text">{text}</p>
    </div>
  )
}

export default UploadButton
