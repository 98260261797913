import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks'

import useAuthVerification from '../../../hooks/useAuthVerification'

const useHome = () => {
  const userRedux = useAppSelector(state => state.authReducer.user)

  const [sessionUserIndexRoute, setSessionUserIndexRoute] = useState<string>('/')
  const [modalData, setModalData] = useState<any>({ isOpen: false, type: 'conexionError', service: '' })
  const [openHamburgerMenu, setOpenHamburguerMenu] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isMainLoading, setIsMainLoading] = useState<boolean>(true)

  const navigate = useNavigate()
  const location = useLocation()

  useAuthVerification(userRedux?.user_id ?? '')

  /*
     * Función que maneja el estado booleano que determina si el menú hamburguesa está abierto o cerrado
    */
  const handleClickHamburguerMenu = () => { setOpenHamburguerMenu(!openHamburgerMenu) }

  /*
     * Función que detecta el click de algún item del menu aside e inmediatamente manda a cargar el contenido principal
    */
  const handleSidebarMenuGroupItemClick = (route: string) => {
    if (route !== location?.pathname) {
      setIsMainLoading(true)
    };
  }

  /*
     * Efecto que se lanza en el primer renderizado y que busca obtener la data del usuario logueado además de
     * redirigir siempre a la ruta index del mismo
    */
  useEffect(() => {
    if (!userRedux) return

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key?.includes('CognitoIdentity')) {
        localStorage.removeItem(key)
      }
    }

    try {
      const sessionUserRoute = userRedux?.route_index_str
      const currentRoute = location?.pathname

      setSessionUserIndexRoute(sessionUserRoute)

      if (currentRoute !== sessionUserRoute) {
        if (currentRoute === '/') {
          navigate(sessionUserRoute)
        } else {
          navigate(currentRoute)
        };
      } else {
        navigate(sessionUserRoute)
      };
    } catch (error: any) {
      console.error(error?.message)

      setTimeout(() => {
        setHasError(true)
      }, 5000)
    };
    }, []); // eslint-disable-line

  return {
    // States
    hasError,
    modalData,
    isMainLoading,
    sessionUserIndexRoute,
    openHamburgerMenu,

    // Functions States
    setModalData,

    // Functions
    handleClickHamburguerMenu,
    handleSidebarMenuGroupItemClick
  }
}

export default useHome
