import './Link.css'

interface ILinkProps {
    text?: string
    url?: string
    children?: any
};

const Link = ({
  text = '',
  url = '/',
  children = <></>
}: ILinkProps) => {
  return (
    <a
      href={url ?? '/'}
      className={`link ${url ? '' : 'link--disabled'}`}
    >
      {
        text === ''
          ? (
            <>{children}</>
          )
          : (
            <span className="link__text">{text}</span>
          )
      }
    </a>
  )
}

export default Link
