import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js'

const signInService = (username: string, password: string): any => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? ''
  }

  const userPool = new CognitoUserPool(poolData)

  const authenticationData = {
    Username: username,
    Password: password
  }

  const authenticationDetails = new AuthenticationDetails(authenticationData)

  const userData = {
    Username: username,
    Pool: userPool
  }

  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        resolve(session)
      },
      onFailure: (err) => {
        reject(err)
      }
    })
  })
}

export default signInService
