import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '../store/hooks'
import signOutService from '../services/cognitoServices/signOutService'

const useAuthVerification = (userId: string) => {
  const navigate = useNavigate()

  const isAuth = useAppSelector(state => state.authReducer.isAuthenticated)

  useEffect(() => {
    const checkAuth = async () => {
      if (!isAuth) {
        localStorage.clear()
        signOutService() // eslint-disable-line
        window.location.href = process.env.REACT_APP_COGNITO_LOGOUT_URL ?? ''
      }
    }

    checkAuth() // eslint-disable-line

    // Opcional: establece un intervalo para verificar los tokens regularmente
    const interval = setInterval(checkAuth, 5 * 60 * 1000) // eslint-disable-line

    return () => { clearInterval(interval) }
  }, [navigate, userId])

  if (!userId) return // eslint-disable-line
}

export default useAuthVerification
