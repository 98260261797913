import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import changePasswordService from '../../../services/cognitoServices/changePasswordService'
import { isPasswordValid } from '../../../utils/validations/inputFieldValidations'

import { type NewPasswordDataType } from '../../../../typings/types'
import { InitialNewPasswordData } from '../../../constants/initialData'

const useNewPassword = () => {
  const [newPasswordData, setNewPasswordData] = useState<NewPasswordDataType>(InitialNewPasswordData)
  const [inputTouched, setInputTouched] = useState<boolean>(false)
  const [hasValidPassword, setHasValidPassword] = useState<boolean>(false)
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState<boolean>(false)
  const [hasConfirmNewPasswordError, setHasConfirmNewPasswordError] = useState<boolean>(false)
  const [submitNewPasswordErrorMessage, setSubmitNewPasswordErrorMessage] = useState<string>('')

  const location = useLocation()
  const username = location?.state?.username
  const otpValue = location?.state?.otpValue
  const navigate = useNavigate()

  /*
     * Función que maneja el click en el botón de 'Regresar' de la página de 'Nueva Contraseña'
    */
  const handleNewPasswordReturnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.preventDefault()

    navigate('/two-steps', { state: { username } })
  }

  /*
     * Función que maneja la data ingresada en los input fields de la página de 'Nueva Contraseña'
     * y la guarda en un objeto de estado al momento
    */
  const handleNewPasswordInputValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target

    setNewPasswordData({ ...newPasswordData, [name]: value })
    setSubmitNewPasswordErrorMessage('')
  }

  /*
     * Función que marca el campo como "tocado" cuando el usuario interactúa con el input de
     * 'Confirmación de contraseña'
    */
  const handleLoginInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event?.preventDefault()

    const { value } = event?.target

    if (value?.length !== 0) {
      setInputTouched(true)
    };
  }

  /*
     * Función que maneja las acciones que se disparan al hacer click al botón submit de la página
     * de Nueva Contraseña
    */
  const handleSubmitNewPassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault()

    const { newPassword } = newPasswordData

    try {
      await changePasswordService(username, otpValue, newPassword)

      navigate('/')
    } catch (error: any) {
      setSubmitNewPasswordErrorMessage(error?.message || JSON.stringify(error))
    };
  }

  useEffect(() => {
    const { newPassword, confirmNewPassword } = newPasswordData

    const confirmPasswordValidation = isPasswordValid(confirmNewPassword)

    setHasConfirmNewPasswordError(!confirmPasswordValidation && inputTouched)

    setIsActiveSubmitButton((newPassword === confirmNewPassword) && hasValidPassword)
  }, [newPasswordData, hasValidPassword, inputTouched])

  return {
    // States
    username,
    otpValue,
    isActiveSubmitButton,
    hasConfirmNewPasswordError,
    submitNewPasswordErrorMessage,

    // Function States
    setHasValidPassword,

    // Functions
    handleNewPasswordReturnClick,
    handleNewPasswordInputValues,
    handleLoginInputBlur,
    handleSubmitNewPassword
  }
}

export default useNewPassword
