import AgencyImg from '../../assets/images/agency.png'
import OBLogoImg from '../../assets/images/ob-logo-tipo.png'
import OBLogoMinImg from '../../assets/images/ob-logo-tipo-min.png'
import OBLogoLoadingImg from '../../assets/images/ob-loading.png'
import BCPLogoImg from '../../assets/images/bcp-logo.png'
import BBVALogoImg from '../../assets/images/bbva-logo.png'
import IBKLogoImg from '../../assets/images/ibk-logo.png'
import SCOLogoImg from '../../assets/images/sco-logo.png'
import ProfileDefaultImg from '../../assets/images/profile-default.jpg'
import Error404Img from '../../assets/images/404-error.png'
import BankLogoImg from '../../assets/icons/bank-outlined.svg'

import './Image.css'

interface IImageProps {
    type: string
    width?: number
    height?: number
    alt: string
    isCircular?: boolean
};

const ImageSrcByType: any = {
  agency: AgencyImg,
  oblogo: OBLogoImg,
  oblogomin: OBLogoMinImg,
  oblogoloading: OBLogoLoadingImg,
  bcplogo: BCPLogoImg,
  bbvalogo: BBVALogoImg,
  ibklogo: IBKLogoImg,
  scologo: SCOLogoImg,
  banklogo: BankLogoImg,
  profiledefault: ProfileDefaultImg,
  error404: Error404Img
}

const Image = ({
  type,
  width = 50,
  height = 50,
  alt,
  isCircular = false
}: IImageProps) => {
  if (!type?.length || !alt?.length) return <></>

  const isCircularClassName = isCircular ? 'image--isCircular' : ''
  const className = `image ${isCircularClassName}`

  return (
    <img
      className={className}
      src={ImageSrcByType[type]}
      width={width}
      height={height}
      alt={alt}
    />
  )
}

export default Image
