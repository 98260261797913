import SidebarHeader from './SidebarHeader/SidebarHeader'
import SidebarMenu from './SidebarMenu/SidebarMenu'

import Overlay from '../../../components/Overlay/Overlay'

interface ISidebarViewProps {
    sessionUserIndexRoute: any
    sidebarRef: React.LegacyRef<HTMLElement> | undefined
    isSmall: boolean
    isOpen: boolean
    isToggle: boolean
    handleOnMouseEnterSidebar: React.MouseEventHandler<HTMLElement> | undefined
    handleOnMouseLeaveSidebar: React.MouseEventHandler<HTMLElement> | undefined
    handleSidebarToggle: React.MouseEventHandler<HTMLDivElement> | undefined
    handleSidebarMenuGroupItemClick: any
};

const SidebarView = ({
  sessionUserIndexRoute,
  sidebarRef,
  isSmall,
  isOpen,
  isToggle,
  handleOnMouseEnterSidebar,
  handleOnMouseLeaveSidebar,
  handleSidebarToggle,
  handleSidebarMenuGroupItemClick
}: ISidebarViewProps) => {
  return (
    <>
      <aside
        ref={sidebarRef}
        className={`sidebar ${isSmall ? 'sidebar--small' : ''} ${isOpen ? 'sidebar--open' : ''} ${isToggle ? 'sidebar--toggle' : ''}`}
        onMouseEnter={handleOnMouseEnterSidebar}
        onMouseLeave={handleOnMouseLeaveSidebar}
      >
        <div className="sidebar_container">
          <SidebarHeader
            isSmall={isSmall}
            isToggle={isToggle}
            handleSidebarToggle={handleSidebarToggle}
          />
          <SidebarMenu
            sessionUserIndexRoute={sessionUserIndexRoute}
            handleSidebarMenuGroupItemClick={handleSidebarMenuGroupItemClick}
          />
        </div>
      </aside>
      {
        isOpen && <Overlay />
      }
    </>
  )
}

export default SidebarView
