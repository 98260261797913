import { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'

import ModalContext from '../../../../contexts/ModalContext'

import getFormattedGMTtoDMYDate from '../../../../utils/date/getFormattedGMTtoDMYDate'
import getInvoicesTableData from '../../../../utils/data/getInvoicesTableData'
import invoicesDataService from '../../../../services/payoutServices/invoicesDataService'

import { InitialInvoiceFilterData } from '../../../../constants/initialData'
import getTimeZoneHours from '../../../../utils/date/getTimeZoneHours'
import getUtcOffHours from '../../../../utils/date/getUtcOffHours'
import { downloadDataAsFile } from '../../../../utils/data/downloadDataAsFile'

const useInvoices = () => {
  const reduxUser = useAppSelector(state => state.authReducer.user)
  const companyData = useAppSelector((state) => state.authReducer.company)

  const [nativeData, setNativeData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [finderValue, setFinderValue] = useState<string>('')
  const [filterData, setFilterData] = useState<any>(InitialInvoiceFilterData)
  const [isLoadingInvoicesTable, setIsLoadingInvoicesTable] = useState<boolean>(true)
  const [openedMenu, setOpenedMenu] = useState(false)
  const [downloadInvoicesCSVLoading, setDownloadInvoicesCSVLoading] = useState(false)

  const { setModalData } = useContext(ModalContext)

  const company_id = companyData?.company_id ?? '' // eslint-disable-line
  const timeZoneCode = companyData?.time_zone_cod ?? ''
  const timeZoneHours = getTimeZoneHours(timeZoneCode)
  const utcOffHours = getUtcOffHours(timeZoneHours)

  /*
     * Función que maneja la petición de servicio que obtiene la data total que se muestra en la vista de Pagos
    */
  const getInvoicesData = async (isRestarted: boolean, offset: number, filterData: any, finderValue: string) => {
    try {
      setIsLoadingInvoicesTable(true)

      const validFilterData = {
        processStatus: filterData?.processStatus?.toUpperCase() === 'ALL' ? '' : filterData?.processStatus?.toUpperCase() ?? '',
        calendar: {
          iniDte: getFormattedGMTtoDMYDate(filterData?.calendar?.[0]) ?? '',
          finDte: getFormattedGMTtoDMYDate(filterData?.calendar?.[1]) ?? ''
        },
        nameId: filterData?.nameId ?? ''
      }

      const response = await invoicesDataService({
        company_id,
        str_search: finderValue,
        status_cod: validFilterData?.processStatus,
        ini_dte: validFilterData?.calendar?.iniDte,
        fin_dte: validFilterData?.calendar?.finDte,
        limit_int: 20,
        offset_int: offset,
        orderby: 'created_dtm',
        orderdir_str: 'desc',
        user: reduxUser
      })
      const { data: responseData } = response
      const { data: currentNativeData } = responseData
      let newNativeData = currentNativeData

      if (!isRestarted) {
        newNativeData = nativeData?.concat(currentNativeData)
      };

      const invoicesTableData = getInvoicesTableData(newNativeData, timeZoneCode)

      setNativeData(newNativeData)
      setData(invoicesTableData)

      setTimeout(function () {
        setIsLoadingInvoicesTable(false)
      }, 1000)
    } catch (error: any) {
      setModalData({ isOpen: true, type: 'conexionError', service: '' })
    };
  }

  const handleDownloadInvoicesCSV = (filterData: any) => async () => {
    try {
      setDownloadInvoicesCSVLoading(true)
      const validFilterData = {
        processStatus: filterData?.processStatus?.toUpperCase() === 'ALL' ? '' : filterData?.processStatus?.toUpperCase() ?? '',
        calendar: {
          iniDte: getFormattedGMTtoDMYDate(filterData.calendar[0]) ?? '',
          finDte: getFormattedGMTtoDMYDate(filterData.calendar[1]) ?? ''
        },
        nameId: filterData?.nameId ?? ''
      }

      const response = await invoicesDataService({
        company_id,
        str_search: finderValue,
        status_cod: validFilterData?.processStatus,
        ini_dte: validFilterData.calendar.iniDte,
        fin_dte: validFilterData.calendar.finDte,
        orderby: 'created_dtm',
        orderdir_str: 'desc',
        user: reduxUser,
        data_format_cod: 'csv'
      })

      downloadDataAsFile({
        data: response.data,
        date: response.headers.date,
        format: 'csv',
        name: 'invoices',
        utcOffHours
      })
    } catch (error: unknown) {
      setModalData({ isOpen: true, type: 'conexionError', service: '' })
    } finally {
      setDownloadInvoicesCSVLoading(false)
    }
  }

  const handleInvoicesExportButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
  }

  const handleScrollInvoicesTableData = (event: any) => {
    const isBottom = (event?.target?.scrollHeight - event?.target?.scrollTop - 1) <= event?.target?.clientHeight

    if (isBottom && data?.body?.length >= offset + 20) {
      const newOffset = offset + 20

      setOffset(newOffset)
      setIsLoadingInvoicesTable(true)

      getInvoicesData(false, newOffset, filterData, finderValue) // eslint-disable-line
    };
  }

  const handleInvoicesFinderValue = (event: any) => {
    const invoicesFinderValue = event?.target?.value

    setFinderValue(invoicesFinderValue)
  }

  const handleFilterSubmit = async (filterData: any) => {
    setOffset(0)
    setFilterData(filterData)

    const table = document?.getElementsByClassName('wrapper_table')?.[0]
    table.scrollTop = 0

    await getInvoicesData(true, 0, filterData, finderValue)
  }

  const handleInvoicesTableDataClick = (invoiceData: any) => {
    const invoiceId = invoiceData?.invoiceId

    window.location.href = `/payout/invoice-detail/${invoiceId}` // eslint-disable-line
  }

  const handleOpenMenu = (opened: boolean) => { setOpenedMenu(opened) }

  useEffect(() => {
    setOffset(0)

    const table = document?.getElementsByClassName('wrapper_table')?.[0]
    table.scrollTop = 0

    getInvoicesData(true, 0, filterData, finderValue) // eslint-disable-line
    }, [finderValue]); // eslint-disable-line

  return {
    // States
    isLoadingInvoicesTable,
    data,
    filterData,
    timeZoneCode,
    openedMenu,
    downloadInvoicesCSVLoading,

    // Functions States
    handleOpenMenu,

    // Functions
    handleInvoicesExportButton,
    handleScrollInvoicesTableData,
    handleInvoicesFinderValue,
    handleFilterSubmit,
    handleInvoicesTableDataClick,
    handleDownloadInvoicesCSV
  }
}

export default useInvoices
