/* eslint-disable @typescript-eslint/restrict-template-expressions */
import './BatchUploadFile.css'
import processStatusData from '../../../../../../constants/processStatusData'
import Icon from '../../../../../../components/Icon/Icon'
import Badge from '../../../../../../components/Badge/Badge'
import getFormattedFileUploadDate from '../../../../../../utils/date/getFormattedFileUploadDate'
import getFormattedMoney from '../../../../../../utils/money/getFormattedMoney'
import getCapitalizedString from '../../../../../../utils/commons/getCapitalizedString'
import { type PayoutDataType } from '../../../../../../../typings/types'
import Timeline from '../../../../../../components/Timeline/Timeline'
import getCurrencySymbolFromCode from '../../../../../../utils/money/getCurrencySymbolFromCode'

interface IBatchUploadFileProps {
    timeZoneCode: string
    fileBatch: PayoutDataType
    isSelected?: boolean
    onClick: (payout: PayoutDataType) => void
};

const BatchUploadFile = ({ timeZoneCode, fileBatch, isSelected = false, onClick }: IBatchUploadFileProps) => {
  if (!fileBatch) return <></>

  const { events, payout_id, status_cod, name_str } = fileBatch
  const processStatus = processStatusData?.find((process: any) => process?.name?.toUpperCase() === status_cod)
  const processStatusTitle = getCapitalizedString(processStatus?.title ?? status_cod)
  const processStatusColor = processStatus?.color

  const classNameColor = processStatusColor ? `batchuploadfile--${processStatusColor}` : '' // eslint-disable-line
  const classNameSelected = isSelected ? 'batchuploadfile--selected' : ''
  const className = `batchuploadfile ${classNameColor} ${classNameSelected} actioncomponent`
  const timelineEventNames = ['processed', 'paid'] as const

  const handlePayoutClick = () => {
    onClick(fileBatch)
  }

  /*
     * Renderizado del componente del item de archivo subido en la lista de archivos subidos anteriormente
    */
  return (
    <div id={payout_id} className={className} onClick={handlePayoutClick}>
      <div className="batchuploadfile_container">
        <span className="batchuploadfile__icon">
          {
            isSelected
              ? (
                <Icon type="check" />
              )
              : (
                <i className="fonticon"></i>
              )
          }
        </span>
        <div className="batchuploadfile__data">
          <h3 className="batchuploadfile__data__name">
            {/* Cambiar el nombre a API Dispersión si origin_cod = 'API' */}
            {name_str}
          </h3>
          <div className="batchuploadfile__data__status_date">
            <p className="batchuploadfile__data__date">
              {getFormattedFileUploadDate(fileBatch, timeZoneCode)}
            </p>
          </div>
        </div>
        <div className="batchuploadfile__status">
          <Badge text={processStatusTitle} color={processStatusColor} />
        </div>
      </div>
      <div className='timeline-wrapper'>
        <Timeline
          data={events
            ? timelineEventNames.map(eventName => {
              const event = events[eventName]
              const color = processStatusColor ?? 'yellow'
              const currencySymbol = getCurrencySymbolFromCode(event.currency_cod)
              const total = getFormattedMoney(event.total_dcm)
              const totalRows = event.total_rows_int.toString()
              const status = event.status_name_str

              return ({
                leftSection: {
                  color,
                  title: `${currencySymbol} ${total}`,
                  subtitle: `${totalRows} ${status}`
                }
              })
            })
            : []}
        />
      </div>
    </div>
  )
}

export default BatchUploadFile
