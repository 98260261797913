import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

const changePasswordService = async (username: string, verificationCode: string, newPassword: string) => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? ''
  }

  const userPool = new CognitoUserPool(poolData)

  const userData = {
    Username: username,
    Pool: userPool
  }

  const cognitoUser = new CognitoUser(userData)

  return await new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (status) => {
        resolve(status)
      },
      onFailure: (error) => {
        reject(error)
      }
    })
  })
}

export default changePasswordService
