import CustomersView from './components/CustomersView'

import useCustomers from './hooks/useCustomers'

import './Customers.css'

const Customers = () => {
  const {
    timeZoneCode,
    data,
    isLoadingCustomersTable,
    handleCustomersFinderValue,
    handleCustomersTableDataClick,
    handleScrollCustomersTableData
  } = useCustomers()

  return (
    <CustomersView
      timeZoneCode={timeZoneCode}
      data={data}
      isLoadingCustomersTable={isLoadingCustomersTable}
      handleScrollCustomersTableData={handleScrollCustomersTableData}
      handleCustomersFinderValue={handleCustomersFinderValue}
      handleCustomersTableDataClick={handleCustomersTableDataClick}
    />
  )
}

export default Customers
