const getDropdownFilterOptionsData = (data: any, dropdownType: 'payout' | 'invoice') => {
  let filteredData = data

  if (dropdownType === 'payout') {
    filteredData = data?.filter((process: any) => process?.name !== 'rejection')
  };

  const dropdownFilterOptions = filteredData?.map((process: any) => {
    return {
      id: process?.id,
      name: process?.name,
      title: process?.title
    }
  })

  return dropdownFilterOptions
}

export default getDropdownFilterOptionsData
