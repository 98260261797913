const getCapitalizedString = (str: string) => {
  const arr = str?.split('')

  const auxArr: string[] = []
  let auxStr: string = ''

  for (let i = 0; i < arr?.length; i++) {
    if (arr[i] === ' ') {
      auxArr?.push(auxStr?.charAt(0)?.toUpperCase() + auxStr?.slice(1)?.toLowerCase())

      auxStr = ''
    } else {
      auxStr += arr[i]

      if (i === arr?.length - 1) {
        auxArr?.push(auxStr?.charAt(0)?.toUpperCase() + auxStr?.slice(1)?.toLowerCase())
      };
    };
  };

  return auxArr?.join(' ')
}

export default getCapitalizedString
