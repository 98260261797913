import ResetPasswordView from './components/ResetPasswordView'

import useResetPassword from './hooks/useResetPassword'

const ResetPassword = () => {
  const {
    submitResetPasswordErrorMessage,
    hasResetPasswordEmailError,
    isActiveSubmitButton,
    handleResetPasswordInputValues,
    handleLoginInputBlur,
    handleClickSubmitButton,
    handleClickCancelButton
  } = useResetPassword()

  /*
     * Renderizado del componente visual de la página de Reset Password
    */
  return (
    <ResetPasswordView
      submitResetPasswordErrorMessage={submitResetPasswordErrorMessage}
      hasResetPasswordEmailError={hasResetPasswordEmailError}
      isActiveSubmitButton={isActiveSubmitButton}
      handleResetPasswordInputValues={handleResetPasswordInputValues}
      handleLoginInputBlur={handleLoginInputBlur}
      handleClickSubmitButton={handleClickSubmitButton} // eslint-disable-line
      handleClickCancelButton={handleClickCancelButton}
    />
  )
}

export default ResetPassword
