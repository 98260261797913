import { useState, useEffect } from 'react'
import CardToolbarView from './CardToolbarView'
import { type PayoutDataType } from '../../../../typings/types'

interface IToolbarProps {
    timeZoneCode: string
    payoutDataSelected: PayoutDataType
    isDispersing?: boolean
    isDisabled?: boolean
    onClick?: any
};

const Toolbar = ({
  payoutDataSelected,
  isDispersing = false,
  isDisabled = false,
  onClick = () => {}
}: IToolbarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFileProcessActive, setIsFileProcessActive] = useState<boolean>(true)
  const isFromApi = payoutDataSelected.origin_cod === 'API'

  /*
     * Función que maneja el click al abrir el Toolbar
    */
  const handleOpenToolbar = (isOpen: boolean) => { setIsOpen(isOpen) }

  /*
     * Función que maneja los eventos que suceden al elegir una opción del toolbar
    */
  const handleSelectToolbarOption = (event: any) => {
    event?.preventDefault()
    setIsOpen(false)
    onClick(event)
  }

  useEffect(() => {
    const isFileProcessActive = payoutDataSelected.status_cod === 'WAITING'

    setIsFileProcessActive(isFileProcessActive)
  }, [payoutDataSelected])

  if (!payoutDataSelected) return <></>

  return (
    <CardToolbarView
      isOpen={isOpen}
      isDisabled={isDisabled}
      isDispersing={isDispersing}
      isFromApi={isFromApi}
      isFileProcessActive={isFileProcessActive}
      handleOpenToolbar={handleOpenToolbar}
      handleSelectToolbarOption={handleSelectToolbarOption}
    />
  )
}

export default Toolbar
