const validRoutesData = [
  '/',
  '/payout/batch',
  '/payout/statement',
  '/payout/customers',
  '/payout/customer-detail/:id',
  '/payout/invoice-detail/:id',
  '/payout/invoices'
]

export default validRoutesData
