import { useState, useEffect } from 'react'

import InputFieldPassword from './InputFieldPassword/InputFieldPassword'

import './InputField.css'

interface InputFieldProps {
    type?: string
    style?: 'primary' | 'secondary'
    name: string
    placeholder?: string
    value?: string
    icon?: any
    hasBars?: boolean
    hasError?: boolean
    hasErrorMessage?: boolean
    hasDebounce?: boolean
    setHasValidPassword?: any
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
    onChange?: React.ChangeEventHandler<HTMLInputElement> | ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
};

const InputField = ({
  type = 'text',
  style = 'primary',
  name,
  placeholder = '',
  value = '',
  icon = <></>,
  hasBars,
  hasError,
  hasErrorMessage = true,
  hasDebounce = false,
  setHasValidPassword = () => {},
  onBlur = () => {},
  onChange = () => {}
}: InputFieldProps) => {
  const [inputFieldValue, setInputFieldValue] = useState<string>('')

  const wrapperInputClassName = `wrapper_inputfield ${hasError ? 'wrapper_inputfield--error' : ''}`
  const inputClassName = `inputfield inputfield--${style}`

  /*
     * Función que maneja el valor del componente input
    */
  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault()

    const { value } = event?.target

    setInputFieldValue(value)

    if (typeof onChange === 'function') {
      if (hasDebounce) {
        const delayInputTimeoutId = setTimeout(() => {
          onChange(event)
        }, 500)

        return () => { clearTimeout(delayInputTimeoutId) }
      } else {
        onChange(event)
      };
    };
  }

  /*
     * Efecto que setea el valor actual de componente InputField, viniendo del componente padre o no
    */
  useEffect(() => {
    setInputFieldValue(value)
  }, [value])

  /*
     * Renderizado del componente InputField
    */
  if (!name?.length) return <></>

  return (
    <div className={wrapperInputClassName}>
      {
        type === 'password'
          ? (
            <InputFieldPassword
              name={name}
              placeholder={placeholder}
              hasBars={hasBars}
              setHasValidPassword={setHasValidPassword}
              onBlur={onBlur}
              onChange={onChange}
            />
          )
          : (
            <>
              <div className={inputClassName}>
                <input
                  className="inputfield__input"
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  value={inputFieldValue}
                  onBlur={onBlur}
                  onChange={handleInputValue}
                  autoComplete="off"
                />
                <div className="inputfield__icon">
                  {icon}
                </div>
              </div>
              {
                hasErrorMessage && (
                  <span className="inputfield__error">The value is not a valid {type}</span>
                )
              }
            </>
          )
      }
    </div>
  )
}

export default InputField
