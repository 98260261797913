import TimelineItem from './TimelineItem/TimelineItem'

import { type TTimelineData } from '../../../typings/types'

import './Timeline.css'

interface ITimeLineProps {
    data: TTimelineData
};

const Timeline = ({ data }: ITimeLineProps) => {
  if (!data) return <></>

  return (
    <div className="timeline">
      {
        data.map((timelineItemData, idx) => (
          <div key={idx} className="wrapper_timelineitem">
            <TimelineItem
              data={timelineItemData}
            />
          </div>
        ))
      }
    </div>
  )
}

export default Timeline
