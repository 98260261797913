const getFormattedTimerDate = (seconds: number) => {
  const days = Math.floor(seconds / 86400)
  seconds %= 86400

  const hours = Math.floor(seconds / 3600)
  seconds %= 3600

  const minutes = Math.floor(seconds / 60)
  seconds %= 60

  return `${days !== 0 ? `${days} ${days === 1 ? 'día' : 'días'} ` : ''}${hours !== 0 ? `${hours}h ` : ''}${minutes}.${Math.floor(seconds)} min`
}

export default getFormattedTimerDate
