import InvoicesView from './components/InvoicesView'

import useInvoices from './hooks/useInvoices'

import './Invoices.css'

const Invoices = () => {
  const {
    data,
    timeZoneCode,
    filterData,
    isLoadingInvoicesTable,
    openedMenu,
    downloadInvoicesCSVLoading,
    handleOpenMenu,
    handleFilterSubmit,
    handleInvoicesExportButton,
    handleInvoicesFinderValue,
    handleInvoicesTableDataClick,
    handleScrollInvoicesTableData,
    handleDownloadInvoicesCSV
  } = useInvoices()

  /*
     * Renderizado de la vista Statement
    */
  return (
    <InvoicesView
      data={data}
      timeZoneCode={timeZoneCode}
      filterData={filterData}
      isLoadingInvoicesTable={isLoadingInvoicesTable}
      openedMenu={openedMenu}
      downloadInvoicesCSVLoading={downloadInvoicesCSVLoading}
      handleOpenMenu={handleOpenMenu}
      handleInvoicesExportButton={handleInvoicesExportButton}
      handleScrollInvoicesTableData={handleScrollInvoicesTableData}
      handleInvoicesFinderValue={handleInvoicesFinderValue}
      handleFilterSubmit={handleFilterSubmit}
      handleInvoicesTableDataClick={handleInvoicesTableDataClick}
      handleDownloadInvoicesCSV={handleDownloadInvoicesCSV}
    />
  )
}

export default Invoices
