import { useState } from 'react'
import FilterView from './FilterView'
import { InitialFilterData } from '../../constants/initialData'
import processStatusData from '../../constants/processStatusData'
import invoiceProcessStatusData from '../../constants/invoiceProcessStatusData'
import { useOutsideClick } from '../../hooks/useOutsideClick'

interface IFilterProps {
    filterData: any
    dropdownType?: 'payout' | 'invoice'
    type?: 'primary' | 'secondary' | 'tertiary' | 'red'
    size?: 'small' | 'regular' | 'large'
    canOnlySelectSameMonth?: boolean
    handleFilterSubmit?: any
};

const Filter = ({
  filterData,
  dropdownType = 'payout',
  type = 'tertiary',
  size = 'small',
  canOnlySelectSameMonth = false,
  handleFilterSubmit = () => {}
}: IFilterProps) => {
  let statusData = processStatusData

  if (dropdownType === 'invoice') {
    statusData = invoiceProcessStatusData
  };

  const [currentFilterData, setCurrentFilterData] = useState<any>(InitialFilterData)
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false)
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false)

  /*
     * Función que maneja la apertura y el cierre del filtro al clickear el botón
    */
  const handleOpenFilters = () => {
    setIsOpenFilters(!isOpenFilters)
  }

  /*
     * Función que cierra el Filter al dar un click fuera de el mismo
    */
  const filterRef = useOutsideClick(() => {
    if (!isOpenCalendar) {
      setIsOpenFilters(false)
    };
  })

  /*
     * Función que guarda la variable actual del filtro de proceso de estado
    */
  const handleProcessStatusFilterChange = (option: string) => {
    const processStatus = statusData?.find((statusData: any) => statusData?.title === option)?.name

    setCurrentFilterData({ ...currentFilterData, processStatus })
  }

  /*
     * Función que guarda la variable actual del filtro de las fechas seleccionadas
    */
  const handleCalendarFilterChange = (date: any) => {
    setCurrentFilterData({ ...currentFilterData, calendar: date })
  }

  /*
     * Función que guarda la variable actual del filtro de nombre o id del archivo subido
    */
  const handleNameIdFilterChange = (event: any) => {
    event?.preventDefault()

    const { value } = event?.target

    setCurrentFilterData({ ...currentFilterData, nameId: value })
  }

  /*
     * Función que limpia los filtros y llama al servicio de listado de archivos automáticamente
    */
  const handleCleanFiltersClick = (event: any) => {
    event?.preventDefault()

    setIsOpenFilters(false)
    setCurrentFilterData(InitialFilterData)

    handleFilterSubmit(InitialFilterData)
  }

  /*
     * Función que envía los filtros seleccionados guardados y acciona el servicio de listado
    */
  const handleSubmitFiltersClick = async (event: any) => {
    event?.preventDefault()

    setIsOpenFilters(false)

    handleFilterSubmit(currentFilterData)
  }

  /*
     * Renderizado del componente de Filtros
    */
  if (!filterData) return <></>

  return (
    <FilterView
      filterRef={filterRef}
      filterData={filterData}
      dropdownType={dropdownType}
      type={type}
      size={size}
      isOpenFilters={isOpenFilters}
      canOnlySelectSameMonth={canOnlySelectSameMonth}
      setIsOpenCalendar={setIsOpenCalendar}
      handleOpenFilters={handleOpenFilters}
      handleProcessStatusFilterChange={handleProcessStatusFilterChange}
      handleCalendarFilterChange={handleCalendarFilterChange}
      handleNameIdFilterChange={handleNameIdFilterChange}
      handleCleanFiltersClick={handleCleanFiltersClick}
      handleSubmitFiltersClick={handleSubmitFiltersClick} // eslint-disable-line
    />
  )
}

export default Filter
