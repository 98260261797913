const invoiceProcessStatusData: any = [
  {
    id: 1,
    name: 'pending',
    title: 'Pendiente'
  },
  {
    id: 2,
    name: 'processing',
    title: 'Procesando'
  },
  {
    id: 3,
    name: 'rejected',
    title: 'Rechazado'
  },
  {
    id: 4,
    name: 'paid',
    title: 'Pagado'
  },
  {
    id: 5,
    name: 'voided',
    title: 'Anulado'
  }
]

export default invoiceProcessStatusData
