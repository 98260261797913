
import Button from '../../Button/Button'
import Menu from '../../Menu/Menu'

interface IToolbarViewProps {
    isOpen: boolean
    isDisabled: boolean
    isDispersing: boolean
    isFromApi: boolean
    isFileProcessActive: boolean
    handleOpenToolbar: (isOpen: boolean) => void
    handleSelectToolbarOption: React.MouseEventHandler<HTMLElement> | undefined
};

const ToolbarView = ({
  isOpen,
  isDisabled,
  isDispersing,
  isFromApi,
  isFileProcessActive,
  handleOpenToolbar,
  handleSelectToolbarOption
}: IToolbarViewProps) => {
  return (
    <Menu
      opened={isOpen}
      disabled={isDisabled}
      onChange={handleOpenToolbar}
      items={[
        {
          id: 'export-bank-batch',
          label: 'Bancos batch',
          permissionId: 'export-bank-batch',
          disabled: !isDispersing,
          items: [
            {
              id: 'all',
              label: 'Todos',
              onClick: handleSelectToolbarOption
            },
            {
              id: 'bcp',
              label: 'BCP',
              onClick: handleSelectToolbarOption
            },
            {
              id: 'bbva',
              label: 'BBVA',
              onClick: handleSelectToolbarOption
            },
            {
              id: 'ibk',
              label: 'Interbank',
              onClick: handleSelectToolbarOption
            },
            {
              id: 'sco',
              label: 'Scotiabank',
              onClick: handleSelectToolbarOption
            },
            {
              id: 'otr',
              label: 'BBVA interbancario',
              onClick: handleSelectToolbarOption
            }
          ]
        },
        {
          id: 'all-reports',
          label: 'Reportes',
          items: [
            {
              id: 'reports',
              label: 'Estado de dispersión',
              onClick: handleSelectToolbarOption
            }
          ]
        },
        {
          id: 'process-actions-item',
          label: 'Proceso',
          permissionId: 'process-actions-item',
          items: [
            {
              id: 'process-approve',
              permissionId: 'bt-process-approve',
              disabled: !isFileProcessActive,
              render: (
                <Button
                  type="primary"
                  text="Aprobar"
                  size="small"
                  isDisabled={!isFileProcessActive}
                  onClick={handleSelectToolbarOption}
                />
              )
            },
            {
              id: 'process-void',
              permissionId: 'bt-process-void',
              disabled: !isFileProcessActive,
              render: (
                <Button
                  type="red"
                  text="Anular Proceso"
                  size="small"
                  isDisabled={!isFileProcessActive}
                  onClick={handleSelectToolbarOption}
                />
              )
            }
          ]
        },
        ...(
          !isFromApi
            ? [{
              id: 'upload-file',
              label: 'Archivo cargado',
              onClick: handleSelectToolbarOption
            }]
            : [])
      ]}
    />
  )
}

export default ToolbarView
