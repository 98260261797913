import { useCallback, useEffect, useRef } from 'react'
import { DataSet, Timeline } from 'vis-timeline/standalone'
import './VisTimeline.css'

interface VisTimelineItemData {
  id: string
  content: string
  start: Date
}

interface VisTimelineProps {
  data: VisTimelineItemData[]
}

const VisTimeline = ({ data }: VisTimelineProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const renderVisTimeline = useCallback(() => {
    if (!containerRef.current) return null

    const items = new DataSet(data)
    const timeline = new Timeline(containerRef.current, items, {
      showCurrentTime: true,
      editable: false
    })
    return timeline
  }, [data])

  useEffect(() => {
    const timeline = renderVisTimeline()

    return () => {
      if (timeline) {
        timeline.destroy()
      }
    }
  }, [renderVisTimeline])

  return (
    <div ref={containerRef} />
  )
}

export default VisTimeline
