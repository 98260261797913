import { Helmet } from 'react-helmet'

import Link from '../../../components/Link/Link'
import InputField from '../../../components/InputField/InputField'
import Button from '../../../components/Button/Button'
import Icon from '../../../components/Icon/Icon'

interface IResetPasswordViewProps {
    submitResetPasswordErrorMessage: string
    hasResetPasswordEmailError: boolean
    isActiveSubmitButton: boolean
    handleResetPasswordInputValues: React.ChangeEventHandler<HTMLInputElement> | ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
    handleLoginInputBlur: React.FocusEventHandler<HTMLInputElement> | undefined
    handleClickSubmitButton: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleClickCancelButton: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const ResetPasswordView = ({
  submitResetPasswordErrorMessage,
  hasResetPasswordEmailError,
  isActiveSubmitButton,
  handleResetPasswordInputValues,
  handleLoginInputBlur,
  handleClickSubmitButton,
  handleClickCancelButton
}: IResetPasswordViewProps) => {
  return (
    <div className="page">
      <Helmet>
        <title>OpenBanking - Forgot password</title>
      </Helmet>
      <div className="page_container">
        <main className="page__main">
          <div className="page__main_container">
            <div className="page__main__header">
              <div className="page__main__header_container">
                <Link url="/">
                  <div className="page__main__header_return">
                    <Icon type="back" />
                  </div>
                </Link>
                <div className="page__main__header__text">
                  <span>Already a member?</span>
                  <Link text="Sign In" />
                </div>
              </div>
            </div>
            <form className="page__main__form">
              <div className="page__main__form__header">
                <h2 className="page__main__form__title">Forgot Password ?</h2>
                <p className="page__main__form__description">Enter your email to reset your password.</p>
              </div>
              <div className="page__main__form__inputfields">
                <InputField
                  name="email"
                  type="email"
                  placeholder="Email"
                  hasError={hasResetPasswordEmailError}
                  onBlur={handleLoginInputBlur}
                  onChange={handleResetPasswordInputValues}
                />
              </div>
              <div className="page__main__form__buttons">
                <Button
                  text="Submit"
                  isFitContent={true}
                  isDisabled={!isActiveSubmitButton}
                  onClick={handleClickSubmitButton}
                />
                <Button
                  type="secondary"
                  text="Cancel"
                  isFitContent={true}
                  onClick={handleClickCancelButton}
                />
                <span className="page__main__form__error">{submitResetPasswordErrorMessage ?? ''}</span>
              </div>
            </form>
            <div className="page__main__footer"></div>
          </div>
        </main>
        <aside className="page__aside">
        </aside>
      </div>
    </div>
  )
}

export default ResetPasswordView
