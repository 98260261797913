import { useEffect, useState } from 'react'

interface Props {
  fileBatchList: any
  currentPayout: any
}

const useBatchUpload = ({ fileBatchList, currentPayout }: Props) => {
  const [payoutListData, setPayoutListData] = useState<any>(fileBatchList)

  useEffect(() => {
    if (!currentPayout?.status_cod?.length) return

    // const newPayoutListData = [currentPayout, ...fileBatchList?.filter((payout: any) => payout?.payout_id !== currentPayout?.payout_id)];
    const newPayoutListData: any = []

    for (let i = 0; i < payoutListData?.length; i++) {
      if (currentPayout?.payout_id === payoutListData?.[i]?.payout_id) {
        newPayoutListData?.push(currentPayout)
      } else {
        newPayoutListData?.push(payoutListData?.[i])
      };
    };

    setPayoutListData(newPayoutListData)
    }, [currentPayout]) // eslint-disable-line

  useEffect(() => {
    setPayoutListData(fileBatchList)
  }, [fileBatchList])

  return {
    // States
    payoutListData

    // Functions States

    // Functions
  }
}

export default useBatchUpload
