import { COMISSION, RESERVE } from '../../constants/statementTypeCodes'

/**
 * Get sign of money based on type code
 * @param {number} money
 * @param {string} typeCode
 * @returns {number}
 */
const getSignMoney = (money: any, typeCode: string) => {
  switch (typeCode) {
    case RESERVE:
    case COMISSION:
      return (-1) * money

    default:
      return money
  }
}

export default getSignMoney
