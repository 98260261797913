/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios'
import type { PayoutDataType } from '../../../typings/types'

const processStatusService = async (payout: PayoutDataType, user: any): Promise<any> => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_ENDPOINT_SL,
    headers: {
      Accept: '*/*'
    }
  })

  try {
    const response = await instance({
      url: `/ob-sl-kuraka/v1/payout/${payout.payout_id}/process-status`,
      method: payout.origin_cod === 'API' ? 'GET' : 'POST',
      auth: {
        username: user?.user_id,
        password: user?.secret_key
      }
    })

    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Es un error de Axios
      if (error.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado que no está en el rango 2xx
        console.error('Respuesta del servidor con un error:')
        console.error(error?.response?.data?.error?.message)
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió ninguna respuesta
        console.error('No se recibió respuesta del servidor. Posiblemente un problema de red.')
      } else {
        // Otros errores de Axios
        console.error('Error de Axios:', error.message)
      };
    } else {
      // No es un error de Axios, puede ser un error de red
      console.error('Error no manejado:', error)
    };

    return undefined // Devuelve undefined en caso de error
  };
}

export default processStatusService
