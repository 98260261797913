const banksData = [
  {
    name: 'Todos',
    typeLogo: 'banklogo',
    widthLogo: 30,
    heightLogo: 30,
    alt: 'All Banks Logo'
  }
  // {
  //     name: "BCP",
  //     typeLogo: "bcplogo",
  //     widthLogo: 64,
  //     heightLogo: 16,
  //     alt: "BCP Bank Logo"
  // },
  // {
  //     name: "BBVA",
  //     typeLogo: "bbvalogo",
  //     widthLogo: 58,
  //     heightLogo: 18,
  //     alt: "BBVA Bank Logo"
  // },
  // {
  //     name: "IBK",
  //     typeLogo: "ibklogo",
  //     widthLogo: 30,
  //     heightLogo: 30,
  //     alt: "Interbank Bank Logo"
  // },
  // {
  //     name: "SCOT",
  //     typeLogo: "scologo",
  //     widthLogo: 30,
  //     heightLogo: 30,
  //     alt: "Scotiabank Bank Logo"
  // },
  // {
  //     name: "OTROS",
  //     typeLogo: "banklogo",
  //     widthLogo: 30,
  //     heightLogo: 30,
  //     alt: "Others Banks Logo"
  // }
]

export default banksData
