import { useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5radar from '@amcharts/amcharts5/radar'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

interface IRadarChartProps {
    data: any
};

const RadarChart = ({ data }: IRadarChartProps) => {
  useEffect(() => {
    if (!data) return

    // Create root element
    const root = am5.Root.new('chartdiv')

    // Set themes
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        innerRadius: am5.percent(20),
        startAngle: -90,
        endAngle: 180
      })
    )

    chart?.get('colors')?.set('step', 2)

    // Add cursor
    const cursor = chart.set('cursor',
      am5radar.RadarCursor.new(root, {
        behavior: 'zoomX'
      })
    )

    cursor.lineY.set('visible', false)

    // Create axes and their renderers
    const xRenderer = am5radar.AxisRendererCircular.new(root, {
      // minGridDistance: 50
    })

    xRenderer.labels.template.setAll({
      radius: 10
    })

    xRenderer.grid.template.setAll({
      forceHidden: true
    })

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'",
        tooltip: am5.Tooltip.new(root, {})
      })
    )

    xAxis.get('renderer').labels.template.setAll({
      fill: am5.color('#B5B5C3'),
      fontWeight: '500',
      fontSize: 16
    })

    const yRenderer = am5radar.AxisRendererRadial.new(root, { minGridDistance: 20 })

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: '500',
      fontSize: 18,
      fill: am5.color('#FF6347'),
      templateField: 'columnSettings'
    })

    yRenderer.grid.template.setAll({
      forceHidden: true
    })

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: yRenderer
      })
    )

    // Set data fields
    yAxis.data.setAll(data)

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    const series1 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis,
        yAxis,
        clustered: false,
        valueXField: 'full',
        categoryYField: 'category',
        fill: root.interfaceColors.get('alternativeBackground')
      })
    )

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20
    })

    series1.data.setAll(data)

    const series2 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis,
        yAxis,
        clustered: false,
        valueXField: 'value',
        categoryYField: 'category'
      })
    )

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: '{category}: {valueX}%',
      cornerRadius: 20,
      templateField: 'columnSettings'
    })

    series2.data.setAll(data)

    // Animate chart and series in
    series1.appear(1000) // eslint-disable-line
    series2.appear(1000) // eslint-disable-line
    chart.appear(1000, 100) // eslint-disable-line

    return () => {
      root.dispose()
    }
  }, [data])

  return (
    <div id="chartdiv" style={{ width: '315px', height: '300px' }}></div>
  )
}

export default RadarChart
