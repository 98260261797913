import { Box, Grid, Typography } from '@mui/material'
import Icon from '../../../../components/Icon/Icon'
import getFormattedFileUploadDate from '../../../../utils/date/getFormattedFileUploadDate'
import { type PayoutDataType } from '../../../../../typings/types'

interface Props {
  currentBatch: PayoutDataType
  timeZoneCode: string
}

const BatchProcessToast = ({
  currentBatch,
  timeZoneCode
}: Props) => {
  return (
    <Box display='flex' gap={2}>
      <Grid item xs={2} display='flex' alignItems='start' pl={1} sx={{ pt: '6px' }}>
        <Icon type='dialog' />
      </Grid>
      <Grid item xs={10}>
        <Typography sx={{ fontSize: 14 }} fontWeight={600}>
          {getFormattedFileUploadDate(currentBatch, timeZoneCode)}
          {' '}
          Dispersión
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          {['PROCESSING', 'CHECKING'].includes(currentBatch?.status_cod)
            ? 'Proceso de payout cargado'
            : 'Proceso payout en espera de aprobación'
          }
        </Typography>
      </Grid>
    </Box>
  )
}

export default BatchProcessToast
