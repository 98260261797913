import Filter from '../Filter/Filter'
import CardToolbar from './CardToolbar/CardToolbar'

import './Card.css'

interface ICardProps {
    timeZoneCode: string
    title?: string
    subtitle?: React.ReactNode
    filterData?: any
    payoutDataSelected?: any
    isToolbarDisabled?: boolean
    isDispersing?: boolean
    hasFilter?: boolean
    hasToolbar?: boolean
    handleFilterSubmit?: any
    handleSelectOptionToolbarClick?: React.MouseEventHandler<HTMLLIElement> | undefined
    headerChildren?: any
    children?: any
};

const Card = ({
  timeZoneCode,
  title = '',
  subtitle,
  filterData,
  payoutDataSelected,
  isToolbarDisabled = false,
  isDispersing = false,
  hasFilter = false,
  hasToolbar = false,
  handleFilterSubmit,
  handleSelectOptionToolbarClick,
  headerChildren = <></>,
  children = <></>
}: ICardProps) => {
  /*
     * Renderizado del componente Card para contener los componentes del layout Batch
    */
  return (
    <div className="card">
      <div className="card_container">
        {
          (!title?.length && !subtitle && headerChildren === <></>)
            ? (
              <></>
            )
            : (
              <div className="card__header">
                <div className="card__header__title">
                  {
                    !title?.length
                      ? (
                        <></>
                      )
                      : (
                        <h3>{title}</h3>
                      )
                  }
                  <div className="card__header__title__children">
                    {headerChildren}
                    {
                      hasFilter && (
                        <Filter
                          filterData={filterData}
                          handleFilterSubmit={handleFilterSubmit}
                        />
                      )
                    }
                    {
                      hasToolbar && (
                        <CardToolbar
                          timeZoneCode={timeZoneCode}
                          payoutDataSelected={payoutDataSelected}
                          isDispersing={isDispersing}
                          isDisabled={isToolbarDisabled}
                          onClick={handleSelectOptionToolbarClick}
                        />
                      )
                    }
                  </div>
                </div>
                {
                  subtitle &&
                    ['string', 'number'].includes(typeof subtitle)
                    ? <div className="card__header__subtitle">
                      <h4>{subtitle}</h4>
                    </div>
                    : subtitle
                }
              </div>
            )
        }
        <div className="card__body">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Card
