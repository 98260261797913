import { useEffect } from 'react'

import Error from '../Error/Error'
import HomeView from './components/HomeView'

import useHome from './hooks/useHome'

import './Home.css'

const Home = () => {
  const {
    hasError,
    modalData,
    sessionUserIndexRoute,
    openHamburgerMenu,
    isMainLoading,
    setModalData,
    handleClickHamburguerMenu,
    handleSidebarMenuGroupItemClick
  } = useHome()

  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('Este navegador no soporta notificaciones.')
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Notification.requestPermission()
    }
  }, [])

  /*
   * Renderizado de la página inicial respecto a sus distintos estados
   */
  if (hasError) return <Error isLogged={true} />

  return (
    <HomeView
      modalData={modalData}
      setModalData={setModalData}
      sessionUserIndexRoute={sessionUserIndexRoute}
      openHamburgerMenu={openHamburgerMenu}
      isMainLoading={isMainLoading}
      handleSidebarMenuGroupItemClick={handleSidebarMenuGroupItemClick}
      handleClickHamburguerMenu={handleClickHamburguerMenu}
    />
  )
}

export default Home
