const bankData: any = {
  1: {
    name: 'BCP',
    icon: 'bcpLogo'
  },
  2: {
    name: 'BBVA',
    icon: 'bbvaLogo'
  },
  3: {
    name: 'Interbank',
    icon: 'interbankLogo'
  },
  4: {
    name: 'Scotiabank',
    icon: 'scotiabankLogo'
  }
}

export default bankData
