import { useAppSelector } from '../../store/hooks'

import HeaderView from './components/HeaderView'

import useHeader from './hooks/useHeader'

import './Header.css'

interface IHeaderProps {
  handleClickHamburguerMenu:
    | React.MouseEventHandler<HTMLDivElement>
    | undefined
}

const Header = ({
  handleClickHamburguerMenu
}: IHeaderProps) => {
  const userData = useAppSelector((state) => state.authReducer.user)

  const email = userData?.email_str ?? ''
  const name = userData?.name_str ?? ''

  const {
    headerProfileRef,
    openHeaderProfile,
    handleClickSignOut,
    handleOnMouseEnterHeaderProfile
  } = useHeader()

  /*
   * Renderizado del Header de la página principal
   */
  return (
    <HeaderView
      name={name}
      email={email}
      headerProfileRef={headerProfileRef}
      openHeaderProfile={openHeaderProfile}
      handleClickHamburguerMenu={handleClickHamburguerMenu}
      handleOnMouseEnterHeaderProfile={handleOnMouseEnterHeaderProfile}
      handleClickSignOut={handleClickSignOut} // eslint-disable-line
    />
  )
}

export default Header
