const getSidebarMenuData = (menuData: any) => {
  if (!menuData) return []
  return menuData?.map((item: any) => {
    const newSubMenu = item?.sub_menu.map((subItem: any) => {
      const { view } = subItem
      const { title_str: viewTitle } = view
      return {
        title_str: subItem?.title_str,
        view: {
          title_str: viewTitle,
          url_str: view?.url_str
        }
      }
    })
    return {
      title_str: item?.title_str,
      sub_menu: newSubMenu
    }
  })
}

export default getSidebarMenuData
