import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#009EF7'
    },
    secondary: {
      main: '#F1FAFF'
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: '14px'
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '12.5px',
          color: '#B5B5C3'
        },
        root: {
          fontSize: '14px',
          color: '#7E8299',
          fontWeight: 600
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F1FAFF !important'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%',
          color: '#5E6278',
          fontSize: '14px',
          fontWeight: 500,
          borderRadius: '7.5px',
          background: '#F5F8FA',
          borderColor: 'transparent',
          outline: 'none',
          height: '40px',
          paddingLeft: '15px',
          paddingRight: '15px',
          '::before': {
            display: 'none'
          },
          '::after': {
            display: 'none'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          height: '40px',
          ':hover': {
            boxShadow: 'none'
          }
        },
        containedPrimary: {
          color: '#fff',
          ':hover': {
            backgroundColor: '#0077C8'
          }
        },
        containedSecondary: {
          ':hover': {
            backgroundColor: '#F1FAFF',
            color: '#009ef7'
          }
        },
        root: {
          fontSize: '14px',
          textTransform: 'none',
          color: '#5E6278'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          top: '-10px',
          border: 'none'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: '#F4F6FA',
            color: '#009ef7'
          },
          '&.Mui-selected': {
            color: '#009ef7'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          background: '#F1FAFF',
          height: '40px',
          color: '#7E8299',
          fontWeight: 500
        }
      }
    }
  }
})

export default theme
