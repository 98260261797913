import type { PayoutDataType, PayoutStatusHistoryElementKey } from '../../../typings/types'
import { statusHistoryLabels } from '../../constants/statusHistoryLabels'
import getFormattedDate from '../date/getFormattedDate'

const getVisTimelineFormattedData = (data: PayoutDataType['status_history']) =>
  Object.entries(data).map(([key, value]) => ({
    id: key,
    content: key in statusHistoryLabels ? statusHistoryLabels[key as PayoutStatusHistoryElementKey] : 'Desconocido',
    start: getFormattedDate(value.request_dtm, 'GMT-5')
  }))

export default getVisTimelineFormattedData
