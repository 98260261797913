import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import TwoStepsView from './components/TwoStepsView'
import NotFound from '../Error/Error'

const TwoSteps = () => {
  const [otpValue, setOtpValue] = useState<string>('')
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState<boolean>(false)

  const location = useLocation()
  const username = location?.state?.username
  const navigate = useNavigate()

  const handleOtpInputValue = (otpValue: string) => {
    setOtpValue(otpValue)
    setIsActiveSubmitButton(otpValue?.length === 6)
  }

  /*
     * Función que maneja las acciones que se disparan al hacer click al botón submit de Doble Autenticación
    */
  const handleClickSubmitButton = async (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    navigate('/new-password', { state: { username, otpValue } })
  }

  /*
     * Renderizado del componente visual de la página de Doble Autenticación
    */
  if (!username) return <NotFound isLogged={false} />

  return (
    <TwoStepsView
      username={username}
      isActiveSubmitButton={isActiveSubmitButton}
      handleOtpInputValue={handleOtpInputValue}
      handleClickSubmitButton={handleClickSubmitButton} // eslint-disable-line
    />
  )
}

export default TwoSteps
