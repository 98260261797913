import Button from '../Button/Button'
import Overlay from '../Overlay/Overlay'

interface IModalViewProps {
    title: string
    description: string
    handleContinue: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const ModalView = ({ title, description, handleContinue }: IModalViewProps) => {
  return (
    <>
      <div className="modal">
        <div className="modal_container">
          <h2 className="modal__title">{title}</h2>
          <p className="modal__description">{description}</p>
          <Button text="Continuar" onClick={handleContinue} />
        </div>
      </div>
      <Overlay />
    </>
  )
}

export default ModalView
