import axios from 'axios'

const approveProcessService = async (payout_id: string, user: any): Promise<any> => { // eslint-disable-line
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_ENDPOINT_SL,
    headers: {
      Accept: '*/*'
    }
  })

  try {
    const response = await instance({
      url: `/ob-sl-kuraka/v1/payout/${payout_id}/status`,
      method: 'PATCH',
      data: {
        status_cod: 'DISPERSION'
      },
      auth: {
        username: user?.user_id,
        password: user?.secret_key
      }
    })

    return response
  } catch (error: any) {
    console.error('Error al consumir el servicio:', error?.response?.data?.service?.message_str)

    return error?.response
  };
}

export default approveProcessService
