import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'

import customerDetailDataService from '../../../../services/payoutServices/customerDetailDataService'
import getCustomerInvoicesTableData from '../../../../utils/data/getCustomerInvoicesTableData'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import invoicesDataService from '../../../../services/payoutServices/invoicesDataService'
import ModalContext from '../../../../contexts/ModalContext'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'

const useCustomerDetail = () => {
  const companyData = useAppSelector((state) => state.authReducer.company)

  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const { pathname } = useLocation()

  const permissions = getFinalPermissions(pathname, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const [nativeData, setNativeData] = useState<any>([])
  const [customerData, setCustomerData] = useState<any>({})
  const [customerInvoicesTableData, setCustomerInvoicesTableData] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingCustomerDetailData, setIsLoadingCustomerDetailData] = useState<boolean>(true)
  const [isLoadingCustomerInvoicesData, setIsLoadingCustomerInvoicesData] = useState<boolean>(true)

  const location = useLocation()

  const { setModalData } = useContext(ModalContext)

  const { id: customerId } = useParams()

  const company_id = companyData?.company_id ?? '' // eslint-disable-line
  const timeZoneCode = companyData?.time_zone_cod ?? ''

  /*
     * Función que maneja la petición de servicio que obtiene la data detallada del cliente buscado
    */
  const getCustomerDetailData = async (externalCustomerId: string) => {
    if (validPermittedServices('customerDetailData', permissions)) {
      try {
        const response = await customerDetailDataService(company_id, externalCustomerId, reduxUser)
        const { data: responseData } = response
        const { data } = responseData

        setCustomerData(data)

        setTimeout(function () {
          setIsLoadingCustomerDetailData(false)
        }, 1000)
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'customerDetailData' })
    }
  }

  const getInvoicesData = async (offset: number, externalCustomerId: string) => {
    try {
      const response = await invoicesDataService({
        company_id,
        external_customer_id: externalCustomerId,
        limit_int: 20,
        offset_int: offset,
        orderby: 'created_dtm',
        orderdir_str: 'desc',
        user: reduxUser
      })
      const { data: responseData } = response
      const { data: currentNativeData } = responseData
      const newNativeData = nativeData?.concat(currentNativeData)
      const customerInvoicesTableData = getCustomerInvoicesTableData(newNativeData, timeZoneCode)

      setNativeData(newNativeData)
      setCustomerInvoicesTableData(customerInvoicesTableData)

      setTimeout(function () {
        setIsLoadingCustomerInvoicesData(false)
      }, 1000)
    } catch (error: any) {
      setModalData({ isOpen: true, type: 'conexionError', service: '' })
    };
  }

  const handleCustomerInvoicesTableDataClick = (customerInvoiceData: any) => {
    const customerInvoiceId = customerInvoiceData?.invoiceId

    window.location.href = `/payout/invoice-detail/${customerInvoiceId}` // eslint-disable-line
  }

  const handleScrollCustomerInvoicesTableData = (event: any) => {
    const isBottom = (event?.target?.scrollHeight - event?.target?.scrollTop - 1) <= event?.target?.clientHeight

    if (isBottom && customerInvoicesTableData?.body?.length >= offset + 20) {
      const newOffset = offset + 20

      setOffset(newOffset)
      setIsLoadingCustomerInvoicesData(true)

      getInvoicesData(newOffset, customerId ?? '') // eslint-disable-line
    };
  }

  useEffect(() => {
    const locationArr = location?.pathname?.split('/')
    const urlPath = locationArr?.[locationArr?.length - 1]

    getCustomerDetailData(customerId ?? urlPath ?? '') // eslint-disable-line
    getInvoicesData(0, customerId ?? urlPath ?? '') // eslint-disable-line
    }, []); // eslint-disable-line

  useEffect(() => {
    if (!isLoadingCustomerDetailData && !isLoadingCustomerInvoicesData) {
      setIsLoading(false)
    };
  }, [isLoadingCustomerDetailData, isLoadingCustomerInvoicesData])

  return {
    // States
    customerData,
    isLoading,
    timeZoneCode,
    customerInvoicesTableData,

    // Functions States

    // Functions
    handleCustomerInvoicesTableDataClick,
    handleScrollCustomerInvoicesTableData
  }
}

export default useCustomerDetail
