const getStartEndDateOfCurrentMonth = (date: Date) => {
  // Obtenemos el mes y el año de la fecha dada
  const month = date?.getMonth()
  const year = date?.getFullYear()

  // Obtenemos el primer día del mes actual
  const firstDate = new Date(year, month, 1)

  // Obtenemos el último día del mes actual
  const lastDate = new Date(year, month + 1, 0)

  return {
    firstDate,
    lastDate
  }
}

export default getStartEndDateOfCurrentMonth
