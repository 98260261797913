import { createContext, useContext, useState, type ReactNode } from 'react'
import { type AuthContextType, type TokensType } from '../../typings/types'

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
    children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [tokens, setTokens] = useState<TokensType | null>(null)

  const updateTokens = (newTokens: TokensType) => {
    setTokens(newTokens)
    // Aquí también podrías agregar lógica para almacenar los tokens actualizados de forma segura
  }

  return (
    <AuthContext.Provider value={{ tokens, updateTokens }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
