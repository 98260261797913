import { ReactComponent as GoogleIcon } from '../../assets/icons/google-icon.svg'
import { ReactComponent as AppleIcon } from '../../assets/icons/apple-black-icon.svg'
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg'
import { ReactComponent as HamburguerIcon } from '../../assets/icons/hamburguer-icon.svg'
import { ReactComponent as AsideMenuIcon } from '../../assets/icons/aside-menu-icon.svg'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-icon.svg'
import { ReactComponent as BankIcon } from '../../assets/icons/bank-outlined.svg'
import { ReactComponent as MoneyBagIcon } from '../../assets/icons/money-bag.svg'
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg'
import { ReactComponent as SidebarToggleIcon } from '../../assets/icons/sidebar-toggle-icon.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg'
import { ReactComponent as CheckIcon } from '../../assets/icons/check-square-fill-icon.svg'
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-icon.svg'
import { ReactComponent as PDFIcon } from '../../assets/icons/pdf-icon.svg'
import { ReactComponent as BackIcon } from '../../assets/icons/back-icon.svg'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg'
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash-icon.svg'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg'
import { ReactComponent as RadioButtonCheckedIcon } from '../../assets/icons/radio-button-checked-icon.svg'
import { ReactComponent as RadioButtonUncheckedIcon } from '../../assets/icons/radio-button-unchecked-icon.svg'
import { ReactComponent as BcpIcon } from '../../assets/icons/bcp-icon.svg'
import { ReactComponent as BbvaIcon } from '../../assets/icons/bbva-icon.svg'
import { ReactComponent as InterbankIcon } from '../../assets/icons/interbank-icon.svg'
import { ReactComponent as OpenbankingIcon } from '../../assets/icons/openbanking-icon.svg'
import { ReactComponent as ScotiabankIcon } from '../../assets/icons/scotiabank-icon.svg'
import { ReactComponent as DialogIcon } from '../../assets/icons/dialog-icon.svg'
import { ReactComponent as AlarmIcon } from '../../assets/icons/alarm-fill.svg'
interface IIconProps {
    type: string
};

const IconByType: any = {
  google: <GoogleIcon />,
  apple: <AppleIcon/>,
  document: <DocumentIcon/>,
  hamburguer: <HamburguerIcon/>,
  asidemenu: <AsideMenuIcon/>,
  arrow: <ArrowIcon />,
  bank: <BankIcon />,
  moneybag: <MoneyBagIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  sidebartoggle: <SidebarToggleIcon />,
  close: <CloseIcon />,
  check: <CheckIcon />,
  menu: <MenuIcon />,
  pdf: <PDFIcon />,
  back: <BackIcon />,
  eye: <EyeIcon />,
  eyeslash: <EyeSlashIcon />,
  filter: <FilterIcon />,
  calendar: <CalendarIcon />,
  search: <SearchIcon />,
  radioButtonChecked: <RadioButtonCheckedIcon />,
  radioButtonUnChecked: <RadioButtonUncheckedIcon />,
  bcpLogo: <BcpIcon />,
  bbvaLogo: <BbvaIcon />,
  interbankLogo: <InterbankIcon />,
  scotiabankLogo: <ScotiabankIcon />,
  openbankingLogo: <OpenbankingIcon />,
  dialog: <DialogIcon />,
  alarm: <AlarmIcon />
}

const Icon = ({ type }: IIconProps) => {
  if (!type?.length || !IconByType?.[type]) return <></>

  return IconByType[type]
}

export default Icon
