import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

const forgotPasswordService = async (username: string) => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? ''
  }

  const userPool = new CognitoUserPool(poolData)

  const userData = {
    Username: username,
    Pool: userPool
  }

  const cognitoUser = new CognitoUser(userData)

  return await new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        // Manejamos la recuperación exitosa de la contraseña
        resolve(data)
      },
      onFailure: (error) => {
        reject(error)
      },
      inputVerificationCode: (data) => {
        // Manejamos la entrada del código de verificación
        resolve(data)
      }
    })
  })
}

export default forgotPasswordService
