import type { TTimelineData, PayoutDataType } from '../../../typings/types'
import Icon from '../../components/Icon/Icon'
import ProcessStatusLoader from '../../components/Loader/ProcessStatusLoader/ProcessStatusLoader'
import processStatusData from '../../constants/processStatusData'
import getFormattedMoney from '../money/getFormattedMoney'

const getBatchDispersionStateDetailTimeLineData = (events?: PayoutDataType['events']) => {
  if (!events) return []

  const eventsArr = Object.entries(events)

  const newEvents = eventsArr.map(event => {
    return { ...event[1], code: event[0] }
  })

  const nonNullsEvents = newEvents.filter(event => event.status_name_str)

  const timelineData: TTimelineData = nonNullsEvents.map(newEvent => {
    const color = processStatusData.find(data => data.code === newEvent.code)?.color ?? 'yellow'
    const isDone = newEvent.status_cod === 'SUCCESS'

    return {
      leftSection: {
        color,
        title: `${newEvent.total_rows_int.toString()} invoices`,
        subtitle: newEvent.status_name_str
      },
      rightSection: {
        icon: isDone ? <Icon type="success" /> : <ProcessStatusLoader />,
        title: 'Total',
        subtitle: `S/ ${getFormattedMoney(newEvent.total_dcm)}`
      }
    }
  })

  return timelineData
}

export default getBatchDispersionStateDetailTimeLineData
