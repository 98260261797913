import { BrowserRouter, Navigate, Routes as ReactDomRoutes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { Provider } from 'react-redux'

import Batch from './modules/payouts/Batch/Batch'
import CustomerDetail from './modules/payouts/Customers/components/CustomerDetail/CustomerDetail'
import Customers from './modules/payouts/Customers/Customers'
import Home from './pages/Home/Home'
import InvoiceDetail from './modules/payouts/InvoiceDetail/InvoiceDetail'
import Invoices from './modules/payouts/Invoices/Invoices'
import Login from './pages/Login/Login'
import NewPassword from './pages/NewPassword/NewPassword'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import Statement from './modules/payouts/Statement/Statement'
import TwoSteps from './pages/TwoSteps/TwoSteps'

import { AuthProvider } from './contexts/AuthContext'
import { ProtectedRoutes } from './routes/ProtectedRoutes'
import { store } from './store/store'
import theme from './theme/theme'

import './App.css'

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <div className="App">
            <BrowserRouter>
              <ReactDomRoutes>
                <Route path='/login' element={<Login />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/two-steps' element={<TwoSteps />} />
                <Route path='/new-password' element={<NewPassword />} />
                <Route element={<ProtectedRoutes />} >
                  <Route path='/' element={<Home />}>
                    <Route path='/payout/batch' element={<Batch/>} />
                    <Route path='/payout/statement' element={<Statement/>} />
                    <Route path='/payout/customers' element={<Customers/>} />
                    <Route path='/payout/customer-detail/:id' element={<CustomerDetail/>} />
                    <Route path='/payout/invoice-detail/:id' element={<InvoiceDetail/>} />
                    <Route path='/payout/invoices' element={<Invoices/>} />
                  </Route>
                </Route>
                <Route path='*' element={<Navigate to='/payout/batch' />} />
              </ReactDomRoutes>
            </BrowserRouter>
          </div>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
