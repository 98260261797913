import Image from '../../../components/Image/Image'
import Button from '../../../components/Button/Button'

interface IErrorViewProps {
    errorText: string
    handleErrorButton: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const ErrorView = ({ errorText, handleErrorButton }: IErrorViewProps) => {
  return (
    <div className="errorpage">
      <div className="errorpage_container">
        <div className="errorpage__card">
          <div className="errorpage__card_container">
            <h1 className="errorpage__card__title">Oops!</h1>
            <p className="errorpage__card__description">{"We can't find that page."}</p>
            <Image type="error404" width={300} height={300} alt="Error 404" />
            <Button text={errorText} size="small" isFitContent={true} onClick={handleErrorButton} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorView
