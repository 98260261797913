import isGMTOffset from './isGMTOffset'

const getTimeZoneHours = (timeZoneCode: string) => {
  if (isGMTOffset(timeZoneCode)) {
    return parseInt(timeZoneCode?.split?.('GMT')?.[1])
  } else {
    return 0
  };
}

export default getTimeZoneHours
