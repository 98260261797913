import { useState, useEffect } from 'react'
import moment from 'moment'

import TimerView from './TimerView'

import getFormattedDate from '../../utils/date/getFormattedDate'

import './Timer.css'

interface ITimerProps {
    timeZoneCode: string
    initialDate?: string
    finalDate?: string
    isDone?: boolean
};

const Timer = ({
  timeZoneCode,
  initialDate = '',
  finalDate = '',
  isDone = false
}: ITimerProps) => {
  let interval: NodeJS.Timeout

  const [requestTime, setRequesTime] = useState<number>(0)
  const [time, setTime] = useState<number>(0)
  const [isActive, setIsActive] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /*
     * Efecto que hace que el timer avance 1 segundo mientras el estado esté activo
    */
  useEffect(() => {
    if (isActive) {
      // eslint-disable-next-line
            interval = setInterval(() => {
        setTime((prevSegundos) => prevSegundos + 1)
      }, 1000)
    } else {
      clearInterval(interval)
    };

    return () => {
      clearInterval(interval)
    }
  }, [isActive])

  /*
     * Efecto que setea el valor del tiempo actual del timer
    */
  useEffect(() => {
    if (!initialDate || !finalDate) return

    const currentTime = getFormattedDate(moment(new Date())?.utcOffset('+00:00')?.format('DD/MM/YYYY HH:mm:ss'), timeZoneCode).getTime() / 1000
    const initialTime = getFormattedDate(initialDate, timeZoneCode).getTime() / 1000
    const finalTime = getFormattedDate(finalDate, timeZoneCode).getTime() / 1000

    const difference = finalTime - initialTime

    if (isDone) {
      setIsActive(false)
      setTime(difference)
    } else {
      setRequesTime(currentTime - initialTime)
    };

    setIsLoading(false)
    }, [initialDate, isDone]); // eslint-disable-line

  if (isLoading) return <></>

  return <TimerView requestTime={requestTime} time={time} />
}

export default Timer
