const processStatusData = [
  {
    id: 1,
    name: 'pending',
    title: 'Pendiente',
    altTitle: 'Pendiente',
    color: 'yellow',
    colorCode: '#ffc700',
    tableDataColor: 'blue',
    code: 'pending'
  },
  {
    id: 2,
    name: 'processing',
    title: 'Procesando',
    altTitle: 'Procesando',
    color: 'yellow',
    colorCode: '#ffc700',
    tableDataColor: 'blue',
    code: 'read'
  },
  {
    id: 3,
    name: 'checking',
    title: 'Revisando',
    altTitle: 'Revisando',
    color: 'blue',
    colorCode: '#009ef7',
    tableDataColor: 'blue',
    code: 'processed'
  },
  {
    id: 4,
    name: 'waiting',
    title: 'Esperando',
    altTitle: 'Esperando',
    color: 'yellow',
    colorCode: '#ffc700',
    tableDataColor: 'yellow',
    code: 'waited'
  },
  {
    id: 5,
    name: 'dispersion',
    title: 'Dispersando',
    altTitle: 'Dispersando',
    color: 'blue',
    colorCode: '#009ef7',
    tableDataColor: 'blue',
    code: 'dispersed'
  },
  {
    id: 6,
    name: 'rejection',
    title: 'Rechazado',
    altTitle: 'Rechazado',
    color: 'red',
    colorCode: '#f1416c',
    tableDataColor: 'red',
    code: 'rejected'
  },
  {
    id: 7,
    name: 'success',
    title: 'Finalizado',
    altTitle: 'Pagado',
    color: 'green',
    colorCode: '#50cd89',
    tableDataColor: 'green',
    code: 'paid'
  },
  {
    id: 8,
    name: 'voided',
    title: 'Anulado',
    altTitle: 'Anulado',
    color: 'red',
    colorCode: '#f1416c',
    tableDataColor: 'red',
    code: 'voided'
  }
] as const

export default processStatusData
