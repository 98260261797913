import Icon from '../../../components/Icon/Icon'
import Image from '../../../components/Image/Image'
import Link from '../../../components/Link/Link'

interface IHeaderViewProps {
    name: string
    email: string
    headerProfileRef: React.LegacyRef<HTMLDivElement> | undefined
    openHeaderProfile: boolean
    handleClickHamburguerMenu: React.MouseEventHandler<HTMLDivElement> | undefined
    handleClickSignOut: React.MouseEventHandler<HTMLLIElement> | undefined
    handleOnMouseEnterHeaderProfile: React.MouseEventHandler<HTMLDivElement> | undefined
};

const HeaderView = ({
  name,
  email,
  headerProfileRef,
  openHeaderProfile,
  handleClickHamburguerMenu,
  handleOnMouseEnterHeaderProfile,
  handleClickSignOut
}: IHeaderViewProps) => {
  return (
    <header className="header">
      <div className="header_container">
        <div className="header__menu">
          <div
            className="header__menu__hamburguer"
            onClick={handleClickHamburguerMenu}
          >
            <Icon type="hamburguer" />
          </div>
          <Link url="/">
            <Image
              type="oblogomin"
              width={42}
              height={30}
              alt="Open Banking Mini Logo"
            />
          </Link>
        </div>
        <div
          ref={headerProfileRef}
          className="header__profile"
          onMouseEnter={handleOnMouseEnterHeaderProfile}
        >
          <Image
            type="profiledefault"
            width={40}
            height={40}
            alt="Profile Photo by default"
          />
          <div
            className={`header__profile__menu ${openHeaderProfile ? 'header__profile__menu--open' : ''}`}
          >
            <div className="header__profile__header">
              <div className="header__profile__header_container">
                <Image
                  type="profiledefault"
                  width={50}
                  height={50}
                  alt="Profile Photo by default"
                />
                <div className="header__profile__header__text">
                  <span className="header__profile__header__text__name">{name}</span>
                  <span className="header__profile__header__text__email">{email}</span>
                </div>
              </div>
            </div>
            <ul className="header__profile__menulist">
              <li className="header__profile__menuitem" onClick={handleClickSignOut}>
                <span className="header__profile__menuitem__text">Sign Out</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderView
