import './Footer.css'
import { useFooter } from './hooks'

const Footer = () => {
  const { currentYear } = useFooter()
  /*
     * Renderizado del Footer de la página principal
    */
  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer__copyright">
          <span>{currentYear} - OpenBanking Perú ©</span>
        </div>
        <div className="footer__links">
          <a href="/" className="footer__link">About</a>
          <a href="/" className="footer__link">Support</a>
          <a href="/" className="footer__link">Purchase</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
