import getFormattedTimerDate from '../../utils/date/getFormattedTimerDate'

interface ITimerViewProps {
    requestTime: number
    time: number
};

const TimerView = ({ requestTime, time }: ITimerViewProps) => {
  return (
    <div className="timer">
      {getFormattedTimerDate(requestTime + time)}
    </div>
  )
}

export default TimerView
