import Icon from '../../../../components/Icon/Icon'

interface ISidebarMenuGroupTitle {
    id: string
    title: string
    disabled: boolean
    open: boolean
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined
};

const SidebarMenuGroupTitle = ({ id, title, disabled = false, open, onClick }: ISidebarMenuGroupTitle) => {
  const classNameDisabled = disabled ? 'sidebar__body__group__title--disabled' : ''
  const classNameOpen = open ? 'sidebar__body__group__title--open' : ''
  const className = `sidebar__body__group__title ${classNameDisabled} ${classNameOpen}`

  /*
     * Renderizado del título de cada menu del layout que contiene el Sidebar
    */
  return (
    <div
      id={id}
      className={className}
      onClick={onClick}
    >
      <div className="sidebar__body__group__title__icon">
        <Icon type="asidemenu" />
      </div>
      <p className="sidebar__body__group__title__text">{title}</p>
      <span className="sidebar__body__group__title__arrow">
        <Icon type="arrow" />
      </span>
    </div>
  )
}

export default SidebarMenuGroupTitle
