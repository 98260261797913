/* eslint-disable @typescript-eslint/naming-convention */
import axios, { type AxiosResponse } from 'axios'

const listFileBatchService = async (
  company_id: string,
  limit_int: number,
  offset_int: number,
  orderby: string,
  orderdir_str: string,
  filterData: any,
  user: any
): Promise<AxiosResponse | undefined> => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_ENDPOINT_MS,
    headers: {
      Accept: '*/*'
    }
  })

  const { processStatus, calendar, nameId } = filterData

  try {
    const response = await instance({
      url: `/ob-ms-quipu/v1/payout/list-file-batch?company_id=${company_id}&limit_int=${limit_int}&offset_int=${offset_int}&orderby=${orderby}&orderdir_str=${orderdir_str}&status=${processStatus}&ini_dte=${calendar?.iniDte}&fin_dte=${calendar?.finDte}&id_name_str=${nameId}`, // eslint-disable-line
      method: 'GET',
      auth: {
        username: user?.user_id,
        password: user?.secret_key
      }
    })

    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Es un error de Axios
      if (error?.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado que no está en el rango 2xx
        console.error('Respuesta del servidor con un error:')
        console.error(error?.response?.data?.error?.message)
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió ninguna respuesta
        console.error('No se recibió respuesta del servidor. Posiblemente un problema de red.')
      } else {
        // Error de red, no se pudo realizar la solicitud
        console.error('Error de red: No se pudo realizar la solicitud.')
      };
    } else {
      // No es un error de Axios, puede ser un error de red
      console.error('Error no manejado:', error)
    };

    return undefined // Devuelve undefined en caso de error
  };
}

export default listFileBatchService
