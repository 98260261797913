import Image from '../../Image/Image'

import './BatchDispersionStateLoader.css'

const BatchDispersionStateLoader = () => {
  /*
     * Renderizado del componente de la pantalla de carga del Estado de Dispersión
    */
  return (
    <div className="batchdispersionstateloader">
      <Image
        type="oblogoloading"
        width={100}
        height={92}
        alt="Open Banking Logo in State loader view"
      />
    </div>
  )
}

export default BatchDispersionStateLoader
