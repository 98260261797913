import InvoiceDetailView from './components/InvoiceDetailView'

import useInvoiceDetail from './hooks/useInvoiceDetail'

import './InvoiceDetail.css'

const InvoiceDetail = () => {
  const {
    timeZoneCode,
    invoiceData,
    isLoading,
    handleCreateTransactionReport
  } = useInvoiceDetail()

  if (isLoading) return <></>

  return (
    <InvoiceDetailView
      timeZoneCode={timeZoneCode}
      invoiceData={invoiceData}
      handleCreateTransactionReport={handleCreateTransactionReport}
    />
  )
}

export default InvoiceDetail
