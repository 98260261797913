import getCurrencySymbolFromCode from '../money/getCurrencySymbolFromCode'

import getFormattedMoney from '../money/getFormattedMoney'
import getSignMoney from '../money/getSignMoney'

import { statementTableHeadingsData } from '../../constants/tableData'

const getStatementTableData = (data: any, timeZoneCode: string) => {
  if (!data) return

  const statementTableBodyData = data?.map((item: any) => {
    return {
      dateTime: item?.created_dtm ?? item?.dtm,
      typeCodeStr: item?.type_cod_str || '-',
      description: item?.description_str || '-',
      payoutFile: item?.payout_file_str || '-',
      currency: getCurrencySymbolFromCode(item?.currency_cod),
      amount: getFormattedMoney(getSignMoney(item?.funds_dcm, item?.type_cod?.toUpperCase())),
      remainingFund: getFormattedMoney(item?.available_funds_dcm)
    }
  })

  const statementTableData = {
    headings: statementTableHeadingsData,
    body: statementTableBodyData
  }

  return statementTableData
}

export default getStatementTableData
