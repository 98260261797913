import { useAppSelector } from '../../../../store/hooks'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import approveProcessService from '../../../../services/payoutServices/approveProcessService'
import bankBatchService from '../../../../services/payoutServices/bankBatchService'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import getTimeZoneHours from '../../../../utils/date/getTimeZoneHours'
import getUtcOffHours from '../../../../utils/date/getUtcOffHours'
import ModalContext from '../../../../contexts/ModalContext'
import processStatusService from '../../../../services/payoutServices/processStatusService'
import transactionReportService from '../../../../services/payoutServices/transactionReportService'
import transactionStatusService from '../../../../services/payoutServices/transactionStatusService'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'
import voidProcessService from '../../../../services/payoutServices/voidProcessService'

import { InitialPayoutData } from '../../../../constants/initialData'
import { type BankSelectedType, type BatchProcessStatusType, type PayoutDataType } from '../../../../../typings/types'
import bankCodes from '../../../../constants/bankCodes'
import { downloadDataAsFile } from '../../../../utils/data/downloadDataAsFile'

interface Props {
  timeZoneCode: string
  uploadFileIdSelected: string
  setCurrentPayout: any
  currentPayout: PayoutDataType
  setIsDispersionStateLoading: any
  reloadBatchDispersionState: () => void
  handleDispersionLoadingDone: (option: boolean) => void
}

const useBatchDispersionState = ({
  timeZoneCode, uploadFileIdSelected,
  currentPayout,
  setCurrentPayout,
  setIsDispersionStateLoading,
  reloadBatchDispersionState,
  handleDispersionLoadingDone
}: Props) => {
  let interval: NodeJS.Timeout

  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const { pathname } = useLocation()

  const permissions = getFinalPermissions(pathname, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const timeZoneHours = getTimeZoneHours(timeZoneCode)
  const utcOffHours = getUtcOffHours(timeZoneHours)

  const [payoutDataSelected, setPayoutDataSelected] = useState<PayoutDataType>(InitialPayoutData)
  const [fileNameSelected, setFileNameSelected] = useState<string>('')
  const [processStatus, setProcessStatus] = useState<BatchProcessStatusType>('PROCESSING')
  const [bankSelected, setBankSelected] = useState<BankSelectedType>('')
  const [transactionReportsOptions, setTransactionReportsOptions] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isProcess, setIsProcess] = useState<boolean>(false)
  const [isOpenTransactionReports, setIsOpenTransactionReports] = useState<boolean>(false)
  const [statusHistoryTimelineOpened, setStatusHistoryTimelineOpened] = useState(false)

  const { modalData, setModalData } = useContext(ModalContext)

  /*
     * Función que maneja la petición de servicio del proceso de estado de dispersión de un archivo
    */
  const setFileBatchProcessStatus = async () => {
    if (validPermittedServices('processStatus', permissions)) {
      try {
        const response = await processStatusService(currentPayout, reduxUser)

        const { data } = response

        if (data === 'OK') {
          setPayoutDataSelected(InitialPayoutData)
          setCurrentPayout(InitialPayoutData)
        } else {
          setProcessStatus(data?.status_cod)
          setPayoutDataSelected(data)
          setCurrentPayout(data)
        };

        setIsLoading(false)
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: 'processStatus' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'processStatus' })
    }

    setIsLoading(false)
  }

  /*
     * Función que detiene el intervalo que llama a la petición de servicio del proceso de estado de dispersión.
    */
  const handleClickSelectExport = async (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const { id } = event?.currentTarget

    let response: any

    if (id === 'reports') {
      if (validPermittedServices('transactionStatus', permissions)) {
        try {
          response = await transactionStatusService(uploadFileIdSelected, reduxUser)

          downloadDataAsFile({
            data: response.data,
            date: response.headers.date,
            format: 'xlsx',
            name: 'transaction-status',
            utcOffHours
          })
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'transactionStatus' })
      }
    } else if (id === 'process-approve') {
      if (validPermittedServices('approveProcess', permissions)) {
        try {
          const response = await approveProcessService(uploadFileIdSelected, reduxUser)

          const { status } = response

          if (status === 200) {
            reloadBatchDispersionState()
          } else if (status === 402) {
            const errorMessage = response?.data?.service?.message_str

            setModalData({ isOpen: true, type: 'serviceError', service: 'approveProcess', message: errorMessage })
          } else {
            setModalData({ isOpen: true, type: 'serviceError', service: 'approveProcess', message: 'Problemas de conexión, intentar nuevamente' })
          };
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'approveProcess' })
      }
    } else if (id === 'process-void') {
      if (validPermittedServices('voidProcess', permissions)) {
        try {
          await voidProcessService(uploadFileIdSelected, reduxUser)

          reloadBatchDispersionState()
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'voidProcess' })
      }
    } else if (id === 'upload-file') {
      const { file_path } = payoutDataSelected?.batch?.file // eslint-disable-line

      window.open(file_path, '_blank')
    } else {
      if (validPermittedServices('bankBatch', permissions)) {
        try {
          const bank: any = {
            all: 'all',
            bcp: bankCodes.BCP,
            bbva: bankCodes.BBVA,
            ibk: bankCodes.INTERBANK,
            sco: bankCodes.SCOTIABANK,
            otr: bankCodes.OTHERS
          }

          const response = await bankBatchService(uploadFileIdSelected, 'txt', 'zip', bank?.[id], reduxUser)

          const { data } = response

          const uploadFileData = JSON.parse(new TextDecoder().decode(data as ArrayBuffer))

          const { path } = uploadFileData?.files?.[id]

          window.open(path, '_blank')
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'bankBatch' })
      }
    };
  }

  /*
     * Función que maneja el click al seleccionar un banco
    */
  const handleClickSelectBank = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const { id: bankId } = event?.currentTarget

    const bankSelected = bankId?.split('navitem--')?.[1]

    setBankSelected(bankSelected as BankSelectedType)
  }

  const handleTransactionReportOptions = (paidBanks: any) => {
    if (!paidBanks) return

    const banks = [
      {
        id: bankCodes.BCP,
        name: 'BCP'
      },
      {
        id: bankCodes.BBVA,
        name: 'BBVA'
      },
      {
        id: bankCodes.INTERBANK,
        name: 'Interbank'
      },
      {
        id: bankCodes.SCOTIABANK,
        name: 'Scotiabank'
      },
      {
        id: bankCodes.OTHERS,
        name: 'BBVA-CCI'
      }
    ]

    const transactionReportsBanksArr = Object?.entries(paidBanks)

    const filteredTransactionReportsBanks = transactionReportsBanksArr?.filter((entry: any) => {
            const [_, value] = entry; // eslint-disable-line

      if (!value?.operation_cod || value?.operation_cod === '') {
        return false
      };

      if (!value?.['file-status']) {
        return false
      };

      if (!value?.['file-status']?.file_path || value?.['file-status']?.file_path === '') {
        return false
      };

      return true
    })

    const transactionReportsOptions = filteredTransactionReportsBanks?.map((currentBank: any, idx: number) => {
      const bankName = banks?.find((bank: any) => bank?.id === currentBank?.[0])?.name

      return {
        id: idx,
        name: bankName,
        title: bankName,
        value: currentBank?.[1]?.['file-status']?.file_path
      }
    })

    setTransactionReportsOptions(transactionReportsOptions)
  }

  const handleClickTransactionReport = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    if (transactionReportsOptions?.length) {
      setIsOpenTransactionReports(!isOpenTransactionReports)
    } else {
      handleCreateTransactionReport() // eslint-disable-line
    };
  }

  const handleClickTransactionReportsBanks = (option: string) => {
    handleCreateTransactionReport(option) // eslint-disable-line
  }

  /*
     * Función que maneja el click del botón de creación del reporte de transacción
    */
  const handleCreateTransactionReport = async (url: string = '') => {
    if (validPermittedServices('transactionReport', permissions)) {
      try {
        let currentUrl: string
        const date = new Date()
        const momentDate = moment(date)?.utcOffset(utcOffHours)?.format('DDMMYYYYHHmmss')

        if (url === '') {
          const response = await transactionReportService(uploadFileIdSelected, reduxUser)

          const { data } = response
          currentUrl = window?.URL?.createObjectURL(new Blob([data]))
        } else {
          currentUrl = url
        };

        const link = document?.createElement('a')

        link.setAttribute('href', currentUrl)
        link.setAttribute('target', '_blank')
        link.setAttribute('download', `transaction-report-${momentDate}.pdf`)

        document?.body?.appendChild(link)

        link?.click()
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'transactionReport' })
    }
  }

  const handleClickStatusHistoryTrigger = () => {
    setStatusHistoryTimelineOpened(!statusHistoryTimelineOpened)
  }

  const transactionReportsRef = useOutsideClick(() => { setIsOpenTransactionReports(false) })

  /*
     * Efecto que dispara los eventos cuando se selecciona un archivo subido de la cola
    */
  useEffect(() => {
    if (uploadFileIdSelected === '') {
      setPayoutDataSelected(InitialPayoutData)
      setBankSelected('')
      setIsProcess(false) // Si se deselecciona un archivo de la lista, se mata el bucle
      setIsLoading(true)

      return
    };

    setPayoutDataSelected(InitialPayoutData)
    setBankSelected('Todos')
    setIsProcess(false)
    setIsLoading(true)

    setTimeout(() => {
      setIsProcess(true)
    }, 500)
  }, [uploadFileIdSelected])

  /*
     * Efecto que respecto a la data del archivo seleccionado de la cola, setea el nombre de este y que además
     * maneja las opciones de bancos para la creación del reporte de transacción
    */
  useEffect(() => {
    if (!payoutDataSelected) return

    handleTransactionReportOptions(payoutDataSelected?.events?.paid?.banks)

    setFileNameSelected(payoutDataSelected?.name_str)
  }, [payoutDataSelected, timeZoneCode])

  /*
     * Efecto que maneja si el componente de dispersión está cargando y lo manda
     * al padre "Batch" para su uso
    */
  useEffect(() => {
    if (!isLoading) {
      handleDispersionLoadingDone(true)
    };
    }, [isLoading]); // eslint-disable-line

  /*
     * Efecto que dispara la petición de servicio en intervalos de 5 segundos
    */
  useEffect(() => {
    if (isProcess) {
      if (processStatus === 'PROCESSING' || processStatus === 'CHECKING') {
        setFileBatchProcessStatus() // eslint-disable-line
        // eslint-disable-next-line
                interval = setInterval(() => {
          setFileBatchProcessStatus() // eslint-disable-line
        }, 5000)
      };

      if (processStatus === 'DISPERSION') {
        interval = setInterval(() => {
          setFileBatchProcessStatus() // eslint-disable-line
        }, 300000)
      };
    } else {
      setProcessStatus('PROCESSING')

      clearInterval(interval)
    };

    return () => {
      clearInterval(interval)
    }
  }, [isProcess, processStatus])

  /*
     * Efecto que dispara la eliminación del bucle de petición de servicio y manda una señal de successful
     * del proceso, al componente padre Batch
    */
  useEffect(() => {
    if (processStatus === 'SUCCESS') {
      setIsProcess(false)
    };
  }, [processStatus])

  /*
     * Efecto que detecta si el modal apareció para detener automáticamente el proceso de estado
     * del archivo subido seleccionado de la lista
    */
  useEffect(() => {
    if (modalData?.isOpen && modalData?.service === 'processStatus') {
      setIsProcess(false)
    };
    }, [modalData?.isOpen]); // eslint-disable-line

  useEffect(() => {
    setIsDispersionStateLoading(isLoading)
    }, [isLoading]); // eslint-disable-line

  return {
    // States
    fileNameSelected,
    bankSelected,
    transactionReportsRef,
    payoutDataSelected,
    processStatus,
    transactionReportsOptions,
    isLoading,
    isProcess,
    isOpenTransactionReports,
    statusHistoryTimelineOpened,

    // Functions States

    // Functions
    handleClickSelectExport,
    handleClickSelectBank,
    handleClickTransactionReport,
    handleClickTransactionReportsBanks,
    handleClickStatusHistoryTrigger
  }
}

export default useBatchDispersionState
