import { Helmet } from 'react-helmet'

import Link from '../../../components/Link/Link'
import Icon from '../../../components/Icon/Icon'
import InputField from '../../../components/InputField/InputField'
import Button from '../../../components/Button/Button'

interface INewPasswordViewProps {
    hasConfirmNewPasswordError: boolean
    isActiveSubmitButton: boolean
    submitNewPasswordErrorMessage: string
    setHasValidPassword: any
    handleNewPasswordReturnClick: React.MouseEventHandler<HTMLDivElement> | undefined
    handleNewPasswordInputValues: React.ChangeEventHandler<HTMLInputElement> | ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
    handleLoginInputBlur: React.FocusEventHandler<HTMLInputElement> | undefined
    handleSubmitNewPassword: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const NewPasswordView = ({
  hasConfirmNewPasswordError,
  isActiveSubmitButton,
  submitNewPasswordErrorMessage,
  setHasValidPassword,
  handleNewPasswordReturnClick,
  handleNewPasswordInputValues,
  handleLoginInputBlur,
  handleSubmitNewPassword
}: INewPasswordViewProps) => {
  return (
    <div className="page">
      <Helmet>
        <title>OpenBanking - New password</title>
      </Helmet>
      <div className="page_container">
        <main className="page__main">
          <div className="page__main_container">
            <div className="page__main__header">
              <div className="page__main__header_container">
                <div className="page__main__header_return" onClick={handleNewPasswordReturnClick}>
                  <Icon type="back" />
                </div>
                <div className="page__main__header__text">
                  <span>Already a member?</span>
                  <Link text="Sign In" />
                </div>
              </div>
            </div>
            <form className="page__main__form">
              <div className="page__main__form__header">
                <h2 className="page__main__form__title">Setup New Password</h2>
                <p className="page__main__form__description">Have you already reset the password ?</p>
              </div>
              <div className="page__main__form__inputfields">
                <InputField
                  name="newPassword"
                  type="password"
                  placeholder="Password"
                  hasBars={true}
                  setHasValidPassword={setHasValidPassword}
                  onChange={handleNewPasswordInputValues}
                />
                <InputField
                  name="confirmNewPassword"
                  type="password"
                  placeholder="Confirm Password"
                  hasError={hasConfirmNewPasswordError}
                  onBlur={handleLoginInputBlur}
                  onChange={handleNewPasswordInputValues}
                />
              </div>
              <div className="page__main__form__buttons">
                <Button
                  text="Submit"
                  isFitContent={true}
                  isDisabled={!isActiveSubmitButton}
                  onClick={handleSubmitNewPassword}
                />
                <span className="page__main__form__error">{submitNewPasswordErrorMessage ?? ''}</span>
              </div>
            </form>
            <div className="page__main__footer"></div>
          </div>
        </main>
        <aside className="page__aside">
        </aside>
      </div>
    </div>
  )
}

export default NewPasswordView
