import LoginView from './components/LoginView'

import useLogin from './hooks/useLogin'

const Login = () => {
  const {
    hasLoginEmailError,
    hasLoginPasswordError,
    isActiveSubmitButton,
    submitLoginErrorMessage,
    isLoadingLogin,
    handleLoginInputBlurs,
    handleLoginInputValues,
    handleSubmitSignIn
  } = useLogin()

  /*
   * Renderizado del componente visual de la página de Login
   */
  return (
    <LoginView
      isLoadingLogin={isLoadingLogin}
      hasLoginEmailError={hasLoginEmailError}
      hasLoginPasswordError={hasLoginPasswordError}
      submitLoginErrorMessage={submitLoginErrorMessage}
      isActiveSubmitButton={isActiveSubmitButton}
      handleLoginInputValues={handleLoginInputValues}
      handleLoginInputBlurs={handleLoginInputBlurs}
      handleSubmitSignIn={handleSubmitSignIn} // eslint-disable-line
    />
  )
}

export default Login
