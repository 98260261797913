import { useState, useRef } from 'react'
import Flatpickr, { type DateTimePickerProps } from 'react-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'

import Icon from '../Icon/Icon'

import getStartEndDateOfCurrentMonth from '../../utils/date/getStartEndDateOfCurrentMonth'
import getSmallestDateBetweenTwo from '../../utils/date/getSmallestDateBetweenTwo'

import 'flatpickr/dist/flatpickr.css'
import './DatePicker.css'
import moment from 'moment'

interface IDatePickerProps {
    value?: string[]
    isDisabled?: boolean
    canUnselect?: boolean
    canOnlySelectSameMonth?: boolean
    setIsOpenCalendar: any
    setCalendarDate: (calendarDate: any) => void
};

const DatePicker = ({
  value,
  isDisabled = false,
  canUnselect = false,
  canOnlySelectSameMonth = false,
  setIsOpenCalendar,
  setCalendarDate
}: IDatePickerProps) => {
  const [currentDate, setCurrentDate] = useState<any>([])
  const [minCalendarDate, setMinCalendarDate] = useState<Date | undefined>(undefined)
  const [maxCalendarDate, setMaxCalendarDate] = useState<Date | undefined>(new Date())

  const classNameDisabled = isDisabled ? 'datepicker--disabled' : ''
  const className = `datepicker ${classNameDisabled}`

  const flatpickerRef = useRef<Flatpickr | null>(null)

  const handleClickDatePicker = (event: any) => {
    event?.preventDefault()

    flatpickerRef?.current?.flatpickr?.open()
  }

  /*
     * Función que controla cuando se abre el calendario
    */
  const handleOpenCalendar = () => {
    setIsOpenCalendar(true)
  }

  /*
     * Función que controla el valor actual de las fechas seleccionadas para mandarlas al componente padre
    */
  const handleValueChange: DateTimePickerProps['onChange'] = (selectedDates, _, instance) => {
    const today = new Date()

    // Lógica de "canOnlySelectSameMonth"
    if (canOnlySelectSameMonth && selectedDates.length === 1) {
      const { firstDate: minCalendarDate, lastDate } = getStartEndDateOfCurrentMonth(selectedDates[0])

      const maxCalendarDate = getSmallestDateBetweenTwo(lastDate, today)

      setMinCalendarDate(minCalendarDate)
      setMaxCalendarDate(maxCalendarDate)
    } else {
      if (selectedDates.length === 1) {
        const selectedDate = selectedDates[0]
        const minStartDate = moment(selectedDate).subtract(1, 'month').toDate()
        const maxEndDate = moment(selectedDate).add(1, 'month').toDate()
        const isMaxEndDateAfterToday = moment(maxEndDate).isAfter(today)

        setMinCalendarDate(minStartDate)
        setMaxCalendarDate(isMaxEndDateAfterToday ? today : maxEndDate)
      } else {
        setMinCalendarDate(undefined)
        setMaxCalendarDate(today)
      }
    }

    // Lógica de "canUnselect"
    if (canUnselect && selectedDates.length === 2 && moment(selectedDates[0]).isSame(selectedDates[1])) {
      setMinCalendarDate(undefined)
      setMaxCalendarDate(today)
      instance.clear()
    }
  }

  /*
     * Función que controla cuando se cierra el calendario
    */
  const handleCloseCalendar = (selectedDates: any, dateStr: string, instance: any) => {
    // Lógica de "canUnselect"
    if (canUnselect && (selectedDates?.[0]?.getTime() === selectedDates?.[1]?.getTime())) return

    if (selectedDates?.length === 1) {
      setCurrentDate([...selectedDates, ...selectedDates])
      setCalendarDate([...selectedDates, ...selectedDates])
    } else {
      setCurrentDate(selectedDates)
      setCalendarDate(selectedDates)
    };

    setIsOpenCalendar(false)
  }

  /*
     * Efecto que setea el valor global guardado de las fechas en el input del DatePicker
    */
  // useEffect(() => {
  //   setCurrentDate(value)
  // }, [value])

  /*
     * Renderizado del componente DatePicker
    */
  return (
    <div className={className} onClick={handleClickDatePicker}>
      <Flatpickr
        ref={flatpickerRef}
        value={currentDate}
        className="form-control form-control-solid"
        placeholder="Elige un rango de fechas"
        options={
          {
            mode: 'range',
            dateFormat: 'd/m/Y',
            minDate: minCalendarDate,
            maxDate: maxCalendarDate,
            locale: Spanish
          }
        }
        onOpen={handleOpenCalendar}
        onClose={handleCloseCalendar}
        onChange={handleValueChange}
      />
      <Icon type="calendar" />
    </div>
  )
}

export default DatePicker
